import { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import useToken from './shared/useToken';
import { ToastCont } from './shared/Toast';
import { CookiesProvider } from "react-cookie";
import { version } from "../package.json";
import './App.css';

// Routes
import Login from './components/login/Login';
import Clients from './components/clients/Clients';
import Billing from './components/billing/Billing';
import Navbar from './components/navbar/Navbar';
import Vehicles from './components/vehicles/HomeVehicle';
import SyncLiderman from './components/liderman/SyncLiderman';
import Permissions from './components/permissions/Permissions';
import Costing from './components/costing/Costing';
import Quote from './components/costing/Quote';
import QuoteHistory from './components/costing/QuoteHistory';
import Main from './components/main/Main';
import OperationPerformance from './components/operationPerformance/Operation';
import Budgeting from './components/budgeting';
import ControlCheckpoints from './components/controlCheckpoints';
import ValidateCheckpoints from './components/validateCheckpoints';
import DashCheckpoints from './components/DashCheckpoints';

function App() {
  const { token, setToken } = useToken();

  useEffect(() => {
    console.log(` %c| Portal Armadillo | ${version} `, 'background: #0d568cff; color: #fcab10ff; border-radius: 4px;')
    //console.log(token);
  }, [token])

  const ROUTES2 =
    <Switch>
      <Route exact path="/">
        <Main />
      </Route>
      <Route exact path="/clients">
        <Clients />
      </Route>
      <Route exact path="/billing">
        <Billing />
      </Route>
      <Route exact path="/vehicles">
        <Vehicles />
      </Route>
      <Route exact path="/interfaceliderman">
        <SyncLiderman />
      </Route>
      <Route exact path="/permissions">
        <Permissions />
      </Route>
      <Route exact path="/costing/drivers">
        <Costing />
      </Route>
      <Route exact path="/costing/newProforma">
        <Quote />
      </Route>
      <Route exact path="/costing/historyQuote">
        <QuoteHistory />
      </Route>
      <Route exact path="/costing/modifyQuote">
        <Quote />
      </Route>
      <Route exact path="/operation/performance">
        <OperationPerformance />
      </Route>
      <Route exact path="/budget">
        <Budgeting />
      </Route>
      <Route exact path="/control/checkpoints">
        <ControlCheckpoints />
      </Route>
      <Route exact path="/validate/checkpoints">
        <ValidateCheckpoints />
      </Route>
      <Route exact path="/admin/checkpoints">
        <DashCheckpoints />
      </Route>
    </Switch>

  return (
    <CookiesProvider>
      <ToastCont />
      {
        (!token)
          ? <Login setToken={setToken} />
          : <BrowserRouter>
            <Navbar routes={ROUTES2} setToken={setToken} token={token} />
          </BrowserRouter>
      }
    </CookiesProvider>
  );
}

export default App;

