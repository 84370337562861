import { Space, Button, Popconfirm, Tooltip, message } from "antd";
import { ArrowUpOutlined, CheckOutlined, EditOutlined, CloseOutlined, FormOutlined } from '@ant-design/icons';
import { useState } from "react";
import controlRepo from "../helpers/controlRepo";

const ActionButtons = ({ isEditable, editable, edit, cancel, save, upload, requestEdit, editingKey, record, setReload }) => {
    const { changeEventToPending, sendCheckpointsToTMS } = controlRepo();
    const [loadings, setLoadings] = useState([]);

    const finishLoading = (index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = false;
            return newLoadings;
        });
    }

    const enterLoading = async (index, record) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            //aqui va el record
            return newLoadings;
        });
        if(index === 1){
            const result = await requestEdit(record)
            changeEventToPending(result)
              .then((data) => {
                console.log(data)
                finishLoading(index);
                message.info(data.message, 3);
                setTimeout(() => {
                    setReload()
                }, 2000);
              })
              .catch(err => {
                finishLoading(index);
                message.error('Error al solicitar la edición', 2);
                console.log(err);
              })
        }
        else if (index === 0){
            const result = await upload(record);
            console.log(result);
            sendCheckpointsToTMS(result)
              .then((data) => {
                console.log(data);
                finishLoading(index);
                message.info('OPT subida correctamente al sistema', 2);
                setTimeout(() => {
                    setReload()
                }, 2000);
              })
              .catch(err => {
                finishLoading(index);
                message.error('Error al subir la OPT', 2);
                console.log(err);
              })
        }
    };
    return editable ? (
        <Space>
            <Button
                type="primary"
                shape="round"
                style={{ background: "#14A44D", borderColor: "#14A44D" }}
                icon={<CheckOutlined />}
                onClick={() => {
                    save(record.key);
                }}
            />
            <Popconfirm title="¿Desea cancelar?" onConfirm={() => cancel()}>
                <Button
                    type="primary"
                    shape="round"
                    danger
                    icon={<CloseOutlined />}
                />
            </Popconfirm>
        </Space>
    ) : (
        <Space>
            <Tooltip placement="top" title={<span>Subir OPT</span>}>
                <Button
                    type="primary"
                    shape="circle"
                    style={(editingKey !== '' || !isEditable) ? {} : { background: "#14A44D", borderColor: "#14A44D" }}
                    icon={<ArrowUpOutlined />}
                    loading={loadings[0]}
                    disabled={editingKey !== '' || !isEditable}
                    onClick={() => enterLoading(0, record)}
                />
            </Tooltip>
            <Tooltip placement="top" title={<span>Corregir horas</span>}>
                <Button
                    type="primary"
                    shape="circle"
                    style={(editingKey !== '' || isEditable) ? {} : { background: "#f50", borderColor: "#f50" }}
                    icon={<FormOutlined />}
                    disabled={editingKey !== '' || isEditable}
                    onClick={() => edit(record)}
                />
            </Tooltip>
            <Tooltip placement="bottom" title={<span>Solicitar edición</span>}>
                <Popconfirm title="¿Desea solicitar una edición?" onConfirm={() => enterLoading(1, record)}>
                    <Button
                        type="primary"
                        shape="circle"
                        style={editingKey !== '' ? {} : { background: "#faad14", borderColor: "#faad14" }}
                        loading={loadings[1]}
                        icon={<EditOutlined />}
                        disabled={editingKey !== ''}
                    />
                </Popconfirm>
            </Tooltip>
        </Space>
    )
}

export default ActionButtons;