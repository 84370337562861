import React, { useState, useEffect } from 'react';
import md5 from 'md5';
import Copyright from '../../shared/Copyright';
import "./Login.css";
import { Spin, Form, Checkbox, Button, Card, Input, Typography, Layout, Image, Row, Col, notification } from 'antd';
import { UserOutlined, LockOutlined, SmileOutlined } from '@ant-design/icons';
import LoginRepo from './LoginRepo';
import { ToastOk, ToastErr, ToastCont } from '../../shared/Toast';
import logo from '../../assets/LogoArmadilloCirculo.png';
import BG from '../../assets/backgrounds/BG2.jpg';

const { Title } = Typography;

export default function SignIn({ setToken }) {
  const [loading, setLoading] = useState(false);
  const { getLogin, getVersion } = LoginRepo();

  useEffect(() => {
    notification.open({
      message: '¡Grandes novedades próximamente!',
      description:
        'Estamos trabajando para mejorar nuestro espacio virtual y ofrecer nuevas funcionalidades muy pronto...',
      icon: ( <SmileOutlined style={{ color: '#108ee9', }} /> ),
      duration: 10,
    });
    // getVersion().then(response => console.log(`%c | API Armadillo | ${response.message} `, 'background: #0d568cff; color: #fcab10ff; border-radius: 4px;'))
    //   .catch(error => console.error(error));
  }, [])

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await getLogin({
        username: values.username,
        password: md5(values.password)
      });
      setLoading(false);
      setToken(response.data);
      ToastOk(response.message)
    } catch (error) {
      setLoading(false);
      console.error(error);
      ToastErr(error.message);
    }
  };

  return (
    <Layout className="ant-layout-login" style={{ backgroundImage: BG }}>
      <ToastCont />
      <Row style={{ height: "100%" }} align="middle">
        <Col xs={24}>
          <div style={{ height: "10px" }}></div>
          <Row justify="center" align="middle" >
            <Col span={5} xs={20} md={8} lg={8} style={{ maxWidth: "400px" }}>
              <Card className="card" cover={
                <Row justify="center" align="middle" >
                  <Col xs={24} md={24} style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
                    <Image preview={false} width={"45%"} src={logo} alt='Logo Empresa de Transportes Armadillo, S.A.C.' />
                  </Col>
                  <Col xs={24} md={24} style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
                    <Title level={3} style={{ fontFamily: "" }}>Inicio de Sesión</Title>
                  </Col>
                </Row>
              } >
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Ingresa tu usuario!' }]}
                  >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Usuario" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Ingresa tu contraseña!' }]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Contraseña"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item>
                    <Button htmlType="submit" className="login-form-button">
                      {loading && <Spin size="middle" />}
                      {!loading && 'Iniciar'}
                    </Button>
                  </Form.Item>
                </Form>
                <Copyright />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row >
    </Layout >



    // <div component="main" xs="4">
    //   <GlobalStyle />
    //   {/* <ToastCont /> */}

    //   {/* <CssBaseline /> */}

    //     <div className="paper}>
    //       {/* <div className="avatar}>
    //         <img className="avatarimg} src={logo} />
    //       </div> */}

    //       <Typography component="h1" variant="h5">
    //         Inicio de Sesión
    //       </Typography>
    //       <form className="form" onSubmit={handleSubmit} noValidate>
    //         <Input
    //           variant="outlined"
    //           margin="normal"
    //           required
    //           fullWidth
    //           id="email"
    //           placeholder="Usuario"
    //           name="email"
    //           autoFocus
    //           value={username}
    //           onChange={onChangeUsername}
    //         />
    //         <Input
    //           variant="outlined"
    //           margin="normal"
    //           required
    //           fullWidth
    //           name="password"
    //           placeholder="Contraseña"
    //           type="password"
    //           id="password"
    //           autoComplete="current-password"
    //           value={password}
    //           onChange={onChangePassword}
    //         />
    //         {/* <FormControlLabel
    //           control={<Checkbox value="remember" color="primary" />}
    //           label="Recordar"
    //         /> */}
    //         <Button
    //           type="submit"
    //           fullWidth
    //           variant="contained"
    //           color="primary"
    //           className="submit}
    //           disabled={loading}
    //         >
    //           {loading && <Spin size="middle" />}
    //           {!loading && 'Iniciar'}
    //         </Button>

    //         {/* <Grid container>
    //         <Grid item xs>
    //           <Link href="#" variant="body2">
    //             Forgot password?
    //           </Link>
    //         </Grid>
    //         <Grid item>
    //           <Link href="#" variant="body2">
    //             {"Don't have an account? Sign Up"}
    //           </Link>
    //         </Grid>
    //       </Grid> */}
    //       </form>
    //     </div>
    //     <Card>
    //       <Copyright />
    //     </Card >
    //   </Card>
    // </div>
  );
}
