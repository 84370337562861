import { Table, Form, Input, Badge, message } from "antd";
import moment from "moment";
import { useEffect, useState } from 'react';
import ActionButtoms from "../Buttoms/ActionButtoms";
import ModalEdit from "../Modals/ModalEdit";

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

const ValidateTable = ({ opt, optguid, ort, ortguid, fecha, content, reloadButtonValidateTable, setReload }) => {

    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [checkpoints, setCheckpoints] = useState({});
    const [isTimeModfied, setIstimeModified] = useState(true);
    const [editingKey, setEditingKey] = useState('');

    useEffect(() => {
        fetchData()
    }, [reloadButtonValidateTable])

    const fetchData = () => {
        setData(content)
    }

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps
    }) => {
        const inputNode = inputType === 'text' 
        ? <Input /> 
        : <ModalEdit title={title} dataIndex={dataIndex} checkpoints={checkpoints} />;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                type: inputType,
                                required: true,
                                message: `Por favor, completar correctamente el campo de ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            ...record,
            pntArribo: isNaN(record.pntArribo) ? record.pntArribo : [fecha],
            ingAlmacen: isNaN(record.ingAlmacen) ? record.ingAlmacen : [fecha],
            aperPuertas: isNaN(record.aperPuertas) ? record.aperPuertas : [fecha],
            cierPuertas: isNaN(record.cierPuertas) ? record.cierPuertas : [fecha],
            salAlmacen: isNaN(record.salAlmacen) ? record.salAlmacen : [fecha]
        });
        setCheckpoints({
            pntArribo: record.pntArribo,
            ingAlmacen: record.ingAlmacen,
            aperPuertas: record.aperPuertas,
            cierPuertas: record.cierPuertas,
            salAlmacen: record.salAlmacen
        })
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                //primero validamos que los dias estén correctos:
                if(moment(fecha, dateFormat).format("L") === moment(row['pntArribo']).format("L") 
                && moment(fecha, dateFormat).format("L") === moment(row['ingAlmacen']).format("L")
                && moment(fecha, dateFormat).format("L") === moment(row['aperPuertas']).format("L")
                && moment(fecha, dateFormat).format("L") === moment(row['cierPuertas']).format("L")
                && moment(fecha, dateFormat).format("L") === moment(row['salAlmacen']).format("L")){
                    //luego validamos las horas:
                    if(moment(row['salAlmacen']).unix() > moment(row['cierPuertas']).unix() 
                    && moment(row['cierPuertas']).unix() > moment(row['aperPuertas']).unix()
                    && moment(row['aperPuertas']).unix() > moment(row['ingAlmacen']).unix()
                    && moment(row['ingAlmacen']).unix() > moment(row['pntArribo']).unix()){
                    }else{
                        return message.error('Ingrese las horas correctas', 2);
                    }
                }else{
                    return message.error('Ingrese las fechas correctas', 2);
                }
                if(row['pntArribo']._i && row['ingAlmacen']._i && row['ingAlmacen']._i && row['ingAlmacen']._i && row['ingAlmacen']._i && row['ingAlmacen']._i){
                    setIstimeModified(false);
                }
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                    pntArribo: [row['pntArribo'].format(dateFormat)],
                    ingAlmacen: [row['ingAlmacen'].format(dateFormat)],
                    aperPuertas: [row['aperPuertas'].format(dateFormat)],
                    cierPuertas: [row['cierPuertas'].format(dateFormat)],
                    salAlmacen: [row['salAlmacen'].format(dateFormat)],
                });
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            message.error('Error al enviar datos, verifique nuevamente.', 2);
            console.log('Validate Failed:', errInfo);
        }
    };

    const upload = (record) => {
        const result = {
            numOpt: opt,
            optguid,
            numeroORT: ort,
            ortguid,
            tramoGUID: record.distribucionGUID,
            evento: record,
            modified: isTimeModfied ? "S" : "N",
            transportOrders: [
                {
                    numeroORT: ort,
                    ortguid,
                    distributionPaths: [
                        {
                            tramoGUID: record.distribucionGUID,
                            checkpoints: [
                                {
                                    fechaHora: moment(record.pntArribo[0], dateFormat).format('YYYY-MM-DDThh:mm:ss'),
                                    codigo: "ARDP"
                                },
                                {
                                    fechaHora: moment(record.ingAlmacen[0], dateFormat).format('YYYY-MM-DDThh:mm:ss'),
                                    codigo: "ETDP"
                                },
                                {
                                    fechaHora: moment(record.aperPuertas[0], dateFormat).format('YYYY-MM-DDThh:mm:ss'),
                                    codigo: "STDP"
                                },
                                {
                                    fechaHora: moment(record.cierPuertas[0], dateFormat).format('YYYY-MM-DDThh:mm:ss'),
                                    codigo: "ENDP"
                                },
                                {
                                    fechaHora: moment(record.salAlmacen[0], dateFormat).format('YYYY-MM-DDThh:mm:ss'),
                                    codigo: "DEDP"
                                }
                            ]
                        }
                    ]
                }
            ]
        }
        return result;
    }

    const confirmEdit = (record) => {
        const result = {
            numOpt: opt,
            optguid,
            ortguid,
            numeroORT: ort,
            tramoGUID: record.distribucionGUID
        }
        return result;
    }

    const columns = [
        {
            title: 'Evento',
            dataIndex: 'tipo',
            width: 140,
            fixed: 'left',
            render: (text) => <b>{text}</b>,
            editable: false
        },
        {
            title: 'Almacén',
            dataIndex: 'nombre',
            width: 140,
            editable: false
        },
        {
            title: 'Punto de arribo',
            dataIndex: 'pntArribo',
            editable: true,
            render: (times, record, index) => {
                return (
                    <Badge
                        offset={[10, -5]}
                        count={times.length > 1 ? times.length : 0}>
                        <p>{times[0]}</p>
                    </Badge>
                )
            }
        },
        {
            title: 'Ingreso almacén',
            dataIndex: 'ingAlmacen',
            editable: true,
            render: (times, record) => {
                return (
                    <Badge
                        offset={[10, -5]}
                        count={times.length > 1 ? times.length : 0}>
                        <p>{times[0]}</p>
                    </Badge>
                )
            }
        },
        {
            title: 'Apertura puertas',
            dataIndex: 'aperPuertas',
            editable: true,
            render: (times, record) => {
                return (
                    <Badge
                        offset={[10, -5]}
                        count={times.length > 1 ? times.length : 0}>
                        <p>{times[0]}</p>
                    </Badge>
                )
            }
        },
        {
            title: 'Cierre puertas',
            dataIndex: 'cierPuertas',
            editable: true,
            render: (times, record) => {
                return (
                    <Badge
                        offset={[10, -5]}
                        count={times.length > 1 ? times.length : 0}>
                        <p>{times[0]}</p>
                    </Badge>
                )
            }
        },
        {
            title: 'Salida almacén',
            dataIndex: 'salAlmacen',
            editable: true,
            render: (times, record) => {
                return (
                    <Badge
                        offset={[10, -5]}
                        count={times.length > 1 ? times.length : 0}>
                        <p>{times[0]}</p>
                    </Badge>

                )
            }
        },
        {
            title: 'Acciones',
            key: 'action',
            width: 150,
            fixed: 'right',
            render: (_, record) => {
                const editable = isEditing(record);
                let isEditable = false;
                Object.keys(record).map(item => {
                    if (item === "pntArribo" || item === "ingAlmacen" || item === "aperPuertas" || item === "cierPuertas" || item === "salAlmacen") {
                        if (record[item].length > 1 || record[item].length == 0) isEditable = true;
                    }
                })
                return <ActionButtoms
                    isEditable={isEditable}
                    editable={editable}
                    edit={edit}
                    cancel={cancel}
                    save={save}
                    upload={upload}
                    confirmEdit={confirmEdit}
                    editingKey={editingKey}
                    record={record}
                    setReload={setReload}
                />
            },
        }
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'nota' ? 'text' : 'object',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                columns={mergedColumns}
                dataSource={data}
                pagination={false}
                rowClassName="editable-row"
                bordered
            />
        </Form>
    )
}

export default ValidateTable;