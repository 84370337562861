import { Badge, Modal, DatePicker, Radio, Space, message } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import moment from 'moment';

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

const ModalEdit = ({ title, dataIndex, value, onChange, checkpoints }) => {

    const [principalValue, setPrincipalValue] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [valueRadio, setValueRadio] = useState(0);
    const [valueDatePicker, setValueDatePicker] = useState(null);
    const [disableButtonOk, setDisableButtonOk] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [colorBadge, setColorBadge] = useState('#faad14');


    useEffect(() => {
        setPrincipalValue(value);
        if (value.length === 1) onChange(moment(value, dateFormat));
        else {
            setValueRadio(value[0]);
            setSelectedValue(value[0]);
        }
    }, [])

    const showModal = () => {
        setColorBadge('#faad14');
        setIsModalOpen(true);
        setDisableButtonOk(false);
    };
    const handleOk = () => {
        if (valueDatePicker) {
            if(dataIndex === "pntArribo"){
                if(moment(checkpoints.pntArribo[0], dateFormat).format('L') != moment(valueDatePicker).format('L')){
                    return message.error("Ingrese una fecha correcta", 2);
                }
            }
            onChange(valueDatePicker);
            setSelectedValue(valueDatePicker.format(dateFormat));
        }
        else {
            onChange(moment(valueRadio, dateFormat))
            setSelectedValue(valueRadio)
        }
        setColorBadge('#52c41a')
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setValueRadio(principalValue[0]);
        setValueDatePicker(null);
        setIsModalOpen(false);
    };

    const onChangeRadio = (e) => {
        setValueRadio(e.target.value);
        setDisableButtonOk(false)
        if (e.target.value === 'selectDatePicker') return setDisableButtonOk(true)
        else setValueDatePicker(null)
    };

    const onChangeDatePicker = (time) => {
        if (time === null) return setDisableButtonOk(true)
        setDisableButtonOk(false)
        setValueDatePicker(time);
    }

    return (
        <>
            {
                principalValue.length > 1
                    ?
                    <Badge
                        offset={[5, -5]}
                        count={principalValue.length > 1 ? principalValue.length : 0}
                        onClick={showModal}
                        style={{
                            backgroundColor: colorBadge,
                        }}
                    >
                        <p>{selectedValue}</p>
                    </Badge>
                    :
                    <DatePicker showTime format={dateFormat} defaultValue={moment(value, dateFormat)} onChange={onChange} />
            }
            <Modal
                title={title}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    disabled: disableButtonOk,
                }}
            >
                <Radio.Group onChange={onChangeRadio} value={valueRadio}>
                    <Space direction="vertical">
                        {principalValue.length > 1 ? principalValue.map((time, i) => {
                            return (
                                <Radio key={i} value={time}>{time}</Radio>
                            )
                        }) : null}
                        <Radio value='selectDatePicker'>
                            Agregar fecha:
                            {valueRadio === 'selectDatePicker' ? (
                                <DatePicker
                                    showTime
                                    format={dateFormat}
                                    onChange={onChangeDatePicker}
                                    style={{
                                        width: 200,
                                        marginLeft: 10,
                                    }}
                                />
                            ) : null}
                        </Radio>
                    </Space>
                </Radio.Group>
            </Modal>
        </>
    );
}

export default ModalEdit;