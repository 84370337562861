import { Button, Form, Input, Select, Space, message, DatePicker } from "antd";
import React, { useState } from "react";
import moment from "moment";
import controlRepo from '../helpers/controlRepo';

const { Option } = Select;
const dateFormat = "DD/MM/YYYY HH:mm:ss";
const styleInputLeft = {
  style: {
    display: "inline-block",
    width: "calc(50% - 8px)",
  }
}
const styleInputRight = {
  style: {
    display: "inline-block",
    width: "calc(50% - 8px)",
    margin: "0 8px",
  }
}

const SelectCheckPoints = ({
  value=[],
  selectedValues,
  setSelectedValues,
  valuesOfForm, 
  setValuesOfForm, 
  dataIndex,
  datePickerValue, 
  setDatePickerValue,
  disabledDatePicker,
  setDisabledSetPicker
}) => {


  const onSelectChange = (selectValue) => {
    if(selectValue === "editar") {
      setSelectedValues({...valuesOfForm, [dataIndex]: "Elegir nueva fecha"});
      return setDisabledSetPicker({...disabledDatePicker, [dataIndex]: false});
    }
    else {
      setSelectedValues({...selectedValues, [dataIndex]: selectValue})
      setDisabledSetPicker({...disabledDatePicker, [dataIndex]: true});
      setDatePickerValue("");
      setValuesOfForm({...valuesOfForm, [dataIndex]: selectValue});
    }
  }

  const onDatePickerChange = (currentValue) => {
    if (currentValue) {
      setDatePickerValue(prevValue => {
        const newValue = {...prevValue};
        newValue[dataIndex] = currentValue;
        return newValue;
      });
      setValuesOfForm({...valuesOfForm, [dataIndex]: currentValue.format(dateFormat)});
    }
  };
  const onOpenChange = (open) => {
    if (open) {
      setDatePickerValue(prevValue => {
        const newValue = {...prevValue};
        newValue[dataIndex] = "";
        return newValue;
      });
    }
  };

  return(
    <span>
      <Select 
        placeholder="Seleccione hora" 
        size="large"
        value={selectedValues[dataIndex]}
        onChange={onSelectChange}
        {...styleInputLeft}
      >
        {
          value
          ? value.map((item, i) => {
            return(
              <Option key={i} value={item} >{item}</Option>
            )
          })
          : null
        }
        <Option value="editar">Elegir nueva fecha</Option>
      </Select>
      <DatePicker 
        showTime 
        format={dateFormat} 
        value={datePickerValue[dataIndex]}
        onChange={onDatePickerChange}
        onOpenChange={onOpenChange}
        disabled={disabledDatePicker[dataIndex]}
        {...styleInputRight}
        size="large" 
      />
    </span>
  )
}

const EditableForm = ({ disableForm, form, cancel, setReload, valuesOfForm, setValuesOfForm, selectedValues, setSelectedValues, disabledDatePicker, setDisabledSetPicker, datePickerValue, setDatePickerValue }) => {

  const { sendCheckpointsToTMS } = controlRepo();

  const [buttonLoading, setButtonLoading] = useState(false);

  const onFinish = (values) => {
    const restData = form.getFieldsValue(true);
    setButtonLoading(true);
    if(valuesOfForm['pntArribo'] && valuesOfForm['ingAlmacen'] && valuesOfForm['aperPuertas'] && valuesOfForm['cierPuertas'] && valuesOfForm['salAlmacen']){
      if(moment(valuesOfForm['pntArribo'], dateFormat).format("L") === moment(valuesOfForm['ingAlmacen'], dateFormat).format("L")
      && moment(valuesOfForm['ingAlmacen'], dateFormat).format("L") === moment(valuesOfForm['aperPuertas'], dateFormat).format("L")
      && moment(valuesOfForm['aperPuertas'], dateFormat).format("L") === moment(valuesOfForm['cierPuertas'], dateFormat).format("L")
      && moment(valuesOfForm['cierPuertas'], dateFormat).format("L") === moment(valuesOfForm['salAlmacen'], dateFormat).format("L")){
        if(moment(valuesOfForm['salAlmacen'], dateFormat).unix() > moment(valuesOfForm['cierPuertas'], dateFormat).unix() 
        && moment(valuesOfForm['cierPuertas'], dateFormat).unix() > moment(valuesOfForm['aperPuertas'], dateFormat).unix()
        && moment(valuesOfForm['aperPuertas'], dateFormat).unix() > moment(valuesOfForm['ingAlmacen'], dateFormat).unix()
        && moment(valuesOfForm['ingAlmacen'], dateFormat).unix() > moment(valuesOfForm['pntArribo'], dateFormat).unix()){
        }else{
            return message.error('Ingrese las horas correctas', 3);
        }
      }else{
        return message.error('Debe ingresar los mismos dias de la operación', 3);
      }
    }else{
      console.log(valuesOfForm)
      return message.info('Debe completar todas las horas para poder enviar', 3);
    }
    const result = {
      numOpt: values['opt'],
      optguid: restData.optguid,
      numeroORT: restData.ort,
      ortguid: restData.ortguid,
      tramoGUID: restData.distribucionGUID,
      evento: restData,
      modified: "S",
      transportOrders: [
          {
              numeroORT: restData.ort,
              ortguid: restData.ortguid,
              distributionPaths: [
                  {
                      tramoGUID: restData.distribucionGUID,
                      checkpoints: [
                          {
                              fechaHora: valuesOfForm['pntArribo'],
                              codigo: "ARDP"
                          },
                          {
                              fechaHora: valuesOfForm['ingAlmacen'],
                              codigo: "ETDP"
                          },
                          {
                              fechaHora: valuesOfForm['aperPuertas'],
                              codigo: "STDP"
                          },
                          {
                              fechaHora: valuesOfForm['cierPuertas'],
                              codigo: "ENDP"
                          },
                          {
                              fechaHora: valuesOfForm['salAlmacen'],
                              codigo: "DEDP"
                          }
                      ]
                  }
              ]
          }
      ]
    }
    sendCheckpointsToTMS(result)
    .then((data) => {
        console.log(data);
        message.success('OPT subida correctamente al sistema', 3);
        setButtonLoading(false);
        cancel();
        setTimeout(() => {
            setReload()
        }, 2000);
      })
      .catch(err => {
        setButtonLoading(false)
        message.error(`Error en subir datos al TMS. (${err.message})`, 3);
      })
  };

  const onFinishFailed = () => {
    message.error('Submit failed!');
};

  return (
    <Form
      name="complex-form"
      form={form}
      disabled={disableForm}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 18,
      }}
      layout="horizontal"
    >
      <Form.Item label="OPT / Evento" style={{marginTop: 30 }}>
        <Space>
          <Form.Item name="opt" noStyle>
            <Input
              style={{
                width: 160,
              }}
              placeholder="OPT"
              disabled
            />
          </Form.Item>
          <Form.Item name="tipo" noStyle>
            <Input
              style={{
                width: 100,
              }}
              placeholder="Evento"
              disabled
            />
          </Form.Item>
        </Space>
      </Form.Item>

      <Form.Item
        label="Punto de Arribo"
        style={{
          marginBottom: 0,
        }}
      >
        <Input.Group compact>
          <Form.Item name="pntArribo">
            <SelectCheckPoints 
              valuesOfForm={valuesOfForm} 
              setValuesOfForm={setValuesOfForm} 
              dataIndex="pntArribo"
              datePickerValue={datePickerValue}
              setDatePickerValue={setDatePickerValue}
              disabledDatePicker={disabledDatePicker}
              setDisabledSetPicker={setDisabledSetPicker}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item
        label="Ingreso Almacén"
        style={{
          marginBottom: 0,
        }}
      >
        <Input.Group compact>
          <Form.Item name="ingAlmacen">
            <SelectCheckPoints
              valuesOfForm={valuesOfForm}
              setValuesOfForm={setValuesOfForm}
              dataIndex="ingAlmacen"
              datePickerValue={datePickerValue}
              setDatePickerValue={setDatePickerValue}
              disabledDatePicker={disabledDatePicker}
              setDisabledSetPicker={setDisabledSetPicker}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item
        label="Apertura Puertas"
        style={{
          marginBottom: 0,
        }}
      >
        <Input.Group compact>
          <Form.Item name="aperPuertas">
            <SelectCheckPoints 
              valuesOfForm={valuesOfForm} 
              setValuesOfForm={setValuesOfForm} 
              dataIndex="aperPuertas" 
              datePickerValue={datePickerValue}
              setDatePickerValue={setDatePickerValue}
              disabledDatePicker={disabledDatePicker}
              setDisabledSetPicker={setDisabledSetPicker}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item
        label="Cierre Puertas"
        style={{
          marginBottom: 0,
        }}
      >
        <Input.Group compact>
          <Form.Item name="cierPuertas">
            <SelectCheckPoints 
              valuesOfForm={valuesOfForm} 
              setValuesOfForm={setValuesOfForm} 
              dataIndex="cierPuertas" 
              datePickerValue={datePickerValue}
              setDatePickerValue={setDatePickerValue}
              disabledDatePicker={disabledDatePicker}
              setDisabledSetPicker={setDisabledSetPicker}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item
        label="Salida Almacén"
      >
        <Input.Group compact>
          <Form.Item name="salAlmacen">
            <SelectCheckPoints 
              valuesOfForm={valuesOfForm} 
              setValuesOfForm={setValuesOfForm} 
              dataIndex="salAlmacen"
              datePickerValue={datePickerValue}
              setDatePickerValue={setDatePickerValue}
              disabledDatePicker={disabledDatePicker}
              setDisabledSetPicker={setDisabledSetPicker}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item label=" " colon={false}>
        <Space>
            <Button
                type='primary'
                htmlType='submit'
                loading={buttonLoading}
            >
                Enviar
            </Button>
            <Button
                type='primary'
                htmlType="button"
                danger
                onClick={() => {
                  cancel();
                  setValuesOfForm({});
                  setDatePickerValue("");
                  setDisabledSetPicker(true);
                }}
            >
                Cancelar
            </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default EditableForm;
