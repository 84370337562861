import { useState, useEffect } from 'react';
import { Table, Tag, Space, Typography } from 'antd';
import QuoteDetails from './QuoteDetails';

import CostingRepo from './CostingRepo';

export default function TabHistoryQuotes({ update }) {
    const [modalDetails, setModalDetails] = useState(false);
    const [recordDetailsQuote, setRecordDetailsQuote] = useState({});
    const [dataProf, setDataProf] = useState();
    const { allHistoryQuotes } = CostingRepo();

    useEffect(() => {
        allHistoryQuotes().then(data => {
            let proformas = data.data;
            for (let i = 0; i < proformas.length; i++) {
                proformas[i].key = proformas[i].id;
            }
            setDataProf(proformas);
        }).catch(error => {
            console.log(error);
        })
    }, [update])

    const detailsQuote = (record) => {
        setModalDetails(true);
        setRecordDetailsQuote(record);
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Cliente',
            dataIndex: 'clientName',
            key: 'clientName',
            width: '30%',
        },
        {
            title: 'Ruc',
            dataIndex: 'identificator',
            key: 'identificator',
            width: '10%',
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
            width: '20%',
        },
        {
            title: 'Fecha',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '15%',
            render: (text, record) => {
                const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
                const d = new Date(record.createdAt);
                const date = d.toLocaleDateString(undefined, options);
                return (
                    <span>
                        {date}
                    </span>
                )
            },
        },
        {
            title: 'Costo incial',
            dataIndex: 'totalCost',
            key: 'totalCost',
            width: '5%',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return (
                    <span>
                        <Typography.Link
                            onClick={() => detailsQuote(record)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Detalles
                        </Typography.Link>
                    </span>
                )


            },
        }
    ]

    return (
        <div className='tableQuoteHistory'>
            <Table columns={columns} dataSource={dataProf} />
            <QuoteDetails modalDetails={modalDetails} setModalDetails={setModalDetails} recordDetailsQuote={recordDetailsQuote} />

        </div>
    );
}