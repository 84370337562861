import DashRepo from '../../shared/DashRepo';
import { useCookies } from 'react-cookie';

export default function PermissionsRepo() {
    
    const [cookies, setCookie] = useCookies(['token']);
    const { dFetch } = DashRepo();

    const getUsers = () => {
        return  dFetch({ verb: 'GET', path: '/users', headers : {
            'Content-Type': 'application/json'}
        }).then(response => {
            return  response.data;
        });
    }

    const newUser = (user) => {
        return  dFetch({ verb: 'POST',data:user, path: '/users', headers : {
            'Content-Type': 'application/json'}
        }).then(response => {
            return  response.data;
        });
    }

    const deleteUser = (username) => {
        return  dFetch({ verb: 'DELETE',data:username, path: '/users', headers : {
            'Content-Type': 'application/json'}
        }).then(response => {
            return  response.data;
        });
    }

    const getMenus = () => {
        return  dFetch({ verb: 'GET', path: '/permissions/menu', headers : {
            'Content-Type': 'application/json'}
        }).then(response => {
            return  response.data;
        });
    }

    const getUserPermissions = (username) => {
        return  dFetch({ verb: 'POST',data:username, path: '/permissions/userPermission', headers : {
            'Content-Type': 'application/json'}
        }).then(response => {
            return  response.data;
        });
    }

    const setNewUserPermissions = (newPermi) => {
        return  dFetch({ verb: 'POST',data:newPermi, path: '/permissions/newPermission', headers : {
            'Content-Type': 'application/json'}
        }).then(response => {
            return  response.data;
        });
    }


    return { getUsers, newUser, deleteUser, getMenus, getUserPermissions , setNewUserPermissions};
}