import { Card, Layout, Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useState } from "react";
import InputDatePicker from "./DatePicker/InputDatePicker";
import EditContainer from "./Tables/EditContainer";
import PrincipalOPTTable from "./Tables/PrincipalOPTTable";

const { Content } = Layout;

export default function ControlCheckpoints() {
  const [reloadButton, setRealoadButton] = useState(false);
  const [loadingOPTTable, setLoadingOPTTable] = useState(false);
  const [dataOPTTable, setDataOPTTable] = useState([]);

  const setReload = () => {
    setRealoadButton(true);
    setTimeout(() => {
      setRealoadButton(false);
    }, 1000);
  };

  return (
    <Card title="Mantenimiento de CheckPoints">
      <div>
        <Content style={{ textAlign: "center" }}>
          <InputDatePicker setDataTable={setDataOPTTable} setLoading={setLoadingOPTTable} />
        </Content>
        <Card
          type="inner"
          title="Tabla de Acciones"
          extra={
            <Button
              type="primary"
              shape="circle"
              icon={<ReloadOutlined />}
              onClick={setReload}
              loading={reloadButton}
            />
          }
          style={{
            marginTop: 20,
          }}
        >
          <PrincipalOPTTable
            reloadButton={reloadButton}
            setReload={setReload}
            dataTable={dataOPTTable}
            setDataTable={setDataOPTTable}
            loading={loadingOPTTable}
            setLoading={setLoadingOPTTable}
          />
        </Card>
      </div>
      <EditContainer />
    </Card>
  );
}
