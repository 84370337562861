import './navbar.css';

export default function Navigation({navlinks}) {
    return (
        <nav className="navigation">
            {navlinks}
            {/* <NavButtons modo={"horizontal"}/> */}
        </nav>
    );
}


