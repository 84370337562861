import './navbar.css';
import {MenuOutlined} from '@ant-design/icons';
import {Menu} from 'antd';

export default function MobileNavigation  ({navlinks,open, setOpen}) {

    return ( 
        <nav className="mobileNavigation">
            <MenuOutlined className='menuOutlined' onClick={() => setOpen(!open)}/>
            {open && <Menu className='navLinksMobile'>{navlinks}</Menu>}

        </nav>
    );
} 
