import { Statistic, Table } from 'antd';
import { useState, useEffect } from 'react';

import RenderEachCell from '../helpers/RenderEachCell';
import StatisticCell from '../helpers/StatisticCell';
import budgetRepo from '../helpers/budgetingRepo.js';
import '../stylesTables.css';

const BudgetTable = (props) => {

    const { getBudgetByYear, getYears } = budgetRepo()
    const { year, setYear, blockYears } = props;
    const today = new Date()
    today.setMonth(today.getMonth()-1)
    const currentMonth = today.toLocaleDateString('es-PE', { month: 'long' }).toLocaleLowerCase()
    const [principalData, setPrincipalData] = useState([])
    const [loading, setLoading] = useState(false)
    const [filterYears, setFilterYears] = useState([]);
    const [blockFilterYears, setblockFilterYears] = useState(blockYears);
    const [type, setType] = useState(props.type)

    useEffect(() => {
        getYears().then((years) => setFilterYears(years.data))
        fechData()
    }, [year]);

    const fechData = () => {
        setLoading(true);
        getBudgetByYear({ year, type })
            .then(({ data }) => {
                handleEstructureTable(data)
                setLoading(false)
            })
    }

    const RenderCell = ({ content, month }) => (
        <table className='subtitlesTable'>
            <tbody>
                {content ? content.map((summary, i) => (
                    <tr key={i}>
                        <td>{
                            summary[type].map((mes, id) => {
                                if (i > 1) {
                                    if (mes.month === month)
                                        return <StatisticCell key={id} total={mes.total} isPorcent={true} />
                                } else {
                                    if (mes.month === month)
                                        return <StatisticCell key={id} total={mes.total} />
                                }
                            })
                        }</td>
                    </tr>
                )) : 0}
            </tbody>
        </table>
    )

    const columns = [
        {
            title: 'COSTS AND EXPENSES',
            children: [
                {
                    title: 'Accounts',
                    dataIndex: 'name',
                    filters: [
                        {
                            text: 'Gross Revenue',
                            value: 'Gross',
                        },
                        {
                            text: 'Costo de Venta',
                            value: 'Costo',
                        },
                        {
                            text: 'Gastos Administrativos y de Venta',
                            value: 'Administrativos',
                        },
                        {
                            text: 'Depreciación',
                            value: 'Depreciación',
                        },
                        {
                            text: 'Gastos Financieros',
                            value: 'Financieros',
                        },
                        {
                            text: 'Perdida Cambiaria',
                            value: 'Perdida',
                        },
                        {
                            text: 'Impuestos',
                            value: 'Impuestos',
                        },
                    ],
                    filterMode: 'tree',
                    onFilter: (value, record) => record.name.includes(value),
                    onCell: (record, index) => {
                        if (record.key > 10 && record.key < 100) return { colSpan: 2 }
                    },
                },
                {
                    title: 'Summaries',
                    render: (_, record) => <RenderEachCell record={record} id={17} />,
                    onCell: (record, index) => {
                        if (record.key > 10 && record.key < 100) return { colSpan: 0 }
                    },
                },
            ],
        },
        {
            title: `Act${year}`,
            children: [
                {
                    title: type.toUpperCase(),
                    children: [
                        {
                            title: 'Jan',
                            dataIndex: 'content',
                            width: 70,
                            render: (content) => <RenderCell content={content} month='january' />,
                        },
                        {
                            title: 'Feb',
                            dataIndex: 'content',
                            width: 70,
                            render: (content) => <RenderCell content={content} month='february' />,
                        },
                        {
                            title: 'Mar',
                            dataIndex: 'content',
                            width: 70,
                            render: (content) => <RenderCell content={content} month='march' />,
                        },
                        {
                            title: 'Apr',
                            dataIndex: 'content',
                            width: 70,
                            render: (content) => <RenderCell content={content} month='april' />,
                        },
                        {
                            title: 'May',
                            dataIndex: 'content',
                            width: 70,
                            render: (content) => <RenderCell content={content} month='may' />,
                        },
                        {
                            title: 'Jun',
                            dataIndex: 'content',
                            width: 70,
                            render: (content) => <RenderCell content={content} month='june' />,
                        },
                        {
                            title: 'Jul',
                            dataIndex: 'content',
                            width: 70,
                            render: (content) => <RenderCell content={content} month='july' />,
                        },
                        {
                            title: 'Aug',
                            dataIndex: 'content',
                            width: 70,
                            render: (content) => <RenderCell content={content} month='august' />,
                        },
                        {
                            title: 'Sep',
                            dataIndex: 'content',
                            width: 70,
                            render: (content) => <RenderCell content={content} month='september' />,
                        },
                        {
                            title: 'Oct',
                            dataIndex: 'content',
                            width: 70,
                            render: (content) => <RenderCell content={content} month='october' />,
                        },
                        {
                            title: 'Nov',
                            dataIndex: 'content',
                            width: 70,
                            render: (content) => <RenderCell content={content} month='november' />,
                        },
                        {
                            title: 'Dec',
                            dataIndex: 'content',
                            width: 70,
                            render: (content) => <RenderCell content={content} month='december' />,
                        },
                    ],
                },
                {
                    title: year,
                    filteredValue: [`${year}`] || undefined,
                    filters: blockFilterYears ? undefined : filterYears,
                    defaultFilteredValue: blockFilterYears ? undefined : [`${year}`],
                    filterMultiple: false,
                    children: [
                        {
                            title: 'FY',
                            dataIndex: 'content',
                            width: 70,
                            render: (content) => <RenderCell content={content} month='totalYear' />,
                        },
                    ],
                },
                {
                    title: 'Q1',
                    dataIndex: 'content',
                    width: 70,
                    render: (content) => <RenderCell content={content} month='quarter1' />,
                },
                {
                    title: 'Q2',
                    dataIndex: 'content',
                    width: 70,
                    render: (content) => <RenderCell content={content} month='quarter2' />,
                },
                {
                    title: 'Q3',
                    dataIndex: 'content',
                    width: 70,
                    render: (content) => <RenderCell content={content} month='quarter3' />,
                },
                {
                    title: 'Q4',
                    dataIndex: 'content',
                    width: 70,
                    render: (content) => <RenderCell content={content} month='quarter4' />,
                },
            ],
        },
    ];

    const handleEstructureTable = (currentData) => {
        const result = currentData.map(account => (
            {
                ...account,
                content: calculateTotalRowAndQuarters(account.content),
                children: account.children.map(categoryOrSubaccount => (
                    {
                        ...categoryOrSubaccount,
                        content: calculateTotalRowAndQuarters(categoryOrSubaccount.content),
                        children: categoryOrSubaccount.children
                            ? categoryOrSubaccount.children.map(subaccount => (
                                {
                                    ...subaccount,
                                    content: calculateTotalRowAndQuarters(subaccount.content)
                                }
                            ))
                            : undefined
                    }
                ))
            }
        ))
        setPrincipalData(result);
    }

    const calculateTotalRowAndQuarters = (content) => {
        return content.map((summary, i) => {
            let totalYear = 0;
            let q1 = summary[type].slice(0, 3).reduce((pv, cv) => pv + cv.total, 0);
            let q2 = summary[type].slice(3, 6).reduce((pv, cv) => pv + cv.total, 0);
            let q3 = summary[type].slice(6, 9).reduce((pv, cv) => pv + cv.total, 0);
            let q4 = summary[type].slice(9, 12).reduce((pv, cv) => pv + cv.total, 0);
            summary[type].forEach((month) => {
                totalYear += month.total
            })
            summary[type].push({ "month": "totalYear", total: i > 1 ? totalYear / 10 : totalYear })
            summary[type].push({ "month": "quarter1", total: i > 1 ? q1 / 3 : q1 })
            summary[type].push({ "month": "quarter2", total: i > 1 ? q2 / 3 : q2 })
            summary[type].push({ "month": "quarter3", total: i > 1 ? q3 / 3 : q3 })
            summary[type].push({ "month": "quarter4", total: i > 1 ? q4 / 3 : q4 })
            return summary;
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setYear(() => {
            try {
                return filters['1-1'][0]
            } catch (error) {
                return year
            }
        })
    }

    return (
        <>
            <Table
                title={() => `Estado de Resultado (${type})`}
                className={`budgeting ${currentMonth}`}
                loading={loading}
                bordered={true}
                columns={columns}
                dataSource={principalData}
                pagination={false}
                onChange={handleTableChange}
                size="small"
                scroll={{
                    x: 'calc(850px + 50%)',
                    y: 500
                }}
                summary={(totalData) => {
                    const totalUtilidadNeta = [];
                    const result = [];
                    totalData.map((account, i) => {
                        totalUtilidadNeta.push(account.content[0][type])
                    })
                    for (let mes = 0; mes < 12; mes++) {
                        let utilidadNeta = 0;
                        totalUtilidadNeta.map((summary, id) => {
                            if (id === 0) {
                                utilidadNeta = summary[mes].total;
                            } else {
                                utilidadNeta -= summary[mes].total
                            }
                        })
                        result.push(parseFloat(utilidadNeta.toFixed(2)));
                    }
                    return (
                        <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell colSpan={2} index={0} align='center'><b>UTILIDAD NETA</b></Table.Summary.Cell>
                                {
                                    result.map((mes, i) => (
                                        <Table.Summary.Cell index={i + 2} key={i}>
                                            <Statistic
                                                className='cellValue'
                                                valueStyle={
                                                    mes < 0
                                                        ? { color: '#cf1322' }
                                                        : undefined
                                                }
                                                value={mes ? mes : 0}
                                            />
                                        </Table.Summary.Cell>
                                    ))
                                }
                                <Table.Summary.Cell index={14}>
                                    <b>
                                        <Statistic
                                            className='cellValue'
                                            valueStyle={
                                                result.reduce((pv, cv) => pv + cv, 0) < 0
                                                    ? { color: '#cf1322' }
                                                    : undefined
                                            }
                                            value={result.reduce((pv, cv) => pv + cv, 0)}
                                        />
                                    </b>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )
                }}
            />
        </>
    );
};

export default BudgetTable;