import React from 'react';
import { Card, Col, Row, Layout } from 'antd';
const { Content } = Layout
export default function Main(props) {

  return (
    <Layout className="layout">
      <Content style={{ padding: '0 50px' }}>
        <Row justify="center" align='middle'
          style={{ height: "80vh" }} >
          <Card>
            <Col>
              <h2>Bienvenido al portal Armadillo</h2>
              <h1>Navegue usando el menú superior ☝️</h1>
            </Col>
          </Card>
        </Row>
      </Content>
    </Layout>
  );
}
