import DashRepo from "../../../shared/DashRepo";

const controlRepo = () => {
    const { dFetch } = DashRepo();

    const getCheckPoints = () => {
        return dFetch({
            verb: 'GET', path: 'V2/checkpoints/getCheckPoints', headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const getCheckPointDetails = (numOPT) => {
        return dFetch({
            verb: 'GET', path: `V2/checkpoints/getCheckPoint/${numOPT}`, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const changeEventToPending = async (newData) => {
        console.log(newData);
        return dFetch({
            verb: 'PUT', path: 'V2/checkpoints/approveModifyOpt', data: newData, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const cancelEventToPending = async (newData) => {
        console.log(newData);
        return dFetch({
            verb: 'PUT', path: 'V2/checkpoints/cancelRequestToModify', data: newData, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const getCheckPointsApproveAndPending = () => {
        return dFetch({
            verb: 'GET', path: 'V2/checkpoints/getCheckpointsApprovedAndPending', headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const sendCheckpointsToTMS = (newData) => {
        return dFetch({
            verb: 'POST', path: 'V2/checkpoints/sendCheckpointsToTms', data: newData, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const getCheckpointsByRange = (range) => {
        return dFetch({
            verb: 'POST', path: 'V2/checkpoints/getCheckpointsByDateRange', data: range, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    return {
        getCheckPoints,
        getCheckPointDetails,
        changeEventToPending,
        cancelEventToPending,
        getCheckPointsApproveAndPending,
        sendCheckpointsToTMS,
        getCheckpointsByRange
    }
}

export default controlRepo;