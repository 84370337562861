import { Button, Col, Form, Input, Row, Select, DatePicker, message } from 'antd';
import { useState } from 'react';

const { Option } = Select;
const dateFormat = 'DD/MM/YYYY HH:mm:ss';

const config = {
    rules: [
        {
            type: 'object',
            required: true,
            message: 'Please select time!',
        },
    ]
};

const OPTForm = ({ setDisableEditOPTButtom, setDisableFieldsOPTForm, disableFieldsOPTForm, disableEditOPTButtom }) => {

    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        message.success('Submit success!');
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    return (
        <Form
            form={form}
            name="advanced_search"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        name="opt"
                        label="OPT / Cliente"
                    >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="pntArribo"
                        label="Punto de arribo"
                        {...config}
                    >
                        <DatePicker
                            showTime format={dateFormat}
                            placeholder="Ingrese hora"
                            disabled={disableFieldsOPTForm}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="ingAlmacen"
                        label="Ingreso al almacén"
                        {...config}
                    >
                        <DatePicker
                            showTime
                            format={dateFormat}
                            placeholder="Ingrese hora"
                            disabled={disableFieldsOPTForm}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="aperPuertas"
                        label="Apertura de puertas"
                        {...config}
                    >
                        <DatePicker
                            showTime
                            format={dateFormat}
                            placeholder="Ingrese hora"
                            disabled={disableFieldsOPTForm}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="cierPuertas"
                        label="Cierre de puertas"
                        {...config}
                    >
                        <DatePicker
                            showTime
                            format={dateFormat}
                            placeholder="Ingrese hora"
                            disabled={disableFieldsOPTForm}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="salAlmacen"
                        label="Salida del almacén"
                        {...config}
                    >
                        <DatePicker
                            showTime
                            format={dateFormat}
                            placeholder="Ingrese hora"
                            disabled={disableFieldsOPTForm}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col
                    span={24}
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={disableFieldsOPTForm}
                    >
                        Aceptar
                    </Button>
                    <Button
                        type='primary'
                        danger
                        style={{
                            margin: '0 8px',
                        }}
                        disabled={disableEditOPTButtom}
                        onClick={() => {
                            form.resetFields();
                            setDisableEditOPTButtom(true);
                            setDisableFieldsOPTForm(true);
                        }}
                    >
                        Cancelar
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default OPTForm;