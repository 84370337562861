import { useState, useEffect, useRef} from 'react';
import { Form, Input, Button, Modal,  Table, Space, Popconfirm, Checkbox } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import CostingRepo from './CostingRepo';

export default function ListClients(props) {

    const [searchText, setSearchText] = useState();
    const [searchedColumn, setSearchedColumn] = useState();
    const [dataSource, setDataSource] = useState([]);
    const [isModalClient, setIsModalClient] = useState(false);
    const searchInput = useRef(null);
    const {getClients, getClientRoutes} = CostingRepo();

    useEffect(()=>{
      getClients().then(clients => {
        let dataClients = clients.data;
        dataClients.forEach(function (row, index) {
          row.key = index;
        })
        setDataSource(dataClients);
      })
    },[]);

    const handleOk = () => {
        props.setIsModalVisible(false);
    };

    const handleCancel = () => {
        props.setIsModalVisible(false);
    };

    const handleNewClient = () => {
        props.setIsModalVisible(false);
        setIsModalClient(true);
    }

    const handleNewClientCancel = () => {
      setIsModalClient(false);
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
                ref={searchInput}
            //   ref={node => {
            //     searchInput(node);
            //   }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ marginBottom: 2, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                    confirm({ closeDropdown: false });
                    setSearchText(selectedKeys[0]);
                    setSearchedColumn(dataIndex);
                    }}
                >
                    Filtrar
                </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined , padding:"10px", fontSize:"15px"}} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.current.select(), 100);
          }
        },
        render: text =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });

      const handleSearch = (selectedKeys, confirm, dataIndex) => {
          confirm();
          setSearchText(selectedKeys[0]);
          setSearchedColumn(dataIndex);
      };
      
      const handleReset = clearFilters => {
          clearFilters();
          setSearchText('');
      };

      const handleSelect = (bussinesName) => {
          let identificator = {identificator:bussinesName.identificador};
          getClientRoutes(identificator).then(routes => {
            let options = [];
            routes.data.forEach(function(row, index) {
              options.push({value:row.nombre,key:index})
            })
            props.setOptions(options);
          })
          props.setCurrentClient(bussinesName);
          props.setIsModalVisible(false);

      };
  
      
      const onFinish = (values) => {
        props.setCurrentClient(values);
        setIsModalClient(false);

      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      
      const columns = [
        {
            title: 'Empresa',
            dataIndex: 'nombre',
            key: 'nombre',
            width: '30%',
            ...getColumnSearchProps('nombre'),
        },
        {
            title: 'RUC',
            dataIndex: 'erpCodigo',
            key: 'erpCodigo',
            width: '20%',
            ...getColumnSearchProps('erpCodigo'),
        },
        {
            title: 'Address',
            dataIndex: 'direccion',
            key: 'direccion',
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                <Popconfirm title="¿Desea agregar este cliente?" onConfirm={() => handleSelect(record)}>
                    <a>Seleccionar</a>
                </Popconfirm>
                ) : null,
        },
      ];

    return(
      <div>
        <Modal width={800} title="Clientes" visible={props.isModalVisible} onOk={handleOk} onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleNewClient}>
            Agregar nuevo cliente
          </Button>,
          <Button key="submit" type="primary" onClick={handleCancel}>
            Cancelar
          </Button>
        ]}
        >
            <Table columns={columns} dataSource={dataSource} />
        </Modal>
        <Modal title="Nuevo cliente" visible={isModalClient} onCancel={handleNewClientCancel}
          footer={[
            <Button key="back" onClick={handleNewClientCancel}>
              Cancelar
            </Button>
          ]}
        >
          <Form
            name="basic"
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 20,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Nombre"
              name="nombre"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese nombre del cliente!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Guardar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
}

