import { useState, useEffect } from 'react';
import {
    Button,
    Checkbox,
    Row,
    Card,
    Col,
    Space,
    Layout
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import SyncLidermanRepo from './SyncLidermanRepo';
import { ToastOk, ToastErr } from '../../shared/Toast';
import './SyncLiderman.css';
const { Content } = Layout;

export default function SyncLiderman() {

    const { getVehiclesLid, sendVehiclesLid } = SyncLidermanRepo();
    const [listVehicles, setListVehicles] = useState([]);
    const [actualPlates, setActualPLates] = useState([]);


    useEffect(() => {
        getVehiclesLid().then(vehicle => {
            setListVehicles(vehicle);
            ToastOk("Obtención completa de vehículos")
            let arrStatus = [];
            for (let i = 0; i < vehicle.length; i++) {
                if (vehicle[i].status === 1) {
                    arrStatus.push(vehicle[i].plate)
                }
            }

        })
    }, [])

    const cleanAll = () => {
        setListVehicles(listVehicles.map(x => {
            x.status = 0;
            return x;
        }))

    }

    const saveAll = () => {
        let selectedPlates = actualPlates.map(x=>x.plate);
        let activatePlates = listVehicles.filter(x => selectedPlates.includes(x.plate));
        console.log(activatePlates);
        sendVehiclesLid(JSON.stringify(activatePlates)).then(response => {
            console.log("Sent Liderman ", response);
            ToastOk("Se guardó exitosamente")
        }).catch(error => {
            console.log("Sent Error Liderman ", error)
            ToastErr("Error al guardar")
        })
    }

    const updateAll = () => {
        getVehiclesLid().then(vehicle => {
            setListVehicles(vehicle);
            ToastOk("Actualización completa")
        })
    }

    function onChange(checkedValues) {
        let values = checkedValues;
        setListVehicles(listVehicles.map(x => {
            if (checkedValues.includes(x.plate)) {
                x.status = 1;
            } else {
                x.status = 0;
            }
            return x
        }))
        let plates = [];
        for (let i = 0; i < values.length; i++) {
            let x = { plate: values[i] }
            plates.push(x);
        }
        setActualPLates(plates)
    }



    return (
        <Content className='contentLiderman' >
            <Card className='cardLiderman' title="Vehiculos Liderman"
                extra={
                    <Space>
                        <Button size='small' onClick={cleanAll}>Limpiar</Button>
                        <Button size='small' onClick={saveAll}>Guardar</Button>
                        <Button size='small' onClick={updateAll}><ReloadOutlined /></Button>
                    </Space>
                }
                style={{ width: 380 }}>
                <Checkbox.Group style={{ width: 330 }} value={listVehicles.filter(x => x.status === 1).map(x => x.plate)} onChange={onChange} >
                    {listVehicles.map(vehicle => (
                        <Row className='rowPlate' key={vehicle.plate}>
                            <Col flex='auto'>
                                <Checkbox
                                    value={vehicle.plate}
                                    style={{
                                        lineHeight: '32px',
                                    }}
                                    checked={vehicle.status === 1}
                                >
                                    <div className='title'>
                                        <h4 className='titlePlaca'>{vehicle.plate}</h4>
                                        <h5 style={{ color: '#8D8E8E' }} className='titleDescrip'>{vehicle.placeName} </h5>
                                    </div>
                                </Checkbox>
                            </Col>
                            <Col flex='30px' style={{ alignSelf: "center" }}>
                                {(vehicle.status === 1) ? <div style={{ fontSize: 15 }}>
                                    🔵
                                </div> : <div style={{ alignSelf: "center", fontSize: 18 }}>
                                    ⚫
                                </div>}
                            </Col>
                        </Row>
                    ))}
                </Checkbox.Group>
            </Card>
        </Content>
    );
}