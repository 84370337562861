import { useState } from 'react';
import { Card, Col, Row, Form, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import PrincipalStatesTable from './PrincipalStatesTable';
import controlRepo from '../helpers/controlRepo';
import EditableForm from '../Forms/editableForm';

const EditContainer = () => {

    const { cancelEventToPending } = controlRepo();

    const [form] = Form.useForm();
    const [valuesOfForm, setValuesOfForm] = useState({});
    const [datePickerValue, setDatePickerValue] = useState("");
    const [selectedValue, setSelectedValues] = useState("");
    const [disableForm, setDisableForm] = useState(true);
    const [reloadButton, setRealoadButton] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);
    const [loadingCancel, setLoadingCancel] = useState(false);
    const [disabledDatePicker, setDisabledSetPicker] = useState({
        pntArribo: true,
        ingAlmacen: true,
        aperPuertas: true,
        cierPuertas: true,
        salAlmacen: true,
      });

    const setReload = () => {
        setRealoadButton(true);
        setTimeout(() => {
            setRealoadButton(false)
        }, 1000);
    }

    const edit = (record, opt, optguid, ort, ortguid) => {
        form.setFieldsValue({
            ...record,
            pntArribo: record.pntArribo, //moment(record.pntArribo[0], dateFormat)
            ingAlmacen: record.ingAlmacen,
            aperPuertas: record.aperPuertas,
            cierPuertas: record.cierPuertas,
            salAlmacen: record.salAlmacen,
            opt, 
            optguid, 
            ort, 
            ortguid,
        });
        setDisableButtons(true);
        setDisableForm(false);
        setDisabledSetPicker({
            pntArribo: true,
            ingAlmacen: true,
            aperPuertas: true,
            cierPuertas: true,
            salAlmacen: true,
        })
    };

    const cancel = () => {
        form.setFieldsValue({
            opt: '',
            tipo: '',
            optguid: '',
            ort: '',
            ortguid: '',
            pntArribo: '',
            ingAlmacen: '',
            aperPuertas: '',
            cierPuertas: '',
            salAlmacen: ''
        });
        setValuesOfForm(null);
        setDisableButtons(false);
        setDisableForm(true);
        setSelectedValues({
            pntArribo: null,
            ingAlmacen: null,
            aperPuertas: null,
            cierPuertas: null,
            salAlmacen: null,
        });
    };

    const cancelRequest = async (record, opt, optguid, ort, ortguid) => {
        setLoadingCancel(true)
        const result = {
            numOpt: opt,
            optguid,
            numeroORT: ort,
            ortguid,
            tramoGUID: record.distribucionGUID
        }
        cancelEventToPending(result)
            .then((data) => {
                console.log(data);
                setLoadingCancel(false)
                setTimeout(() => {
                    setReload();
                }, 500);
            });
    }

    return (
        <div
            style={{
                marginTop: 30,
            }}>
            <Row gutter={24}>
                <Col span={12}>
                    <Card
                        type="inner"
                        title="Tabla de Estados"
                        extra={
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<ReloadOutlined />}
                                onClick={setReload}
                                loading={reloadButton}
                            />
                        }
                    >
                        <PrincipalStatesTable edit={edit} reloadButton={reloadButton} disableButtons={disableButtons} loadingCancel={loadingCancel} cancelRequest={cancelRequest} />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card type="inner" title="Editar OPT" bordered={true}>
                        <EditableForm form={form} disableForm={disableForm} cancel={cancel} setReload={setReload} valuesOfForm={valuesOfForm} setValuesOfForm={setValuesOfForm} selectedValues={selectedValue} setSelectedValues={setSelectedValues} disabledDatePicker={disabledDatePicker} setDisabledSetPicker={setDisabledSetPicker} 
                        datePickerValue={datePickerValue} setDatePickerValue={setDatePickerValue} />
                    </Card> 
                </Col>
            </Row>
        </div>
    );
}

export default EditContainer;