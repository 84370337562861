import { Statistic } from "antd";
import { useState, useEffect } from "react";

const StatisticCell = ({ total, isPorcent, isUN }) => {

    const [color, setColor] = useState('black');
    const [result, setResult] = useState(total)

    useEffect(() => {
        handleVerify()
    }, [])

    const handleVerify = () => {
        if (total < 0) {
            setColor('#cf1322');
            setResult(Math.abs(total))
        }
    }

    return (
        <Statistic
            className='cellValue'
            value={result ? result : 0}
            valueStyle={{ color }}
            precision={isPorcent || isUN ? 1 : 0}
            suffix={isPorcent ? '%' : ''}
        />
    )
}

export default StatisticCell;