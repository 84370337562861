import { Table } from "antd";
import { useEffect, useState } from "react";
import VerificationTable from "./VerificationTable";

const originalData = [];
for (let i = 0; i < 3; ++i) {
  originalData.push({
    key: i.toString(),
    opt: "123213123",
    conductor: "Driver " + i,
    vehiculo: "10.3.4.5654",
    fechayhora: "2014-12-24 23:12:00",
    tipo: "Jack",
    hora: 500,
    content: [
      {
        key: i.toString(),
        evento: "Recojo",
        cliente: "Idemia SAC",
        pntArribo: ["12/12/2022 15:09:19"],
        ingAlmacen: ["12/12/2022 20:09:19"],
        aperPuertas: ["12/12/2022 20:09:19"],
        cierPuertas: ["12/12/2022 20:09:19"],
        salAlmacen: ["12/12/2022 20:09:19"],
        estado: "espera",
      },
      {
        key: (i + 1).toString(),
        evento: "Entrega",
        cliente: "Idemia SAC",
        pntArribo: ["12/12/2022 20:09:19"],
        ingAlmacen: ["12/12/2022 20:09:19"],
        aperPuertas: ["12/12/2022 20:09:19"],
        cierPuertas: ["12/12/2022 20:09:19"],
        salAlmacen: ["12/12/2022 19:09:19"],
        estado: "aprobado",
      },
    ],
  });
}

const PrincipalVerificationTable = ({ reloadButton }) => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [reloadButton]);

  const fetchData = () => {
    setLoading(true);
    setTimeout(() => {
      setData(originalData);
      setLoading(false);
    }, 1000);
  };

  const columns = [
    {
      title: "OPT",
      dataIndex: "opt",
      width: 140,
      render: (text) => <b>{text}</b>,
    },
    {
      title: "Conductor",
      dataIndex: "conductor",
    },
    {
      title: "Vehiculo",
      dataIndex: "vehiculo",
    },
    {
      title: "Fecha y hora",
      dataIndex: "fechayhora",
    },
    {
      title: "Tiempo de Inicio",
      dataIndex: "fechayhora",
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      pagination={false}
      loading={loading}
      expandable={{
        expandedRowRender: (record) => (
          <VerificationTable
            opt={record.opt}
            content={record.content}
            reloadButton={reloadButton}
          />
        ),
      }}
    />
  );
};

export default PrincipalVerificationTable;
