import React, { useEffect, useState } from 'react';
import { Table, Card, Tag, Alert, Button, DatePicker, Row, Spin } from 'antd';
import OperationRepo from './OperationRepo';
import { LoadingOutlined } from '@ant-design/icons';
import'./operation.css';
const { add } = require("date-fns")
const { RangePicker } = DatePicker;
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);

const date = new Date()
const currentDate = date.toISOString();
const currentDate2 = (new Date (date.setDate(date.getDate()-7)).toISOString());

export default function Operation() {

    const [allOperations, setAllOperations] = useState();
    const [startDate, setStartDate] = useState(currentDate);
    const [endDate, setEndDate] = useState(currentDate2);
    const [loading, setLoading] = useState(true);
    const {getOperations} = OperationRepo();

    useEffect(()=>{
        getOperations(startDate, endDate, false).then(res => {
            let data = res.data;
            data.forEach(function(row, index){
                row.key = index;
            }); 
            setAllOperations(data)
            setLoading(false);
        });
    }, [])

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        if (date){
            const d = new Date(date);
            const newDate = d.toLocaleDateString(undefined, options);
            return newDate;
        }else{
            return "..."
        }
        
    }

    const expandedRowRender = (props) => {
        const columns = [
            {
                title: 'Acción',
                dataIndex: 'accion',
                key: 'accion',
            },
            
            {
                title: 'checkpointArribo',
                dataIndex: 'checkpointArribo',
                key: 'checkpointArribo',
                render: (text, record) => {
                    const date = formatDate(record.checkpointArribo)
                    return(
                        ((record.emptyErrors.indexOf("checkpointArribo") > -1) || (record.comparationErrors.indexOf("checkpointArribo") > -1))? 
                        <span style={{color:'red'}}>
                            {date}
                        </span> 
                        :
                        <span>
                            {date}
                        </span>
                    )
                },
            },
            {
                title: 'checkpointIngreso',
                dataIndex: 'checkpointIngreso',
                key: 'checkpointIngreso',
                render: (text, record) => {
                    const date = formatDate(record.checkpointIngreso)
                    return(
                        ((record.emptyErrors.indexOf("checkpointIngreso") > -1) || (record.comparationErrors.indexOf("checkpointIngreso") > -1))? 
                        <span style={{color:'red'}}>
                            {date}
                        </span> 
                        :
                        <span>
                            {date}
                        </span>
                    )
                },
            },
            {
                title: 'checkpointInicioAtencion',
                dataIndex: 'checkpointInicioAtencion',
                key: 'checkpointInicioAtencion',
                render: (text, record) => {
                    const date = formatDate(record.checkpointInicioAtencion)
                    return(
                        ((record.emptyErrors.indexOf("checkpointInicioAtencion") > -1) || (record.comparationErrors.indexOf("checkpointInicioAtencion") > -1))? 
                        <span style={{color:'red'}}>
                            {date}
                        </span> 
                        :
                        <span>
                            {date}
                        </span>
                    )
                },
            },
            {
                title: 'checkpointFinAtencion',
                dataIndex: 'checkpointFinAtencion',
                key: 'checkpointFinAtencion',
                render: (text, record) => {
                    const date = formatDate(record.checkpointFinAtencion)
                    return(
                        ((record.emptyErrors.indexOf("checkpointFinAtencion") > -1) || (record.comparationErrors.indexOf("checkpointFinAtencion") > -1))? 
                        <span style={{color:'red'}}>
                            {date}
                        </span> 
                        :
                        <span>
                            {date}
                        </span>
                    )
                },
            },
            {
                title: 'checkpointPartida',
                dataIndex: 'checkpointPartida',
                key: 'checkpointPartida',
                render: (text, record) => {
                    const date = formatDate(record.checkpointPartida)
                    return(
                        ((record.emptyErrors.indexOf("checkpointPartida") > -1) || (record.comparationErrors.indexOf("checkpointPartida") > -1))? 
                        <span style={{ color:'red'}}>
                            {date}
                        </span> 
                        :
                        <span>
                            {date}
                        </span>
                    )
                },
            }, 
            {
                title: 'Mensajes',
                dataIndex: 'msgErrors',
                key: 'msgErrors',
                render: (_, record) => (
                    <>
                    {record.msgErrors.map((tag) => {
                            return (
                                <Tag color={'red'} key={tag}>
                                    {tag.toUpperCase()}
                                </Tag>
                              );
                        })}
                    </>
                ),

            },      
        ];
        
        return <Table columns={columns} dataSource={props.tms_ort_milestones} pagination={false} />;
    };

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            key: 'fecha',
        },
        {
            title: 'Operación de Trans',
            dataIndex: 'numero',
            key: 'numero',
        },
        {
            title: 'Cliente',
            dataIndex: 'client',
            key: 'client',
        },
        {
            title: 'Unidad',
            dataIndex: 'vehicle',
            key: 'vehicle',
        },
        {
            title: 'Operador',
            dataIndex: 'oper',
            key: 'oper',
        },
        {
            title: 'Status',
            dataIndex: 'tags',
            key: 'tags',
            render: (_, record) => (
                <>
                {record.tags.map((tag) => {
                        let color = tag === "Vacío" ? "yellow" : "red";
                        if(tag === "Coherente"){
                            color = "geekblue";
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                          );
                    })}
                </>
            ),
        },
        // {
        //     title: 'Action',
        //     key: 'operation',
        //     render: () => <a>Publish</a>,
        // },
    ];

    const formatFields = (data) => {
        data.forEach(function(row, index){
            row.key = index;
        }); 
        return data;
    }

    const onChange = (date, dateString) => {
        console.log(dateString);
        setStartDate((new Date(dateString[0])).toISOString());
        setEndDate(add(new Date(dateString[1]),{ days:2 }).toISOString());
    };

    const UpdateArmadillo = () => {
        setLoading(true);
        getOperations(startDate, endDate, false).then(res => {
            let data = res.data;
            const newData = formatFields(data);
            setAllOperations(newData);
            setLoading(false);
        })
    }

    const UpdateTms = () => {
        setLoading(true);
        getOperations(startDate, endDate, true).then(res => {
            let data = res.data;
            const newData = formatFields(data);
            setAllOperations(newData);
            setLoading(false);
        })
    }

    const searchDate = () => {
        setLoading(true);
        console.log(startDate);
        console.log(endDate);
        getOperations(startDate, endDate, false).then(res => {
            console.log(res);
            let data = res.data;
            const newData = formatFields(data);
            setAllOperations(newData)
            setLoading(false);
        });
    }


    return (
        <Card title={'Operaciones'}>
            <Row>
                <Button type="primary" htmlType="submit" onClick={UpdateArmadillo} style={{marginBottom:10, borderRadius: 6, }}>
                    Actualizar Armadillo
                </Button>
                <Button type="primary" htmlType="submit" onClick={UpdateTms} style={{ marginBottom:10, marginLeft:10, borderRadius: 6, }}>
                    Actualizar TMS
                </Button>
            </Row>
            <RangePicker onChange={onChange}/>
            <Button type="primary" htmlType="submit" onClick={searchDate} style={{ marginTop:10, marginBottom:15, marginLeft:10, borderRadius: 6, }}>
                Buscar
            </Button>
            {loading ? <div style={{ height: "110px", position: "relative", minWidth: "100%" }}>
                <div style={{ position: "relative", top: "50%", left: "30%", color: "black" }}><Spin indicator={antIcon} /> Cargando la lista de Opts </div>
            </div> :
                <Table
                //className="components-table-demo-nested"
                columns={columns}
                expandable={{
                    expandedRowRender,
                }}
                dataSource={allOperations}
                pagination={{
                    pageSize: 20,
                  }}
            />
            }
        </Card>
    );
};