import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Spin, Popconfirm, Form, Modal, Input, Button, Cascader,
    Row, Col, Space, AutoComplete, Select, InputNumber, Card
} from 'antd';
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import ListClients from './ListClients';
import CostingRepo from './CostingRepo';
import TableQuote from './TableQuote';
import { ToastOk, ToastErr } from '../../shared/Toast';
const { Option } = Select;
const { SHOW_CHILD } = Cascader;
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);

export default function Quote() {

    const [form] = Form.useForm();
    const [formModalNewPrice] = Form.useForm();
    const [formModalAdd] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentClient, setCurrentClient] = useState('');
    const [dataQuote, setDataQuote] = useState([]);
    const [currentQuote, setCurrentQuote] = useState({});
    const [dataProf, setDataProf] = useState({});
    const [dataVehicles, setDataVehicles] = useState()
    const [dataOperators, setDataOperators] = useState()
    const [childrenOperators, setChildrenOperators] = useState([]);
    const [childrenVehicles, setChildrenVehicles] = useState([]);
    const [childrenTolls, setChildrenTolls] = useState([])
    const [childrenGards, setChildrenGards] = useState([])
    const [options, setOptions] = useState([]);
    const [modalNewPrice, setModalNewPrice] = useState(false);
    const [dataChangePrice, setDataChangePrice] = useState({});
    const [loadingCost, setLoadingCost] = useState(false);
    const [dataGards, setDataGards] = useState([])
    const [dataTolls, setDataTolls] = useState([])
    const [selectDataGards, setSelectDataGards] = useState([])
    const [isEdit, setIsEdit] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const { newQuote, saveProf, newVersionQuote, getOperators, getVehicles, getGards, getTolls } = CostingRepo();

    const [modalAddShow, setModalAddShow] = useState(false);
    const [modalAddTitle, setModalAddTitle] = useState("");
    const [modalAddData, setModalAddData] = useState([]);
    const [addedListOptions, setAddedListOptions] = useState();
    const [addModalSelectedValues, setAddModalSelectedValues] = useState()
    const [modalAddField, setModalAddField] = useState()
    const [indexAddRoute, setIndexAddRoute] = useState();

    const dataTableMemo = useMemo(() => dataQuote, [dataQuote])
    const onModalAddActivate = (formField, dataSelect, title, addedListOptions, indexRoute) => {
        setModalAddTitle(title)
        setModalAddData(dataSelect)
        setModalAddField(formField)
        setIndexAddRoute(indexRoute)
        setAddedListOptions(addedListOptions)
        setAddModalSelectedValues([])
        setModalAddShow(true)
    }
    const onModalAddFinish = () => {
        let lastForm = form.getFieldsValue();
        let temp = lastForm.routes[indexAddRoute][modalAddField] || [];
        lastForm.routes[indexAddRoute][modalAddField] = [...temp, ...addModalSelectedValues]
        form.setFieldsValue({ ...lastForm })
        setModalAddShow(false)
    }
    const onModalAddDeactivate = () => {
        setModalAddShow(false)
    }
    const onModalAddCascaderSelect = (value) => {
        if (!value) return
        value = value?.pop()
        setAddModalSelectedValues([...addModalSelectedValues, value])
        console.log(value)
    }
    useEffect(() => {
        const e = location.state && location.state.edit;
        const client = e ? location.state.clientName : "";
        form.setFieldsValue({ ...form.values, clientName: e ? client : currentClient.nombre });
    }, [currentClient])

    useEffect(() => {
        Promise.all([
            getOperators(),
            getVehicles(),
            getGards(),
            getTolls()
        ]).then(x => {
            const operators = x[0].data.map(operator => {
                return <Option key={operator.id}>{operator.description}</Option>
            });
            const vehicles = x[1].data.map(vehicle => {
                return <Option key={vehicle.id}>{vehicle.name}</Option>
            });
            const childrenGards = x[2].data[1].map(gard => {
                return <Option key={gard.id}>{gard.productName}</Option>
            })
            const childrenTolls = x[3].data[1].map(toll => {
                return <Option key={toll.id}>{toll.productName}</Option>
            })
            setDataGards(x[2].data[0])
            setDataTolls(x[3].data[0])
            setDataVehicles(x[1].data.map(({ id, plate, name }) => ({ id, value: id.toString(), key: id, label: `${name} - ${plate}` })))
            setDataOperators(x[0].data.map(({ id, description }) => ({ id, value: id.toString(), key: id, label: description })))
            setChildrenTolls(childrenTolls)
            setChildrenGards(childrenGards)
            setChildrenOperators(operators)
            setChildrenVehicles(vehicles)
        });
        let state = location.state
        let edit = state && state.edit
        setIsEdit(location.state && location.state.edit);
        if (edit) {
            state.routesPerCar = state.costing_routes_data_final_quotes;
            for (let i = 0; i < state.costing_routes_quotes.length; i++) {
                state.costing_routes_quotes[i].operators = typeof state.costing_routes_quotes[i].operators === 'string' || state.costing_routes_quotes[i].operators instanceof String ? JSON.parse(state.costing_routes_quotes[i].operators) :  state.costing_routes_quotes[i].operators;
                state.costing_routes_quotes[i].peajes = typeof state.costing_routes_quotes[i].operators === 'string' || state.costing_routes_quotes[i].operators instanceof String ? JSON.parse(state.costing_routes_quotes[i].peajes) :  state.costing_routes_quotes[i].operators;
                state.costing_routes_quotes[i].vehicles = typeof state.costing_routes_quotes[i].operators === 'string' || state.costing_routes_quotes[i].operators instanceof String ? JSON.parse(state.costing_routes_quotes[i].vehicles) :  state.costing_routes_quotes[i].operators;
                state.costing_routes_quotes[i].guard = typeof state.costing_routes_quotes[i].operators === 'string' || state.costing_routes_quotes[i].operators instanceof String ? JSON.parse(state.costing_routes_quotes[i].guardDefinition) :  state.costing_routes_quotes[i].operators;
            }
            setCurrentClient(location.state.clientName);
            setCurrentQuote(location.state);
            setDataQuote([state]);
            form.setFieldsValue({ ...form.values, clientName: location.state.clientName, description: location.state.description, routes: location.state.costing_routes_quotes });
            Promise.all([
                getOperators(),
                getVehicles()
            ]).then(x => {
                const operators = x[0].data.map(operator => {
                    return <Option key={operator.id}>{operator.description}</Option>
                });
                const vehicles = x[1].data.map(vehicle => {
                    return <Option key={vehicle.id}>{vehicle.name}</Option>
                });
                setChildrenOperators(operators);
                setChildrenVehicles(vehicles);
            });
        } else {

        }
    }, [])

    const showModal = () => {
        setIsModalVisible(true);
    };

    const onFinishData = values => {
        setLoadingCost(true);
        let completeProfor = {}
        newQuote(values).then(quote => {
            setDataQuote([quote.data]);
            completeProfor = {
                ...quote.data,
                routes_data_initial: values.routes,
                routes_data_final: quote.data.routes
            }
            completeProfor.clientName = quote.data.clientName;
            completeProfor.description = quote.data.description;
            completeProfor.identificator = quote.data.identificator;
            completeProfor.totalCost = quote.data.totalCost;
            completeProfor.routes_data_initial = quote.data.routes;
            completeProfor.routes_data_final = quote.data.routesPerCar;
            completeProfor.idFather = currentQuote.id

            setDataProf(completeProfor);
            setLoadingCost(false);
        }).catch((error) => {
            setLoadingCost(false);
        })
    };

    const saveProforma = () => {
        if (isEdit) {
            saveNewVersionQuote(dataProf)
        } else {
            saveProf(dataProf).then(x => {
                if (x.message === 'Proforma creada') {
                    form.resetFields();
                    setDataQuote([]);
                    ToastOk("Proforma guardada satisfactoriamente");
                    history.push({ pathname: "/costing/historyQuote" });
                } else {
                    ToastErr("Error al momento de guardar")
                }
            })
        }

    }
    const saveNewVersionQuote = () => {
        newVersionQuote(dataProf).then(x => {
            if (x.message === 'Nueva version de cotización creada') {
                history.push({ pathname: "/costing/historyQuote" });
                ToastOk("Cotización - Nueva versión creada")
            } else {
                ToastErr("Error de creación")
            }
        });
    }
    const handleNewPriceChange = (record) => {
        setModalNewPrice(true)
        setDataChangePrice(record)
    }
    const handleNewPriceFailed = () => {
        setModalNewPrice(true)
    }

    const handleNewPriceCancel = () => {
        formModalNewPrice.resetFields();
        setModalNewPrice(false)
    }
    const onFinishNewPrice = (value) => {
        setDataQuote(dataQuote => dataQuote.map(quote =>
        ({
            ...quote,
            totalPrice: quote.routesPerCar.reduce((acc, cur) => {
                return acc += Number(cur.finalPrice)
            }, 0).toFixed(2),
            routesPerCar: quote.routesPerCar.map(rpc => {
                if (dataChangePrice.key === rpc.key) {
                    rpc.margin = (((value.finalPrice - parseFloat(rpc.costTotalPerVehicle)) / value.finalPrice) * 100).toFixed(2) + "%"
                    rpc.finalPrice = value.finalPrice
                }
                return rpc
            })
        })));
        formModalNewPrice.resetFields();
        setModalNewPrice(false);
    }

    return (
        <Card title="Creación de nueva proforma" style={{ background: '#D3D3D3' }}>
            <div className='resumeContent'>
                <Form form={form} name="dynamic_form_nest_item" onFinish={onFinishData} autoComplete="off" layout="horizontal"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}>
                    <Card style={{ width: '50%', marginBottom: 5 }} title={'1. Seleccione el cliente'}>
                        <Form.Item label="Cliente" name='clientName' rules={[{ required: true, message: 'Ingrese el cliente!' }]} >
                            <Input placeholder="Cliente" onClick={showModal} autoComplete={false} disabled={isEdit} />
                        </Form.Item>
                        <Form.Item label="Descripción" name='description'>
                            <Input placeholder="Descripción" />
                        </Form.Item>
                    </Card>

                    <Form.List name="routes" style={{ fontSize: 8, marginBottom: 5 }}>
                        {(fields, { add, remove }) => (
                            (currentClient !== '') ? <>
                                <Card title="2. Seleccione rutas">
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Agregar ruta
                                    </Button>
                                </Card>
                                <div style={{ display: 'flex', overflow: 'scroll' }}>
                                    {fields.map(({ key, name, ...restField }) => {
                                        return (
                                            <Card style={{ flexShrink: 0, width: '25%' }}>
                                                <Row justify="space-between" style={{ backgroundColor: "#8fb2db", padding: "0px 10px", }}>
                                                    <Col>
                                                        <h4>Ruta {name + 1}</h4>
                                                    </Col>
                                                    <span><MinusCircleOutlined style={{ color: 'red' }} onClick={() => remove(name)} /></span></Row>
                                                <Col key={key} style={{ border: "1px solid #8fb2db", padding: 5 }} align="baseline">

                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'routeHome']}
                                                        style={{ marginBottom: 5 }}
                                                        rules={[{ required: true, message: 'Ingrese ruta-inicio' }]}
                                                        label="Lugar inicio"
                                                    >
                                                        <AutoComplete
                                                            options={options}
                                                            placeholder="Inicio"
                                                            filterOption={(inputValue, option) =>
                                                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'routeEnd']}
                                                        style={{ marginBottom: 5 }}
                                                        rules={[{ required: true, message: 'Ingrese ruta-fin' }]}
                                                        label="Lugar fin"
                                                    >
                                                        <AutoComplete
                                                            options={options}
                                                            placeholder="Fin"
                                                            filterOption={(inputValue, option) =>
                                                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'guard']}
                                                        style={{ marginBottom: 5, width: "100%" }}
                                                        label="Resguardo"
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            allowClear
                                                            placeholder="Resguardos"
                                                            dropdownStyle={{ display: 'none' }}
                                                            defaultValue={[]}
                                                        >
                                                            {childrenGards}
                                                        </Select>
                                                    </Form.Item>
                                                    <Row justify='end' style={{ marginBottom: 5 }} >
                                                        <Button type="primary" onClick={() => onModalAddActivate('guard', dataGards, 'Resguardos', childrenGards, name)}>Añadir resguardos</Button>
                                                    </Row>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'peajes']}
                                                        style={{ marginBottom: 5, width: "100%" }}
                                                        // rules={[{ required: true, message: 'Ingrese peajes' }]}
                                                        label="Peajes"
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            allowClear
                                                            placeholder="Peajes"
                                                            dropdownStyle={{ display: 'none' }}
                                                            defaultValue={[]}
                                                        >
                                                            {childrenTolls}
                                                        </Select>
                                                    </Form.Item>
                                                    <Row justify='end' style={{ marginBottom: 5 }} >
                                                        <Button type="primary" onClick={() => onModalAddActivate('peajes', dataTolls, 'Peajes', childrenTolls, name)}>Añadir peajes</Button>
                                                    </Row>
                                                    <Form.Item
                                                        name={[name, 'distance']}
                                                        style={{ marginBottom: 5 }}
                                                        rules={[{ required: true, message: 'Ingrese distancia' }]}
                                                        label="Distancia(km)"
                                                    >
                                                        <InputNumber
                                                            style={{ width: '100%' }}
                                                            placeholder="KM" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        style={{ marginBottom: 5 }}
                                                        name={[name, 'time']}
                                                        rules={[{ required: true, message: 'Ingrese tiempo' }]}
                                                        label="Duracion(hrs)"
                                                    >
                                                        <InputNumber
                                                            style={{ width: '100%' }}
                                                            placeholder="Horas" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        style={{ marginBottom: 5 }}
                                                        name={[name, 'flete']}
                                                        rules={[{ required: true, message: 'Ingrese % Pasivo' }]}
                                                        label="% Pasivo"
                                                    >
                                                        <InputNumber
                                                            style={{ width: '100%' }}
                                                            placeholder="% Pasivo" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        style={{ marginBottom: 5 }}
                                                        name={[name, 'travelExpenses']}
                                                        rules={[{ required: true, message: 'Ingrese viáticos' }]}
                                                        label="Viáticos"
                                                    >
                                                        <InputNumber
                                                            style={{ width: '100%' }}
                                                            placeholder="Viáticos" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        style={{ marginBottom: 5 }}
                                                        name={[name, 'vehicles']}
                                                        rules={[{ required: true, message: 'Ingrese Unidades' }]}
                                                        label="Unidades"
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            allowClear
                                                            placeholder="Selecione Unidades"
                                                            dropdownStyle={{ display: 'none' }}
                                                        >
                                                            {childrenVehicles}
                                                        </Select>
                                                    </Form.Item>
                                                    <Row justify='end' style={{ marginBottom: 5 }} >
                                                        <Button type="primary" onClick={() => onModalAddActivate('vehicles', dataVehicles, 'Unidades', childrenVehicles, name)}>Añadir unidades</Button>
                                                    </Row>
                                                    <Form.Item
                                                        {...restField}
                                                        style={{ marginBottom: 5 }}
                                                        name={[name, 'operators']}
                                                        rules={[{ required: true, message: 'Ingrese operadores' }]}
                                                        label="Operadores"
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            allowClear
                                                            dropdownStyle={{ display: 'none' }}
                                                            placeholder="Operadores"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {childrenOperators}
                                                        </Select>
                                                    </Form.Item>
                                                    <Row justify='end' style={{ marginBottom: 5 }} >
                                                        <Button type="primary" onClick={() => onModalAddActivate('operators', dataOperators, 'Operadores', childrenOperators, name)}>Añadir operadores</Button>
                                                    </Row>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'margin']}
                                                        style={{ marginBottom: 5 }}
                                                        rules={[{ required: true, message: 'Ingrese margen' }]}
                                                        label="Margen"
                                                    >
                                                        <InputNumber
                                                            placeholder="Margen" />
                                                    </Form.Item>
                                                </Col>
                                            </Card>
                                        )
                                    })}
                                </div>


                            </>
                                : <></>
                        )}
                    </Form.List>

                    <Form.Item >
                        <Button type="primary" htmlType="submit" style={{ borderRadius: 6, }} >
                            Calcular costos
                        </Button>
                    </Form.Item>
                </Form>
                <Row >
                    {loadingCost ? <div style={{ height: "110px", position: "relative", minWidth: "100%" }}>
                        <div style={{ position: "relative", top: "50%", left: "30%", color: "black" }}><Spin indicator={antIcon} /> Calculando costos </div>
                    </div> :
                        <TableQuote style={{ width: "100%" }} handleNewPriceChange={handleNewPriceChange} dataQuote={dataTableMemo} />
                    }
                </Row>
                <Row className='rowButtons' justify="space-between">
                    <Col >
                        <Space>
                            <div
                                style={{
                                    width: 70,
                                    marginLeft: 0,
                                }}
                            >
                                <Popconfirm
                                    placement="rightBottom"
                                    title={"Seguro que desea guardar?"}
                                    onConfirm={saveProforma}
                                    okText="Ok"
                                    cancelText="No"
                                >
                                    <Button style={{
                                        marginBottom: 20,
                                        borderRadius: 6,
                                    }}
                                        type="primary">
                                        {(isEdit) ? "Nueva versión" : "Guardar Proforma"}
                                    </Button>
                                </Popconfirm>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <ListClients
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    setCurrentClient={setCurrentClient}
                    setOptions={setOptions}
                />
                <Modal title={`Añadir ${modalAddTitle}`} visible={modalAddShow} onCancel={onModalAddDeactivate}
                    footer={[
                        <Button key="ok" onClick={onModalAddFinish}>
                            Aceptar
                        </Button>,
                        <Button key="back" onClick={onModalAddDeactivate}>
                            Cancelar
                        </Button>
                    ]}
                >
                    <Row>
                        <Form.Item>
                            <Cascader
                                options={modalAddData}
                                onChange={onModalAddCascaderSelect}
                                placeholder={`Listar ${modalAddTitle}`}>
                            </Cascader>
                        </Form.Item>
                    </Row>
                    <Form.Item>
                        <Select
                            mode="multiple"
                            placeholder={`${modalAddTitle} añadidos`}
                            dropdownStyle={{ display: 'none' }}
                            defaultValue={[]}
                            value={addModalSelectedValues}
                        >
                            {addedListOptions}
                        </Select>
                    </Form.Item>
                </Modal>
                <Modal title="Ingresar nuevo precio" visible={modalNewPrice} onCancel={handleNewPriceCancel}
                    footer={[
                        <Button key="back" onClick={handleNewPriceCancel}>
                            Cancelar
                        </Button>
                    ]}
                >
                    <Form
                        form={formModalNewPrice}
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinishNewPrice}
                        onFinishFailed={handleNewPriceFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Nuevo precio final"
                            name="finalPrice"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese el nuevo precio!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Guardar
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </Card >
    );
}

