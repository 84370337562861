import { DatePicker, Input, Space, Button, Form, Card, Col, Row, message } from "antd";
import { useState } from "react";
import controlRepo from "../helpers/controlRepo";
import ModalDetail from "../Modal/ModalDetail";
const { RangePicker } = DatePicker;

const { Search } = Input;

const rangeConfig = {
  rules: [
    {
      type: "array",
      required: true,
      message: "Please select time!",
    },
  ],
};

const InputDatePicker = ({setDataTable, setLoading}) => {

  const { getCheckPointDetails, getCheckpointsByRange } = controlRepo();

  const [loadingButtonRangePicker, setLoadingButtonRangePicker] = useState(false);
  const [loadingButtonToday, setLoadingButtonToday] = useState(false);
  const [optDetails, setOptDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dates, setDates] = useState(null);

  const onSearch = (value) => {
    if (value === "") return;
    setLoadingButtonToday(true);
    getCheckPointDetails(value)
      .then(({ data }) => {
        setOptDetails(data[0]);
        setLoadingButtonToday(false);
        setIsModalOpen(true);
      })
      .catch((err) => {
        setLoadingButtonToday(false);
        console.log(err);
      });
  };

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const onFinish = (fieldsValue) => {
    setLoadingButtonRangePicker(true);
    setLoading(true);
    // Should format date value before submit.
    const rangeTimeValue = fieldsValue["dateRange"];
    const values = {
      ...fieldsValue,
      dateRange: [
        rangeTimeValue[0].format("DD/MM/YYYY"),
        rangeTimeValue[1].format("DD/MM/YYYY"),
      ],
    };
    getCheckpointsByRange(values)
      .then(({data}) => {
        setLoadingButtonRangePicker(false);
        setLoading(false);
        setDataTable(data);
      })
      .catch(err => {
        message.error("Ocurrio un error al consultar la información", 3);
        setLoadingButtonRangePicker(false);
        setLoading(false);
        console.log(err)
      })
  };

  return (
    <div>
      <Row
        gutter={12}
        style={{
          background: "rgba(128, 128, 128, 0.08)",
          padding: "20px 15px",
        }}
      >
        <Col span={10}>
          <Card type="inner" title="Buscar OPT" bordered={false}>
            <Search
              placeholder="Número de OPT"
              allowClear
              enterButton="Buscar"
              size="middle"
              loading={loadingButtonToday}
              onSearch={onSearch}
            />
            <ModalDetail
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              optDetails={optDetails}
            />
          </Card>
        </Col>
        <Col span={14}>
          <Form onFinish={onFinish}>
            <Card
              type="inner"
              title="Elegir intervalo de tiempo"
              bordered={false}
            >
              <Space align="center">
                <Form.Item name="dateRange" {...rangeConfig}>
                  <RangePicker
                    disabledDate={disabledDate}
                    onCalendarChange={(val) => setDates(val)}
                    onOpenChange={onOpenChange}
                    format="DD/MM/YYYY"
                    status="warning"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loadingButtonRangePicker}
                  >
                    Aceptar
                  </Button>
                </Form.Item>
              </Space>
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default InputDatePicker;
