import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
//import '../style/main.css';
import { Row, Col, Layout, Menu, Typography, Card, Spin } from 'antd';
import { CarOutlined } from '@ant-design/icons';
import VehicleRepo from './VehicleRepo';
import _ from 'lodash';
import Features from './Features';
import ImageVehicle from './ImageVehicle';
import './vehicle.css';
const { Content, Sider } = Layout;
const { SubMenu } = Menu;
const { Title } = Typography;

export default function HomeVehicle() {

  const { getVehicles, getVehiclesSpec } = VehicleRepo();
  const [menuCategory, setMenuCategory] = useState([]);
  const [listVehicle, setListVehicle] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [collapsed, setColapsed] = useState(false);
  const [rootKeys, setRootKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [plate, setPlate] = useState("");
  const [selectDataVehicle, setSelectDataVehicle] = useState({});

  useEffect(() => {
    getVehicles().then(vehicle => {
      const arr = vehicle.data;
      setListVehicle(vehicle.data);
      console.log(arr);

      let arrCat = _.uniqBy(arr, 'manufacturer');

      arrCat.forEach(function (row, index) {
        row.id_cat = index;
        row.open = false;
      });
      setMenuCategory(arrCat);
      let keys = _.map(menuCategory, 'id_cat');
      setRootKeys(keys);

      onSelectVehicle(arrCat[0])
    });
  }, [])

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onCollapse = (collapsed) => {
    setColapsed({ collapsed });
  };


  const onSelectVehicle = (selectData) => {
    setLoadingData(true);
    setSelectDataVehicle(selectData);
    setPhoto(selectData.photo);
    setPlate(selectData.plate);
    getVehiclesSpec(selectData.plate).then(specs => {
      if (specs.data) {
        setLoadingData(false);
        return setVehicle(specs.data);
      } else {
        alert("No existe ese vehiculo");
        setLoadingData(false);
      }
    }
    );
  };

  return (
    <Layout style={{ height: "80vh" }}>
      <Sider style={{ "background": "#2e4a72", "borderColor": "#2e4a72"}} collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <Menu theme="dark"
          style={{ "backgroundColor": "#2e4a72 !important", "borderColor": "#2e4a72" }}
          className="style-menu"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          mode="inline">
          {menuCategory.map(arrCatData => (
            <SubMenu style={{ "backgroundColor": "#2e4a72", "borderColor": "#2e4a72" }} key={arrCatData.id_cat} icon={<CarOutlined />} title={arrCatData.manufacturer}>
              {listVehicle.filter(dataFil => dataFil.manufacturer === arrCatData.manufacturer).map(dataPla => (
                <Menu.Item
                  style={{ "backgroundColor": "#103558", "borderColor": "#103558" }}
                  key={dataPla.plate}
                  onClick={() => onSelectVehicle(dataPla)}
                >
                  {dataPla.plate}
                </Menu.Item>
              ))}
            </SubMenu>
          ))
          }
        </Menu>
      </Sider>

      <Content className="site-layout" style={{ margin: '15px' }}>
        <Row gutter={16} style={{ margin: '5px' }}>
          {loadingData ? <div style={{ height: "500px", position: "relative", minWidth: "100%" }}>
            <div style={{ position: "relative", top: "50%", left: "30%", color: "black" }}><Spin /> Obteniendo información </div>
          </div> : <React.Fragment>
            <Col span={12} xs={24} sm={24} md={24} lg={8}>
              <Card title={<Title style={{ "color": "#2f5a83" }} level={4}>{vehicle?.plate}</Title>} bordered={false}>
                <ImageVehicle photo={photo} setPhoto={setPhoto} plate={plate} setListVehicle={setListVehicle} listVehicle={listVehicle} selectDataVehicle={selectDataVehicle} />
              </Card>
              <br />
            </Col>
            <Col span={12} xs={24} sm={24} md={24} lg={16}>
              <Card title={<Title style={{ "color": "#2f5a83" }} level={4}>Especificaciones</Title>} bordered={false}>
                <Features vehicle={vehicle} />
              </Card>
            </Col>
          </React.Fragment>}
        </Row>
      </Content>

    </Layout>
  );

}