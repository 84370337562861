import React, { useEffect, useState } from 'react';
import { Tabs, Table, Input, InputNumber, Popconfirm, Form, Typography, Button, Modal } from 'antd';
import CostingRepo from './CostingRepo';
import CostVarious from './CostVarious';
import _ from 'lodash';
import './costing.css';

const { TabPane } = Tabs;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Ingrese ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default function DistanceDrivers(props) {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { getVehicles, updateVehicle } = CostingRepo();

  useEffect( ()=>{
    getVehicles().then(vehicles => {
      const dataVehicles = vehicles.data;
      setData(dataVehicles);
    })
  }, [props]);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      plate: '',
      con_gallon: '',
      factor_diesel: '',
      factor_leasing: '',
      km_month: null,
      leasing: null,
      vehicle: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {...item, ...row});
        newData.forEach( function (row) {
          row.factor_diesel = parseFloat(props.diesel/row.con_gallon).toFixed(3);
          row.factor_leasing = parseFloat(row.leasing/row.km_month).toFixed(3);
        });
        setEditingKey('');
        let upVehicle = newData[index];
        updateVehicle(upVehicle).then(res => {
          if(res.error === null){
            setData(newData);
          }
        });
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Vehicle',
      dataIndex: 'name',
      width: '20%',
      editable: true,
    },
    {
      title: 'Placa',
      dataIndex: 'plate',
      width: '15%',
      editable: false,
    },
    {
      title: 'Consumo por galón',
      dataIndex: 'con_gallon',
      width: '15%',
      editable: true,
    },
    {
      title: 'Factor de combustible',
      dataIndex: 'factor_diesel',
      width: '10%',
      editable: false,
    },
    {
      title: 'Km por mes',
      dataIndex: 'km_month',
      width: '15%',
      editable: true,
    },
    {
      title: 'Leasing',
      dataIndex: 'leasing',
      width: '15%',
      editable: true,
    },
    {
      title: 'Factor de Leasing',
      dataIndex: 'factor_leasing',
      width: '10%',
      editable: false,
    },
    {
      title: 'Operación',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Guardar
            </Typography.Link>
            <Popconfirm title="¿Seguro que cancelará?" onConfirm={cancel}>
              <a>Cancelar</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Editar
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form2.resetFields();
  };

  const onFinish = (newVehicle) => {
    updateVehicle(newVehicle).then(res => {
      setData(res.data);
      form2.resetFields();
      setIsModalVisible(false);
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return(
      <Tabs tabPosition={'top'}>
          <TabPane tab="Vehiculos" key="1">
            <Form form={form} component={false}>
              <Table
                  components={{
                  body: {
                      cell: EditableCell,
                  },
                  }}
                  bordered
                  dataSource={data}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={false}
              />
              <Button type="primary"
              onClick={showModal} 
              style={{
                margin:'20px'}}>
                Agregar vehiculo
              </Button>
              <Modal 
                title="Agregar nuevo vehículo" 
                visible={isModalVisible} 
                onCancel={handleCancel}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    Cancelar
                  </Button>
                ]}
                >
                <Form
                  form={form2}
                  name="basic"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Vehículo"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese el vehículo!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Placa"
                    name="plate"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese la placa!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Consumo por galón"
                    name="con_gallon"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese el consumo!',
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    label="Km por mes"
                    name="km_month"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese los km/mes!',
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    label="Leasing"
                    name="leasing"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese el leasing!',
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button type="primary" htmlType="submit">
                      Guardar
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </Form>
          </TabPane>
          <TabPane tab="Conceptos Varios" key="2">
              <CostVarious/>
          </TabPane>
      </Tabs>
      
  );
}