import React, { useEffect, useState } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, Button, Modal } from 'antd';
import CostingRepo from './CostingRepo';
import './costing.css';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};


export default function Constants(props) {
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {getConstants, updateConstant} = CostingRepo();

    useEffect( ()=>{
        getConstants ().then(constants => {
          const dataConstants = constants.data;
          dataConstants.forEach( function (row) {
            row.key = row.id;
          })
          setData(dataConstants);
          props.setDiesel(dataConstants[0].price);
        });
      }, []);
  
    const isEditing = (record) => record.key === editingKey;
  
    const edit = (record) => {
      form.setFieldsValue({
        precio: '',
        ...record,
      });
      setEditingKey(record.key);
    };
  
    const cancel = () => {
      setEditingKey('');
    };
  
    const save = async (key) => {
      try {
        const row = await form.validateFields();
        const newData = [...data];
        const index = newData.findIndex((item) => key === item.key);
  
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
          updateConstant(newData[index]).then(updatedConst => {
            if(updatedConst.error===null){
              setData(newData);
            }
            if(updatedConst.data.title === 'Combustible'){
              props.setDiesel(updatedConst.data.price);      
            }
          });
          setEditingKey('');
        } else {
          newData.push(row);
          setData(newData);
          setEditingKey('');
        }
      } catch (errInfo) {
        console.log('Validate Failed:', errInfo);
      }
    };
  
    const columns = [
      {
        title: 'Titúlo',
        dataIndex: 'title',
        width: '30%',
        editable: false,
      },
      {
        title: 'Descripción',
        dataIndex: 'description',
        width: '35%',
        editable: true,
      },
      {
        title: 'Precio',
        dataIndex: 'price',
        width: '20%',
        editable: true,
      },
      {
        title: 'Operación',
        dataIndex: 'operation',
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{
                  marginRight: 8,
                }}
              >
                Guardar
              </Typography.Link>
              <Popconfirm title="Seguro que quiere cancelar?" onConfirm={cancel}>
                <a>Cancelar</a>
              </Popconfirm>
            </span>
          ) : (
            <span>
            <Typography.Link disabled={editingKey !== ''} 
              onClick={() => edit(record)}
              style={{
                marginRight: 8,
              }}>
              Editar
            </Typography.Link>
            </span>
          );
        },
      },
    ];

    const mergedColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
  
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });

    const showModal = () => {
      setIsModalVisible(true);
    };
  
    const handleOk = () => {
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
      form2.resetFields();
    };

    const onFinish = (newConstant) => {
      updateConstant(newConstant).then(constants => {
        constants.data.forEach( function (row) {
          row.key = row.id;
        })

        setData(constants.data);
        form2.resetFields();
        setIsModalVisible(false);
      });
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    return(
        <Form form={form} component={false}>
            <Table
                components={{
                body: {
                    cell: EditableCell,
                },
                }}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={false}
            />
            <Button type="primary"
              onClick={showModal} 
              style={{
                margin:'20px'}}>
                Agregar constante
            </Button>
            <Modal 
              title="Agregar nueva constante" 
              visible={isModalVisible} 
              onCancel={handleCancel}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  Cancelar
                </Button>
              ]}
              >
              <Form
                form={form2}
                name="basic"
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Título"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese el título!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Descripción"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese la descripción!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Precio"
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese el precio!',
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Guardar
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
        </Form>
        
    );
}