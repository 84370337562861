import DashRepo from '../../shared/DashRepo';

export default function ClientsRepo(){

    const {dFetch} = DashRepo();

    const getClients = (update) => {
        return dFetch({ verb: 'GET', path: 'clients', data: {}, params: { update } }).then(response => {
            return response.data;
        });
    }

    const updateCredit = (payload) => {
        return dFetch({ verb: 'POST', path: 'clients/credit', data: payload }).then(response => {
            return response.data;
        });
    }

    const updateDirection = (number) => {
        return dFetch({ verb: 'POST', path: 'clients/external', data: { number } }).then(response => {
            return response.data;
        });
    }

    return { getClients, updateCredit, updateDirection };
}