import { SettingOutlined } from "@ant-design/icons";
import {
  Collapse,
  Tag,
  Typography,
  Tooltip,
  Form,
  Input,
  Button,
  Cascader,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import BudgetRepo from "../helpers/budgetingRepo";

const { Panel } = Collapse;

const optionsCascader = [
  {
    value: 1,
    label: "Gross Revenue",
  },
  {
    value: 2,
    label: "Costo de Venta",
    children: [
      {
        value: 1,
        label: "OPERADORES",
      },
      {
        value: 2,
        label: "CO-OPERADORES",
      },
      {
        value: 3,
        label: "CONTROLADORES",
      },
      {
        value: 4,
        label: "SIPERVISION",
      },
      {
        value: 5,
        label: "SEGUROS",
      },
      {
        value: 6,
        label: "COSTO DE UNIDADES",
      },
      {
        value: 7,
        label: "LEASING",
      },
      {
        value: 8,
        label: "OTRAS RELACIONADAS A OPERACION",
      },
    ],
  },
  {
    value: 3,
    label: "Gastos Administrativos y de Venta",
  },
  {
    value: 4,
    label: "Depreciación",
  },
  {
    value: 5,
    label: "Perdida Cambiaria",
  },
  {
    value: 6,
    label: "Gastos Financieros",
  },
  {
    value: 7,
    label: "Impuestos",
  },
];

const ContentCodeDrawer = () => {
  const { getSubAccounts, createSubAccount } = BudgetRepo();
  const [form] = Form.useForm();

  const [loadingButton, setLoadingButton] = useState(false)
  const [subaccounts, setSubaccounts] = useState([]);
  const [openPanels, setOpenPanels] = useState([]);
  const [openNestedPanels, setOpenNestedPanels] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getSubAccounts().then((data) => {
      setSubaccounts(data.data)
    });
  }

  const genExtra = () => (
    <SettingOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );

  const handleCreateCode = (values) => {
    setLoadingButton(true)
    let obj;
    if(values.seleccion[0] === 2){
      obj = {
        name: values.codigo,
        id_account: null,
        id_category: values.seleccion[1]
      }
    }else{
      obj = {
        name: values.codigo,
        id_account: values.seleccion[0],
        id_category: null
      }
    }
    createSubAccount(obj)
    .then( (data) => {
      setSubaccounts(data.data)
      message.success("Código de cuenta: "+values.codigo+" creado correctamente.", 5);
      form.resetFields();
      setLoadingButton(false);
      setTimeout(() => {
        if(values.seleccion[0] === 2){
          setOpenPanels([values.seleccion[0]]);
          setOpenNestedPanels([values.seleccion[1]])
        }else{
          setOpenPanels(values.seleccion)
        }
      }, 1000)
    })
    .catch(err => {
      message.error("Ocurrió un problema, revisar la consola.", 2)
      console.log(err);
    })
  };

  return (
    <>
      <Typography.Title level={5}>Ingresa Nuevo Código</Typography.Title>
      <Form form={form} onFinish={handleCreateCode} layout="horizontal">
        <Form.Item
          label="Código"
          name="codigo"
          rules={[
            {
              required: true,
              message: "Completar el campo.",
            },
          ]}
        >
          <Input placeholder="Ingrese Nombre del código" />
        </Form.Item>
        <Form.Item
          label="Selección"
          name="seleccion"
          rules={[
            {
              required: true,
              message: "Seleccione una opción",
            },
          ]}
        >
          <Cascader
            options={optionsCascader}
            placeholder="Seleccionar opción"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loadingButton} >
            Crear
          </Button>
        </Form.Item>
      </Form>
      <Typography.Title level={5}>
        Lista de Códigos por Categoría
      </Typography.Title>
      <Collapse activeKey={openPanels} onChange={setOpenPanels}>
        <Panel header="Gross Revenue" key="1" extra={genExtra()}>
          <div>
            {
            subaccounts.map((subaccount, i) => {
              if (subaccount.id_account === 1){
                return (
                  <Tooltip key={i} placement="top" title={subaccount.summaries.reduce( (ac, cv) => ac + cv.summary +"; ", "")}>
                    <Tag color={subaccount.summaries.length ? "green" : "red"}>{subaccount.name}</Tag>
                  </Tooltip>
                );
              }
            })}
          </div>
        </Panel>
        <Panel header="Costo de Venta" key="2" extra={genExtra()}>
          <Collapse activeKey={openNestedPanels} onChange={setOpenNestedPanels}>
            <Panel header="OPERADORES" key="1">
              <div>
              {subaccounts.map((subaccount, i) => {
                if (subaccount.id_category === 1){
                  return (
                    <Tooltip key={i} placement="top" title={subaccount.summaries.reduce( (ac, cv) => ac + cv.summary +"; ", "")}>
                      <Tag color={subaccount.summaries.length ? "green" : "red"}>{subaccount.name}</Tag>
                    </Tooltip>
                  );
                }
              })}
              </div>
            </Panel>
            <Panel header="CO-OPERADORES" key="2">
              <div>
              {subaccounts.map((subaccount, i) => {
                if (subaccount.id_category === 2){
                  return (
                    <Tooltip key={i} placement="top" title={subaccount.summaries.reduce( (ac, cv) => ac + cv.summary +"; ", "")}>
                      <Tag color={subaccount.summaries.length ? "green" : "red"}>{subaccount.name}</Tag>
                    </Tooltip>
                  );
                }
            })}
              </div>
            </Panel>
            <Panel header="CONTROLADORES" key="3">
              <div>
              {subaccounts.map((subaccount, i) => {
              if (subaccount.id_category === 3)
                return (
                  <Tooltip key={i} placement="top" title={subaccount.summaries.reduce( (ac, cv) => ac + cv.summary +"; ", "")}>
                    <Tag color={subaccount.summaries.length ? "green" : "red"}>{subaccount.name}</Tag>
                  </Tooltip>
                );
            })}
              </div>
            </Panel>
            <Panel header="SUPERVISION" key="4">
              <div>
              {subaccounts.map((subaccount, i) => {
              if (subaccount.id_category === 4)
                return (
                  <Tooltip key={i} placement="top" title={subaccount.summaries.reduce( (ac, cv) => ac + cv.summary +"; ", "")}>
                    <Tag color={subaccount.summaries.length ? "green" : "red"}>{subaccount.name}</Tag>
                  </Tooltip>
                );
            })}
              </div>
            </Panel>
            <Panel header="SEGUROS" key="5">
              <div>
              {subaccounts.map((subaccount, i) => {
              if (subaccount.id_category === 5)
                return (
                  <Tooltip key={i} placement="top" title={subaccount.summaries.reduce( (ac, cv) => ac + cv.summary +"; ", "")}>
                    <Tag color={subaccount.summaries.length ? "green" : "red"}>{subaccount.name}</Tag>
                  </Tooltip>
                );
            })}
              </div>
            </Panel>
            <Panel header="COSTO UNIDADES" key="6">
              <div>
              {subaccounts.map((subaccount, i) => {
              if (subaccount.id_category === 6)
                return (
                  <Tooltip key={i} placement="top" title={subaccount.summaries.reduce( (ac, cv) => ac + cv.summary +"; ", "")}>
                    <Tag color={subaccount.summaries.length ? "green" : "red"}>{subaccount.name}</Tag>
                  </Tooltip>
                );
            })}
              </div>
            </Panel>
            <Panel header="LEASING" key="7">
              <div>
              {subaccounts.map((subaccount, i) => {
              if (subaccount.id_category === 7)
                return (
                  <Tooltip key={i} placement="top" title={subaccount.summaries.reduce( (ac, cv) => ac + cv.summary +"; ", "")}>
                    <Tag color={subaccount.summaries.length ? "green" : "red"}>{subaccount.name}</Tag>
                  </Tooltip>
                );
            })}
              </div>
            </Panel>
            <Panel header="OTROS RELACIONADOS OPERACION" key="8">
              <div>
              {subaccounts.map((subaccount, i) => {
              if (subaccount.id_category === 8)
                return (
                  <Tooltip key={i} placement="top" title={subaccount.summaries.reduce( (ac, cv) => ac + cv.summary +"; ", "")}>
                    <Tag color={subaccount.summaries.length ? "green" : "red"}>{subaccount.name}</Tag>
                  </Tooltip>
                );
            })}
              </div>
            </Panel>
          </Collapse>
        </Panel>
        <Panel
          header="Gastos Administrativos y de Ventas"
          key="3"
          extra={genExtra()}
        >
          <div>
          {subaccounts.map((subaccount, i) => {
              if (subaccount.id_account === 3)
                return (
                  <Tooltip key={i} placement="top" title={subaccount.summaries.reduce( (ac, cv) => ac + cv.summary +"; ", "")}>
                      <Tag color={subaccount.summaries.length ? "green" : "red"}>{subaccount.name}</Tag>
                  </Tooltip>
                );
            })}
          </div>
        </Panel>
        <Panel header="Depreciación" key="4" extra={genExtra()}>
          <div>
          {subaccounts.map((subaccount, i) => {
              if (subaccount.id_account === 4)
                return (
                  <Tooltip key={i} placement="top" title={subaccount.summaries.reduce( (ac, cv) => ac + cv.summary +"; ", "")}>
                    <Tag color={subaccount.summaries.length ? "green" : "red"}>{subaccount.name}</Tag>
                  </Tooltip>
                );
            })}
          </div>
        </Panel>
        <Panel header="Perdida Cambiaria" key="5" extra={genExtra()}>
          <div>
          {subaccounts.map((subaccount, i) => {
              if (subaccount.id_account === 5)
                return (
                  <Tooltip key={i} placement="top" title={subaccount.summaries.reduce( (ac, cv) => ac + cv.summary +"; ", "")}>
                    <Tag color={subaccount.summaries.length ? "green" : "red"}>{subaccount.name}</Tag>
                  </Tooltip>
                );
            })}
          </div>
        </Panel>
        <Panel header="Gastos Financieros" key="6" extra={genExtra()}>
          <div>
          {subaccounts.map((subaccount, i) => {
              if (subaccount.id_account === 6)
                return (
                  <Tooltip key={i} placement="top" title={subaccount.summaries.reduce( (ac, cv) => ac + cv.summary +"; ", "")}>
                    <Tag color={subaccount.summaries.length ? "green" : "red"}>{subaccount.name}</Tag>
                  </Tooltip>
                );
            })}
          </div>
        </Panel>
        <Panel header="Impuestos" key="7" extra={genExtra()}>
          <div>
          {subaccounts.map((subaccount, i) => {
              if (subaccount.id_account === 7)
                return (
                  <Tooltip key={i} placement="top" title={subaccount.summaries.reduce( (ac, cv) => ac + cv.summary +"; ", "")}>
                    <Tag color={subaccount.summaries.length ? "green" : "red"}>{subaccount.name}</Tag>
                  </Tooltip>
                );
            })}
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default ContentCodeDrawer;
