import React, { useState, useEffect } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, Button, Modal } from 'antd';
import CostingRepo from './CostingRepo';
import './costing.css';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};


export default function TimeDrivers() {
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { getOperators, updateOperators } = CostingRepo();
  
    useEffect( ()=>{
        getOperators().then(operators => {
          const dataOperators = operators.data;
          dataOperators.forEach( function (row) {
            row.essalud = parseFloat(row.salary*0.09).toFixed(3);
            row.factor = parseFloat(((row.salary*1.09)/(row.hours_worked))/(row.n_personal*row.n_services)).toFixed(3);
            row.key = row.id;
          });
          setData(dataOperators);
        })
      }, []);

    const isEditing = (record) => record.key === editingKey;
  
    const edit = (record) => {
      form.setFieldsValue({
        name: '',
        age: '',
        address: '',
        ...record,
      });
      setEditingKey(record.key);
    };
  
    const cancel = () => {
      setEditingKey('');
    };
  
    const save = async (key) => {
      try {
        const row = await form.validateFields();
        const newData = [...data];
        const index = newData.findIndex((item) => key === item.key);
  
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
          updateOperators(newData[index]).then(res => {
            if(res.error===null){
              setData(newData);
            }
          })
          setEditingKey('');
        } else {
          newData.push(row);
          setData(newData);
          setEditingKey('');
        }
      } catch (errInfo) {
        console.log('Validate Failed:', errInfo);
      }
    };
  
    const columns = [
      {
        title: 'Descripción',
        dataIndex: 'description',
        width: '20%',
        editable: true,
      },
      {
        title: 'Horas trabajadas',
        dataIndex: 'hours_worked',
        width: '12%',
        editable: true,
      },
      {
        title: 'Salario',
        dataIndex: 'salary',
        width: '12%',
        editable: true,
      },
      {
        title: 'ESSALUD',
        dataIndex: 'essalud',
        width: '12%',
        editable: true,
      },
      {
        title: '# personal',
        dataIndex: 'n_personal',
        width: '12%',
        editable: true,
      },
      {
        title: '# de servicios',
        dataIndex: 'n_services',
        width: '12%',
        editable: true,
      },
      {
        title: 'Factor',
        dataIndex: 'factor',
        width: '12%',
        editable: true,
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              Edit
            </Typography.Link>
          );
        },
      },
    ];

    const mergedColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
  
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });

    const showModal = () => {
      setIsModalVisible(true);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
      form2.resetFields();
    };
  
    const onFinish = (newOperator) => {
      updateOperators(newOperator).then(res => {
        const dataOp = res.data;
        dataOp.forEach( function (row) {
          row.essalud = parseFloat(row.salary*0.09).toFixed(3);
          row.factor = parseFloat(((row.salary*1.09)/(row.hours_worked))/(row.n_personal*row.n_services)).toFixed(3);
          row.key = row.id;
        });
        setData(dataOp);
        form2.resetFields();
        setIsModalVisible(false);
      })
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    return(
        <Form form={form} component={false}>
            <Table
                components={{
                body: {
                    cell: EditableCell,
                },
                }}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={false}
            />
            <Button type="primary"
              onClick={showModal} 
              style={{
                margin:'20px'}}>
                Agregar vehiculo
              </Button>
              <Modal 
                title="Agregar nuevo operador" 
                visible={isModalVisible} 
                onCancel={handleCancel}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    Cancelar
                  </Button>
                ]}
                >
                <Form
                  form={form2}
                  name="basic"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Descipción"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese la descripción!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Horas trabajadas"
                    name="hours_worked"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese las horas trabajadas!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Salario"
                    name="salary"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese el salario!',
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    label="# personal"
                    name="n_personal"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese el # personal!',
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    label="#servicios"
                    name="n_services"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese el # servicios!',
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button type="primary" htmlType="submit">
                      Guardar
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
        </Form>
    );
}