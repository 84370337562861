import { Table } from 'antd';
import { useState, useEffect } from 'react';
import RenderEachCell from '../helpers/RenderEachCell';
import StatisticCell from '../helpers/StatisticCell';
import BudgetRepo from '../helpers/budgetingRepo';

const PLTable = (props) => {

    const { getPLByYearAndTypes } = BudgetRepo();
    const { year } = props;
    const today = new Date()
    today.setMonth(today.getMonth()-1)
    const currentMonth = today.toLocaleDateString('en-US', { month: 'long' }).toLocaleLowerCase()

    const [principalData, setPrincipalData] = useState([]);
    const [month, setMonth] = useState(currentMonth);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fechData()
    }, [year]);

    const fechData = () => {
        setLoading(true);
        getPLByYearAndTypes({ year, types: ["budget", "real"] })
            .then(({ data }) => {
                calculateSubstractions(data)
                setLoading(false)
            })
    }

    const showPerMonth = (totalYear, i) =>
        totalYear.map((mes, id) => {
            if (i > 1) {
                if (mes.month === month) return <StatisticCell key={id} total={mes.total} isPorcent={true} />
            } else {
                if (mes.month === month) return <StatisticCell key={id} total={mes.total} />
            }
        })

    const RenderCell = ({ content, type }) => (
        <table className='subtitlesTable'>
            <tbody>
                {content ? content.map((summary, i) => (
                    <tr key={i}>
                        <td>{
                            type === 'mtd'
                                ? showPerMonth(summary.mtd, i)
                                : type === 'budget'
                                    ? showPerMonth(summary.budget, i)
                                    : type === 'py'
                                        ? showPerMonth(summary.py, i)
                                        : type === 'mtd-bdg' && summary.mtdLessBudget
                                            ? showPerMonth(summary.mtdLessBudget, i)
                                            : type === 'mtd-py' && summary.mtdLessPY
                                                ? showPerMonth(summary.mtdLessPY, i)
                                                : type === 'cum-mtd' && summary.accumMtd
                                                    ? showPerMonth(summary.accumMtd, i)
                                                    : type === 'cum-budget' && summary.accumBudget
                                                        ? showPerMonth(summary.accumBudget, i)
                                                        : type === 'cum-py' && summary.accumPY
                                                            ? showPerMonth(summary.accumPY, i)
                                                            : type === 'cumMtd-budget' && summary.accumMtdLessBudget
                                                                ? showPerMonth(summary.accumMtdLessBudget, i)
                                                                : type === 'cumMtd-py' && summary.accumMtdLessPY
                                                                    ? showPerMonth(summary.accumMtdLessPY, i)
                                                                    : 0
                        }</td>
                    </tr>
                )) : 0}
            </tbody>
        </table>
    )

    const columns = [
        {
            title: 'Profits & Losses Declaration',
            children: [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    width: 205,
                    filters: [
                        {
                            text: 'Gross Revenue',
                            value: 'Gross',
                        },
                        {
                            text: 'Costo de Venta',
                            value: 'Costo',
                        },
                        {
                            text: 'Gastos Administrativos y de Venta',
                            value: 'Administrativos',
                        },
                        {
                            text: 'Depreciación',
                            value: 'Depreciación',
                        },
                        {
                            text: 'Gastos Financieros',
                            value: 'Financieros',
                        },
                        {
                            text: 'Perdida Cambiaria',
                            value: 'Perdida',
                        },
                        {
                            text: 'Impuestos',
                            value: 'Impuestos',
                        },
                    ],
                    filterMode: 'tree',
                    onFilter: (value, record) => record.name.includes(value),
                    onCell: (record, index) => {
                        if (record.key > 10 && record.key < 100) return { colSpan: 2 }
                    },
                },
                {
                    title: 'Summaries',
                    width: 205,
                    render: (_, record) => <RenderEachCell record={record} id={17} />,
                    onCell: (record, index) => {
                        if (record.key > 10 && record.key < 100) return { colSpan: 0 }
                    },
                },
            ],
        },
        {
            title: year,
            children: [
                {
                    title: 'MTD',
                    children: [
                        {
                            title: month,
                            filters: [
                                {
                                    text: 'January',
                                    value: 'january',
                                },
                                {
                                    text: 'February',
                                    value: 'february',
                                },
                                {
                                    text: 'March',
                                    value: 'march',
                                },
                                {
                                    text: 'April',
                                    value: 'april',
                                },
                                {
                                    text: 'May',
                                    value: 'may',
                                },
                                {
                                    text: 'June',
                                    value: 'june',
                                },
                                {
                                    text: 'July',
                                    value: 'july',
                                },
                                {
                                    text: 'August',
                                    value: 'august',
                                },
                                {
                                    text: 'September',
                                    value: 'september',
                                },
                                {
                                    text: 'October',
                                    value: 'october',
                                },
                                {
                                    text: 'November',
                                    value: 'november',
                                },
                                {
                                    text: 'December',
                                    value: 'december',
                                },
                            ],
                            defaultFilteredValue: [`${currentMonth}`],
                            onFilter: (value, record) => {
                                setTimeout(() => setMonth(value), 0);
                                return true
                            },
                            filterMultiple: false,
                            children: [
                                {
                                    title: 'MTD',
                                    dataIndex: 'content',
                                    width: 80,
                                    render: (content) => <RenderCell content={content} type='mtd' />,
                                },
                                {
                                    title: 'BUDGET',
                                    dataIndex: 'content',
                                    width: 80,
                                    render: (content) => <RenderCell content={content} type='budget' />,
                                },
                                {
                                    title: 'PY',
                                    dataIndex: 'content',
                                    width: 80,
                                    render: (content) => <RenderCell content={content} type='py' />,
                                },
                            ]
                        },
                    ]
                },
                {
                    title: '∆',
                    children: [
                        {
                            title: 'MTD',
                            children: [
                                {
                                    title: 'BUDGET',
                                    dataIndex: 'content',
                                    width: 80,
                                    render: (content) => <RenderCell content={content} type='mtd-bdg' />
                                },
                                {
                                    title: 'PY',
                                    dataIndex: 'content',
                                    width: 80,
                                    render: (content) => <RenderCell content={content} type='mtd-py' />
                                }
                            ]
                        },
                    ]
                },
                {
                    title: ' ',
                    dataIndex: 'null',
                    width: 15,
                },
                {
                    title: 'Accmulated',
                    children: [
                        {
                            title: `until ${month}`,
                            children: [
                                {
                                    title: 'MTD',
                                    dataIndex: 'content',
                                    width: 80,
                                    render: (content) => <RenderCell content={content} type='cum-mtd' />
                                },
                                {
                                    title: 'BUDGET',
                                    dataIndex: 'content',
                                    width: 80,
                                    render: (content) => <RenderCell content={content} type='cum-budget' />
                                },
                                {
                                    title: 'PY',
                                    dataIndex: 'content',
                                    width: 80,
                                    render: (content) => <RenderCell content={content} type='cum-py' />
                                },
                            ]
                        },
                    ]
                },
                {
                    title: '∆',
                    children: [
                        {
                            title: 'MTD',
                            children: [
                                {
                                    title: 'BUDGET',
                                    dataIndex: 'content',
                                    width: 80,
                                    render: (content) => <RenderCell content={content} type='cumMtd-budget' />
                                },
                                {
                                    title: 'PY',
                                    dataIndex: 'content',
                                    width: 80,
                                    render: (content) => <RenderCell content={content} type='cumMtd-py' />
                                }
                            ]
                        },
                    ]
                },
            ],
        },
    ];

    const calculateSubstractions = (currentData) => {
        const result = currentData.map((gfather, id) => (
            {
                ...gfather,
                children: isNaN(gfather.children)
                    ? gfather.children.map(father => (
                        {
                            ...father,
                            children: father.children
                                ? father.children.map(son => (
                                    {
                                        ...son,
                                        content: handleContent(son.content)
                                    }
                                )) : undefined,
                            content: handleContent(father.content)
                        }
                    )) : undefined,
                content: handleContent(gfather.content, id)
            }
        ));
        return setPrincipalData(result);
    };

    const acumulatedFirstsMTDLessBudget = [];
    const acumulatedFirstsMTDLessPY = [];
    const acumulatedFirstsAccumMTD = [];
    const acumulatedFirstsAccumBudget = [];
    const acumulatedFirstsAccumPY = [];
    const acumulatedFirstAccumMTDLessBudget = [];
    const acumulatedFirstAccumMTDLessPY = [];
    const handleContent = (content, idgfather) => {
        return (
            content.map((summary, idsummary) => {
                if ((idgfather || idgfather === 0) && idsummary === 0) {
                    acumulatedFirstsMTDLessBudget.push(handleMTDLessBudgetOrPY(summary, 1, idsummary))
                    acumulatedFirstsMTDLessPY.push(handleMTDLessBudgetOrPY(summary, 2, idsummary))
                    acumulatedFirstsAccumMTD.push(handleAccumulatedUntil(summary, 1, idsummary))
                    acumulatedFirstsAccumBudget.push(handleAccumulatedUntil(summary, 2, idsummary))
                    acumulatedFirstsAccumPY.push(handleAccumulatedUntil(summary, 3, idsummary))
                    acumulatedFirstAccumMTDLessBudget.push(handleAccumMTDLessBudgetOrPYCalculteBefore(summary, 4, idsummary))
                    acumulatedFirstAccumMTDLessPY.push(handleAccumMTDLessBudgetOrPYCalculteBefore(summary, 5, idsummary))
                }
                return (
                    {
                        ...summary,
                        mtdLessBudget: handleMTDLessBudgetOrPY(summary, 1, idsummary, idgfather, acumulatedFirstsMTDLessBudget),
                        mtdLessPY: handleMTDLessBudgetOrPY(summary, 2, idsummary, idgfather, acumulatedFirstsMTDLessPY),
                        accumMtd: handleAccumulatedUntil(summary, 1, idsummary, idgfather, acumulatedFirstsAccumMTD),
                        accumBudget: handleAccumulatedUntil(summary, 2, idsummary, idgfather, acumulatedFirstsAccumBudget),
                        accumPY: handleAccumulatedUntil(summary, 3, idsummary, idgfather, acumulatedFirstsAccumPY),
                        accumMtdLessBudget: handleAccumMTDLessBudgetOrPY(summary, 4, idsummary, idgfather, acumulatedFirstsAccumMTD, acumulatedFirstsAccumBudget, acumulatedFirstsAccumPY, acumulatedFirstAccumMTDLessBudget),
                        accumMtdLessPY: handleAccumMTDLessBudgetOrPY(summary, 5, idsummary, idgfather, acumulatedFirstsAccumMTD, acumulatedFirstsAccumBudget, acumulatedFirstsAccumPY, acumulatedFirstAccumMTDLessPY),
                    }
                )
            })
        )
    };

    const handleMTDLessBudgetOrPY = (summary, type, idsummary, idgfather, acumulated) => {
        return (
            summary.mtd.map((mes, i) => {
                return (
                    {
                        month: mes.month,
                        total: idsummary < 2
                            ? type === 1
                                ? mes.total - summary.budget[i].total
                                : mes.total - summary.py[i].total
                            : calculatePorcentFromAcumulated(idsummary, idgfather, i, acumulated)
                    }
                )
            })
        )
    };

    const handleAccumulatedUntil = (summary, type, idsummary, idgfather, acumulated) => {
        let total = 0;
        if (type === 1)
            return summary.mtd.map((mes, i) => {
                return (
                    {
                        month: mes.month,
                        total: idsummary < 2
                            ? total += mes.total
                            : calculatePorcentFromAcumulated(idsummary, idgfather, i, acumulated)
                    }
                )
            });
        else if (type === 2)
            return summary.budget.map((mes, i) => (
                {
                    month: mes.month,
                    total: idsummary < 2
                        ? total += mes.total
                        : calculatePorcentFromAcumulated(idsummary, idgfather, i, acumulated)
                }
            ));
        else if (type === 3)
            return summary.py.map((mes, i) => (
                {
                    month: mes.month,
                    total: idsummary < 2
                        ? total += mes.total
                        : calculatePorcentFromAcumulated(idsummary, idgfather, i, acumulated)
                }
            ));
    }

    const calculatePorcentFromAcumulated = (idsummary, idgfather, i, acumulated) => {
        let result = 0;
        if (acumulated) {
            if (idsummary === 2) {
                if (idgfather === 1) {
                    result = parseFloat(((1 - (acumulated[1][i].total / acumulated[0][i].total)) * 100).toFixed(1))
                } else {
                    result = parseFloat(((acumulated[idgfather][i].total / acumulated[0][i].total) * 100).toFixed(1))
                }
            } else if (idsummary === 3) {
                result = parseFloat((((acumulated[0][i].total - acumulated[1][i].total - acumulated[2][i].total) / acumulated[0][i].total) * 100).toFixed(1))
            }
        }
        return result;
    }

    const handleAccumMTDLessBudgetOrPY = (summary, type, idsummary, idgfather, accmtd1, accbudget, accpy, acumulated) => {
        const accumMTD = handleAccumulatedUntil(summary, 1, idsummary, idgfather, accmtd1);
        const accumBudget = handleAccumulatedUntil(summary, 2, idsummary, idgfather, accbudget);
        const accumPY = handleAccumulatedUntil(summary, 3, idsummary, idgfather, accpy);
        return accumMTD.map((mes, i) => (
            {
                month: mes.month,
                total: type === 4
                    ? idsummary < 2
                        ? mes.total - accumBudget[i].total
                        : calculatePorcentFromAcumulated(idsummary, idgfather, i, acumulated)
                    : idsummary < 2
                        ? mes.total - accumPY[i].total
                        : calculatePorcentFromAcumulated(idsummary, idgfather, i, acumulated)
            }
        ))
    }

    const handleAccumMTDLessBudgetOrPYCalculteBefore = (summary, type, idsummary) => {
        const accumMTD = handleAccumulatedUntil(summary, 1, idsummary);
        const accumBudget = handleAccumulatedUntil(summary, 2, idsummary);
        const accumPY = handleAccumulatedUntil(summary, 3, idsummary);
        return accumMTD.map((mes, i) => (
            {
                month: mes.month,
                total: type === 4
                    ? mes.total - accumBudget[i].total
                    : type === 5 ? mes.total - accumPY[i].total : 0
            }
        ))
    }

    const calculateUtilidadNeta = (allFirstSummaries) => {
        const result = [];
        for (let mes = 0; mes < 12; mes++) {
            let utilidadNeta = 0;
            let nombreMes = '';
            allFirstSummaries.map((summary, id) => {
                if (id === 0) {
                    utilidadNeta = summary[mes].total;
                    nombreMes = summary[mes].month;
                } else {
                    utilidadNeta -= summary[mes].total;
                }
            })
            result.push({ month: nombreMes, total: utilidadNeta })
        }
        return result;
    }

    return (
        <Table
            title={() => `Estado de Resultado (comparativo)`}
            className="budgeting"
            columns={columns}
            dataSource={principalData}
            loading={loading}
            bordered
            size="small"
            scroll={{
                x: 'calc(700px + 50%)',
                y: 500,
            }}
            summary={(totalData) => {
                const allFirstSummariesMTD = [];
                const allFirstSummariesBG = [];
                const allFirstSummariesPY = [];
                const allFirstSummariesMTDLessBG = [];
                const allFirstSummariesMTDLessPY = [];
                const allFirstSummariesaccMTD = [];
                const allFirstSummariesaccBG = [];
                const allFirstSummariesaccPY = [];
                const allFirstSummariesAccMTDLessBG = [];
                const allFirstSummariesAccMTDLessPY = [];
                totalData.map((account, i) => {
                    allFirstSummariesMTD.push(account.content[0].mtd);
                    allFirstSummariesBG.push(account.content[0].budget);
                    allFirstSummariesPY.push(account.content[0].py);
                    allFirstSummariesMTDLessBG.push(account.content[0].mtdLessBudget);
                    allFirstSummariesMTDLessPY.push(account.content[0].mtdLessPY);
                    allFirstSummariesaccMTD.push(account.content[0].accumMtd);
                    allFirstSummariesaccBG.push(account.content[0].accumBudget);
                    allFirstSummariesaccPY.push(account.content[0].accumPY);
                    allFirstSummariesAccMTDLessBG.push(account.content[0].accumMtdLessBudget);
                    allFirstSummariesAccMTDLessPY.push(account.content[0].accumMtdLessPY);
                })
                const UNMTD = calculateUtilidadNeta(allFirstSummariesMTD);
                const UNBG = calculateUtilidadNeta(allFirstSummariesBG);
                const UNPY = calculateUtilidadNeta(allFirstSummariesPY);
                const UNMTDLessBG = calculateUtilidadNeta(allFirstSummariesMTDLessBG);
                const UNMTDLessPY = calculateUtilidadNeta(allFirstSummariesMTDLessPY);
                const UNAccMTD = calculateUtilidadNeta(allFirstSummariesaccMTD);
                const UNAccBG = calculateUtilidadNeta(allFirstSummariesaccBG);
                const UNAccPY = calculateUtilidadNeta(allFirstSummariesaccPY);
                const UNAccMTDLessBG = calculateUtilidadNeta(allFirstSummariesAccMTDLessBG);
                const UNAccMTDLessPY = calculateUtilidadNeta(allFirstSummariesAccMTDLessPY);
                return (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={2} index={0} align='center'><b>UTILIDAD NETA</b></Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                                {
                                    UNMTD.map((mes, i) => {
                                        if (mes.month === month) {
                                            return <StatisticCell key={i} total={mes.total} isUN={true} />
                                        }
                                    })
                                }
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                                {
                                    UNBG.map((mes, i) => {
                                        if (mes.month === month) {
                                            return <StatisticCell key={i} total={mes.total} isUN={true} />
                                        }
                                    })
                                }
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4}>
                                {
                                    UNPY.map((mes, i) => {
                                        if (mes.month === month) {
                                            return <StatisticCell key={i} total={mes.total} isUN={true} />
                                        }
                                    })
                                }
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={5}>
                                {
                                    UNMTDLessBG.map((mes, i) => {
                                        if (mes.month === month) {
                                            return <StatisticCell key={i} total={mes.total} isUN={true} />
                                        }
                                    })
                                }
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={6}>
                                {
                                    UNMTDLessPY.map((mes, i) => {
                                        if (mes.month === month) {
                                            return <StatisticCell key={i} total={mes.total} isUN={true} />
                                        }
                                    })
                                }
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={7}>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={8}>
                                {
                                    UNAccMTD.map((mes, i) => {
                                        if (mes.month === month) {
                                            return <StatisticCell key={i} total={mes.total} isUN={true} />
                                        }
                                    })
                                }
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={9}>
                                {
                                    UNAccBG.map((mes, i) => {
                                        if (mes.month === month) {
                                            return <StatisticCell key={i} total={mes.total} isUN={true} />
                                        }
                                    })
                                }
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={10}>
                                {
                                    UNAccPY.map((mes, i) => {
                                        if (mes.month === month) {
                                            return <StatisticCell key={i} total={mes.total} isUN={true} />
                                        }
                                    })
                                }
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={11}>
                                {
                                    UNAccMTDLessBG.map((mes, i) => {
                                        if (mes.month === month) {
                                            return <StatisticCell key={i} total={mes.total} isUN={true} />
                                        }
                                    })
                                }
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={12}>
                                {
                                    UNAccMTDLessPY.map((mes, i) => {
                                        if (mes.month === month) {
                                            return <StatisticCell key={i} total={mes.total} isUN={true} />
                                        }
                                    })
                                }
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )
            }}
        />
    )
};

export default PLTable;