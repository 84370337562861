import { useState, useEffect } from 'react';
import { Table, Tag, Card, Tabs } from 'antd';
import AcceptedQuotes from './AcceptedQuotes'
import AllProformas from './AllProformas';
import RejectProforma from './RejectProforma';
import TabHistoryQuotes from './TabHistoryQuotes';

const { TabPane } = Tabs;

export default function QuoteHistory() {
    const [update, setUpdate] = useState(false)

    return (
        <Card title="Histórico de proformas/cotizaciones">
            <div className='tabsQuoteHistory'>
                <Tabs defaultActiveKey="1" >
                    <TabPane tab="Proformas" key="1">
                        <AllProformas setUpdate={setUpdate}/>
                    </TabPane>
                    <TabPane tab="Cotizaciones" key="2">
                        <AcceptedQuotes update={update}/>
                    </TabPane>
                    <TabPane tab="Historial de Cotizaciones" key="3">
                        <TabHistoryQuotes update={update}/>
                    </TabPane>
                    <TabPane tab="Proformas rechazadas" key="4">
                        <RejectProforma update={update}/>
                    </TabPane>
                </Tabs>
            </div>
        </Card>
    );
}