import DashRepo from "../../../shared/DashRepo";

const ValidateRepo = () => {
  const { dFetch } = DashRepo();

  const getCheckPointsPending = () => {
    return dFetch({
      verb: "GET",
      path: "V2/checkpoints/getCheckpointsPending",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      return response.data;
    });
  };

  const approveEventToEdit = async (newData) => {
    return dFetch({
      verb: "PUT",
      path: "V2/checkpoints/allowModifyCheckpointOpt",
      data: newData,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      return response.data;
    });
  };

  const sendCheckpointsToTMS = async (newData) => {
    return dFetch({
      verb: "POST",
      path: "V2/checkpoints/sendCheckpointsToTms",
      data: newData,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      return response.data;
    });
  };

  return {
    getCheckPointsPending,
    sendCheckpointsToTMS,
    approveEventToEdit
  };
};

export default ValidateRepo;
