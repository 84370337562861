import { Badge, Modal, Radio, Space } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import moment from 'moment';

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

const ModalRender = ({ title, value, onChange }) => {

    const [principalValue, setPrincipalValue] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [valueRadio, setValueRadio] = useState(0);
    const [selectedValue, setSelectedValue] = useState(null);
    const [colorBadge, setColorBadge] = useState('#faad14');


    useEffect(() => {
        setPrincipalValue(value);
        setSelectedValue(value[0]);
        if (value.length > 1) setValueRadio(value[0]);
        else onChange(moment(value, dateFormat));
    }, [])

    const showModal = () => {
        setColorBadge('#faad14')
        setIsModalOpen(true);
    };
    const handleOk = () => {
        onChange(moment(valueRadio, dateFormat))
        setSelectedValue(valueRadio)
        setColorBadge('#52c41a')
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setValueRadio(principalValue[0]);
        setIsModalOpen(false);
    };

    const onChangeRadio = (e) => {
        setValueRadio(e.target.value);
    };

    return (
        <>
            {
                principalValue.length > 1
                    ?
                    <Badge
                        offset={[10, -5]}
                        count={principalValue.length > 1 ? principalValue.length : 0}
                        onClick={showModal}
                        style={{
                            backgroundColor: colorBadge,
                        }}
                    >
                        <p>{selectedValue}</p>
                    </Badge>
                    :
                    <p>{principalValue[0]}</p>
            }
            <Modal
                title={title}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Radio.Group onChange={onChangeRadio} value={valueRadio}>
                    <Space direction="vertical">
                        {principalValue.length > 1 ? principalValue.map((time, i) => {
                            return (
                                <Radio key={i} value={time}>{time}</Radio>
                            )
                        }) : null}
                    </Space>
                </Radio.Group>
            </Modal>
        </>
    );
}

export default ModalRender;