import { Table, Form, Input, Badge, message } from "antd";
import moment from "moment";
import { useEffect, useState } from 'react';
import ActionButtons from "../Buttons/ActionButtons";
import ModalRender from "../Modal/ModalRender";

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps
}) => {
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            type: inputType,
                            required: true,
                            message: `Por favor, completar campo de ${title}!`,
                        },
                    ]}
                >
                    <ModalRender title={title} />
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const OPTTable = ({ opt, optguid, ort, ortguid, content, timeInterval, reloadButton, setReload }) => {

    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState('');

    useEffect(() => {
        fetchData()
    }, [timeInterval, reloadButton])

    const fetchData = () => {
        setData(content)
    }

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            ...record,
            pntArribo: record.pntArribo, //moment(record.pntArribo[0], dateFormat)
            ingAlmacen: record.ingAlmacen, //moment(record.ingAlmacen[0], dateFormat)
            aperPuertas: record.aperPuertas, //moment(record.aperPuertas[0], dateFormat)
            cierPuertas: record.cierPuertas, //moment(record.cierPuertas[0], dateFormat)
            salAlmacen: record.salAlmacen //moment(record.salAlmacen[0], dateFormat)
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                    pntArribo: [row['pntArribo'].format(dateFormat)],
                    ingAlmacen: [row['ingAlmacen'].format(dateFormat)],
                    aperPuertas: [row['aperPuertas'].format(dateFormat)],
                    cierPuertas: [row['cierPuertas'].format(dateFormat)],
                    salAlmacen: [row['salAlmacen'].format(dateFormat)]
                });
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const upload = (record) => {
        const result = {
            numOpt: opt,
            optguid,
            numeroORT: ort,
            ortguid,
            tramoGUID: record.distribucionGUID,
            evento: record,
            modified: "N",
            transportOrders: [
                {
                    numeroORT: ort,
                    ortguid,
                    distributionPaths: [
                        {
                            tramoGUID: record.distribucionGUID,
                            checkpoints: [
                                {
                                    fechaHora: moment(record.pntArribo[0], "DD/MM/YYYY hh:mm:ss").format('YYYY-MM-DDThh:mm:ss'),
                                    codigo: "ARDP"
                                },
                                {
                                    fechaHora: moment(record.ingAlmacen[0], "DD/MM/YYYY hh:mm:ss").format('YYYY-MM-DDThh:mm:ss'),
                                    codigo: "ETDP"
                                },
                                {
                                    fechaHora: moment(record.aperPuertas[0], "DD/MM/YYYY hh:mm:ss").format('YYYY-MM-DDThh:mm:ss'),
                                    codigo: "STDP"
                                },
                                {
                                    fechaHora: moment(record.cierPuertas[0], "DD/MM/YYYY hh:mm:ss").format('YYYY-MM-DDThh:mm:ss'),
                                    codigo: "ENDP"
                                },
                                {
                                    fechaHora: moment(record.salAlmacen[0], "DD/MM/YYYY hh:mm:ss").format('YYYY-MM-DDThh:mm:ss'),
                                    codigo: "DEDP"
                                }
                            ]
                        }
                    ]
                }
            ]
        }
        return result;
    }

    const requestEdit = (record) => {
        const result = {
            numOpt: opt,
            optguid,
            ortguid,
            numeroORT: ort,
            tramoGUID: record.distribucionGUID,
            evento: record
        }
        return result;
    }

    const columns = [
        {
            title: 'Evento',
            key: 'tipo',
            dataIndex: 'tipo',
            width: 140,
            render: (text) => <b>{text}</b>,
            selectTime: false,
        },
        {
            title: 'Almacén',
            key: 'nombre',
            dataIndex: 'nombre',
            width: 140,
            selectTime: false,
        },
        {
            title: 'Punto de arribo',
            key: 'pntArribo',
            dataIndex: 'pntArribo',
            selectTime: true,
            render: (times, record, index) => {
                return (
                    <Badge
                        offset={[10, -5]}
                        count={times.length > 1 ? times.length : 0}>
                        <p>{times[0]}</p>
                    </Badge>
                )
            }
        },
        {
            title: 'Ingreso almacén',
            key: 'ingAlmacen',
            dataIndex: 'ingAlmacen',
            selectTime: true,
            render: (times, record) => {
                return (
                    <Badge
                        offset={[10, -5]}
                        count={times.length > 1 ? times.length : 0}>
                        <p>{times[0]}</p>
                    </Badge>
                )
            }
        },
        {
            title: 'Apertura puertas',
            key: 'aperPuertas',
            dataIndex: 'aperPuertas',
            selectTime: true,
            render: (times, record) => {
                return (
                    <Badge
                        offset={[10, -5]}
                        count={times.length > 1 ? times.length : 0}>
                        <p>{times[0]}</p>
                    </Badge>
                )
            }
        },
        {
            title: 'Cierre puertas',
            key: 'cierPuertas',
            dataIndex: 'cierPuertas',
            selectTime: true,
            render: (times, record) => {
                return (
                    <Badge
                        offset={[10, -5]}
                        count={times.length > 1 ? times.length : 0}>
                        <p>{times[0]}</p>
                    </Badge>
                )
            }
        },
        {
            title: 'Salida almacén',
            key: 'salAlmacen',
            dataIndex: 'salAlmacen',
            selectTime: true,
            render: (times, record) => {
                return (
                    <Badge
                        offset={[10, -5]}
                        count={times.length > 1 ? times.length : 0}>
                        <p>{times[0]}</p>
                    </Badge>

                )
            }
        },
        {
            title: 'Acciones',
            key: 'action',
            width: 150,
            fixed: 'right',
            render: (_, record) => {
                const editable = isEditing(record);
                let isEditable = true;
                Object.keys(record).map((item) => {
                    if (item === "pntArribo" || item === "ingAlmacen" || item === "aperPuertas" || item === "cierPuertas" || item === "salAlmacen") {
                        if (record[item].length > 1 || record[item].length == 0) isEditable = false;
                    }
                })
                return <ActionButtons
                    isEditable={isEditable}
                    editable={editable}
                    edit={edit}
                    cancel={cancel}
                    save={save}
                    upload={upload}
                    requestEdit={requestEdit}
                    editingKey={editingKey}
                    record={record}
                    setReload={setReload}
                />
            },
        }
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.selectTime) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'OPT' ? 'text' : 'object',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                columns={mergedColumns}
                dataSource={data}
                pagination={false}
                rowClassName="editable-row"
                bordered
            />
        </Form>
    )
}

export default OPTTable;