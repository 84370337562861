import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
//import '../style/main.css';
import { Row, Col, Button,Form, Input} from 'antd';
import VehicleRepo from './VehicleRepo';
import { ToastOk } from '../../shared/Toast';
//import { Spin, Space } from 'antd';

export default function Features({vehicle}) {
    
    const [vehicleSpecs, setVehicleSpecs] = useState([]);
    const {updateVehiclesSpec} = VehicleRepo();

    useEffect(()=>{
        setVehicleSpecs(vehicle);
    },[vehicle]);

    const updateVehicle= (e) => {
        //API
        updateVehiclesSpec(vehicleSpecs);
        ToastOk("Actualizacion completa - "+vehicleSpecs.plate)
      };


    return(
            <Form>
                <Row gutter={{ xs: 24 }} justify="space-between">
                    <Col span={11} xs={24} sm={24} md={24} lg={11}>
                        <Form.Item label="Placa">
                            <Input 
                                placeholder="Placa"
                                value={vehicleSpecs.plate}
                                onChange={(e) => setVehicleSpecs({...vehicleSpecs, plate: e.target.value})} />
                        </Form.Item>
                        <Form.Item label="Categoria">
                            <Input 
                                placeholder="Categoria" 
                                value={vehicleSpecs.category}
                                onChange={(e) => setVehicleSpecs({...vehicleSpecs, category: e.target.value})}  />
                        </Form.Item>
                        <Form.Item label="Nivel Armadura">
                            <Input 
                                placeholder="Nivel Armadura"
                                value={vehicleSpecs.armor_cat}
                                onChange={(e) => setVehicleSpecs({...vehicleSpecs, armor_cat: e.target.value})} />
                        </Form.Item>
                        <Form.Item label="Profuncidad de carga">
                            <Input 
                                placeholder="Profuncidad de carga"
                                value={vehicleSpecs.load_depth}
                                onChange={(e) => setVehicleSpecs({...vehicleSpecs, load_depth: e.target.value})} />
                        </Form.Item>
                        <Form.Item label="Profundidad de carga">
                            <Input 
                                placeholder="Profundidad de carga" 
                                value={vehicleSpecs.load_height}
                                onChange={(e) => setVehicleSpecs({...vehicleSpecs, load_height: e.target.value})}/>
                        </Form.Item>
                        <Form.Item label="Ancho de carga">
                            <Input 
                                placeholder="Ancho de carga" 
                                value={vehicleSpecs.load_width}
                                onChange={(e) => setVehicleSpecs({...vehicleSpecs, load_width: e.target.value})}/>
                        </Form.Item>
                        
                    </Col>
                    <Col span={11} xs={24} sm={24} md={24} lg={11}>
                        <Form.Item label="Peso neto">
                            <Input 
                                placeholder="Peso neto" 
                                value={vehicleSpecs.net_weight}
                                onChange={(e) => setVehicleSpecs({...vehicleSpecs, net_weight: e.target.value})}/>
                        </Form.Item>
                        <Form.Item label="Peso de carga util">
                            <Input 
                                placeholder="Peso de carga util"
                                value={vehicleSpecs.useful_load_weight}
                                onChange={(e) => setVehicleSpecs({...vehicleSpecs, useful_load_weight: e.target.value})} />
                        </Form.Item>
                        <Form.Item label="Volumen">
                            <Input 
                                placeholder="Volumen" 
                                value={vehicleSpecs.volume}
                                onChange={(e) => setVehicleSpecs({...vehicleSpecs, volume: e.target.value})}/>
                        </Form.Item>
                        <Form.Item label="Tipo de combustible">
                            <Input 
                                placeholder="Tipo de combustible" 
                                value={vehicleSpecs.fuel_type}
                                onChange={(e) => setVehicleSpecs({...vehicleSpecs, fuel_type: e.target.value})}/>
                        </Form.Item>
                        <Form.Item label="Peso de combustible">
                            <Input 
                                placeholder="Peso de combustible" 
                                value={vehicleSpecs.gross_weight}
                                onChange={(e) => setVehicleSpecs({...vehicleSpecs, gross_weight: e.target.value})}/>
                        </Form.Item>
                        <Form.Item label="Año">
                            <Input 
                                placeholder="Año" 
                                value={vehicleSpecs.year}
                                onChange={(e) => setVehicleSpecs({...vehicleSpecs, year: e.target.value})}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item >
                    <Button 
                        style={{
                            borderRadius: 10,
                            borderColor:"#dc3545",
                            backgroundColor: "#dc3545",}}
                            onClick={updateVehicle}
                        type="primary">
                        Actualizar
                    </Button>
                </Form.Item>
            </Form>
    );
}