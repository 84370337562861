import { useState, useEffect} from 'react';
import { Table, Tag, Space, Typography  } from 'antd';
import CostingRepo from './CostingRepo';

export default function RejectProforma({ update }){

    const [dataProf, setDataProf] = useState();
    const {allRejectProformas} = CostingRepo();


    useEffect(()=>{
        allRejectProformas().then(data => {
            let proformas = data.data;
            for (let i = 0; i < proformas.length; i++) {
                proformas[i].key = proformas[i].id;
            }
            setDataProf(proformas);
        })
    },[update])

    const save = (key) => {
    }

    
    const columns = [
      {
        title: '#',
        dataIndex: 'key',
        key: 'key',
    },
        {
            title: 'Cliente',
            dataIndex: 'clientName',
            key: 'clientName',
            width: '30%',
        },
        {
            title: 'Ruc',
            dataIndex: 'identificator',
            key: 'identificator',
            width: '10%',
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
            width: '20%',
        },
        {
            title: 'Fecha',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '15%',
        },
        {
            title: 'Costo incial',
            dataIndex: 'totalCost',
            key: 'totalCost',
            width: '5%',
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => 
          {
              return(
                <span>
                <Typography.Link
                  onClick={() => save(record.key)}
                  style={{
                    marginRight: 8,
                  }}
                >
                  Modificar
                </Typography.Link>


              </span>
              )
              

          },
        }
    ]    
      

      const data = [
        {
            key: '1',
            client: 'MIASA INTEGRACION ADUANERA SAC',
            ruc: '201364583623',
            date: '12/04/2022',
            cost: 400,
            currentCost: 500,
            margen: '25%',
            totalPrice: 600,
            newMargen: '20%',
            tags: ['El margen disminuyó'],
        },
        {
            key: '2',
            client: 'TECNOFIL SAC',
            ruc: '20306487473',
            date: '11/04/2022',
            cost: 500,
            currentCost: 400,
            margen: '20%',
            totalPrice: 600,
            newMargen: '25%',
            tags: ['El margen aumentó'],
        },
        {
            key: '3',
            client: 'MIASA INTEGRACION ADUANERA SAC',
            ruc: '201364583623',
            date: '12/04/2022',
            cost: 400,
            currentCost: 400,
            margen: '25%',
            totalPrice: 600,
            newMargen: '25%',
            tags: ['El margen se mantuvo'],
        },
      ];

    return(
        <div className='tableQuoteHistory'>
            <Table columns={columns} dataSource={dataProf} />
        </div>
    );
}