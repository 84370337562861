import { Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
const { SubMenu } = Menu;

export default function NavButtons(props) {

    const [current, useCurrent] = useState('');
    const [permissions, setPermissions] = useState([]);
    const history = useHistory();

    useEffect(() => {
        const per = props.permi
        setPermissions(per);
    }, [permissions]);


    const onClickSubmenu = (route) => {
        if (route.substring(0, 1) === "/") {
            history.push(route);
        }
        else if (route.substring(0, 4) === "http") {
            window.location.href = route;
        } else {
            window.location.href = route;

        }

    };


    return (
        <Menu theme="dark" selectedKeys={[current]} mode={props.modo} style={{ "backgroundColor": "#ffffff" }}>
            {permissions.map(datPermi => (
                <SubMenu className='subMenu' key={datPermi.id} title={datPermi.description_span} onTitleClick={() => onClickSubmenu(datPermi.controller)}>
                    {datPermi.secondMenu.map(dataSecond => (
                        <Menu.Item className='menuItem'
                            style={{ "backgroundColor": "#232323", "borderColor": "#232323" }}
                            key={dataSecond.id}
                        >
                            <Link to={(dataSecond.controller.substring(0, 1) === "/" || dataSecond.controller.substring(0, 4) === "http") ? { pathname: dataSecond.controller } : { pathname: "https://mundo.etarmadillo.com/" + dataSecond.controller }}
                                target={(dataSecond.controller.substring(0, 1) === "/") ? "_self" : "_blank"} >{dataSecond.description_span}</Link>
                        </Menu.Item>
                    ))}
                </SubMenu>
            ))}
        </Menu>
    );
}
