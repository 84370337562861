import { Table } from "antd";
import { useState, useEffect } from "react";
import StatesTable from "./StatesTable";
import controlRepo from "../helpers/controlRepo";
import moment from 'moment';

const PrincipalStatesTable = ({ edit, reloadButton, disableButtons, loadingCancel, cancelRequest }) => {

    const { getCheckPointsApproveAndPending } = controlRepo();
    
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])

    useEffect(() => {
        fetchData()
    }, [reloadButton]);

    const fetchData = () => {
        setLoading(true);
        getCheckPointsApproveAndPending()
        .then(({data}) => {
            setData(data);
            setLoading(false)
        })
    }

    const columns = [
        {
            title: 'OPT',
            dataIndex: 'opt',
            render: (text) => <b>{text}</b>,
            sorter: (a, b) => a.opt - b.opt,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Conductor',
            dataIndex: 'conductor',
        },
        {
            title: 'Vehículo',
            dataIndex: 'placa',
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            render:(fecha) => moment(fecha, "DD/MM/YYYY HH:mm:ss").format('L'),
            sorter: (a, b) => moment(a.fecha, "DD/MM/YYYY HH:mm:ss").unix() - moment(b.fecha, "DD/MM/YYYY HH:mm:ss").unix(),
            sortDirections: ['descend', 'ascend']
        },
    ]

    return (
        <Table
            columns={columns}
            loading={loading}
            dataSource={data}
            pagination={false}
            rowClassName="editable-row"
            bordered
            expandable={{
                defaultExpandAllRows: true,
                expandedRowRender: (record) =>
                    <StatesTable
                        opt={record.opt}
                        optguid={record.optguid}
                        ort={record.numeroORT}
                        ortguid={record.ortguid}
                        content={record.eventos}
                        edit={edit}
                        reloadButton={reloadButton}
                        disableButtons={disableButtons}
                        loadingCancel={loadingCancel}
                        cancelRequest={cancelRequest}
                    />,
            }}
            scroll={{
                y: 500
            }}
        />
    );
}

export default PrincipalStatesTable;