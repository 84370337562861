import React, { useMemo } from 'react';
import 'antd/dist/antd.css';
import './costing.css';
import { Table, Typography } from 'antd';


export default function TableQuote(props) {

  const { handleNewPriceChange, dataQuote } = props;

  const changePrice = (record) => {
    handleNewPriceChange(record);
  }

  const expandedRowRender = (expandedRecord) => {
    const { routesPerCar } = expandedRecord
    const columns = [
      { title: 'Inicio - ruta', dataIndex: 'routeHome', key: 'routeHome' },
      { title: 'Fin - ruta', dataIndex: 'routeEnd', key: 'routeEnd' },
      { title: 'Vehículo', dataIndex: 'vehicle', key: 'vehicle' },
      { title: 'Km', dataIndex: 'distance', key: 'distance' },
      { title: 'Horas', dataIndex: 'time', key: 'time' },
      { title: 'Resg', dataIndex: 'guard', key: 'guard' },
      { title: 'Costo del vehiculo', dataIndex: 'costVehicle', key: 'costVehicle' },
      { title: 'Costo Concep Varios', dataIndex: 'costVariousConcepts', key: 'costVariousConcepts' },
      { title: 'Costo de operadores', dataIndex: 'costOperators', key: 'costOperators' },
      { title: 'Costo Peajes', dataIndex: 'peajesCost', key: 'peajesCost' },
      { title: 'Viáticos', dataIndex: 'travelExpenses', key: 'travelExpenses' },
      { title: 'Costo Total', dataIndex: 'costTotalPerVehicle', key: 'costTotalPerVehicle' },
      {
        title: 'Margen', dataIndex: 'margin', key: 'margin',
        render: (_, record) => {
          return (
            <span>
              {record.margin ?? (((record.finalPrice - parseFloat(record.costTotalPerVehicle))/ record.finalPrice) * 100).toFixed(2) + "%"}
            </span>)
        }
      },
      { title: 'Precio final', dataIndex: 'finalPrice', key: 'finalPrice',
      render: (_, record) => {
        return (
          <span>
            {record.finalPrice}
          </span>)
      } },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => {
          return (
            <span>
              <Typography.Link
                onClick={() => changePrice(record)}
                style={{
                  marginRight: 8,
                }}
              >
                Modificar precio
              </Typography.Link>
            </span>
          )
        },
      }
    ];
    return <Table className="components-table-demo-nested"
      columns={columns} dataSource={routesPerCar} pagination={false} />;
  };

  const columns = [
    { title: 'Cliente', dataIndex: 'clientName', key: 'clientName', width: '30%' },
    { title: 'RUC', dataIndex: 'identificator', key: 'identificator', width: '10%' },
    { title: 'Descipción', dataIndex: 'description', key: 'description', width: '70%' },
    // { title: 'Costo total', dataIndex: 'totalCost', key: 'totalCost', width: '20%' },
    // { title: 'Precio final', dataIndex: 'totalPrice', key: 'totalPrice', width: '20%' },
  ];

  return (
    <Table
      className="components-table-demo-nested"
      columns={columns}
      expandable={{ expandedRowRender }}
      dataSource={dataQuote}
      pagination={false}
    />
  );
}


