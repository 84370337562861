import { Button, Card } from "antd";
import { useState } from "react";
import { ReloadOutlined } from '@ant-design/icons';
import PrincipalValidateTable from "./Table/PrincipalValidateTable";
import SearchInput from "./SearchInput/Search";
import OPTForm from "./Forms/OPTForm";

const ValidateCheckpoints = () => {

    const [reloadButtonValidateTable, setRealoadButtonValidateTable] = useState(false);
    const [disableEditOPTButtom, setDisableEditOPTButtom] = useState(true);
    const [disableFieldsOPTForm, setDisableFieldsOPTForm] = useState(true);

    const setReload = () => {
        setRealoadButtonValidateTable(true);
        setTimeout(() => {
            setRealoadButtonValidateTable(false)
        }, 1000);
    }

    return (
        <>
            <Card title="Validación de CheckPoints" >
                <SearchInput
                    setDisableEditOPTButtom={setDisableEditOPTButtom} />
                <Card
                    type="inner"
                    title="Editar OPT"
                    style={{
                        marginTop: 20
                    }}
                    extra={
                        <Button
                            type="primary"
                            disabled={disableEditOPTButtom}
                            onClick={() => {
                                setDisableFieldsOPTForm(false)
                            }}
                        >
                            Editar
                        </Button>
                    }
                >
                    <OPTForm
                        setDisableEditOPTButtom={setDisableEditOPTButtom}
                        setDisableFieldsOPTForm={setDisableFieldsOPTForm}
                        disableFieldsOPTForm={disableFieldsOPTForm}
                        disableEditOPTButtom={disableEditOPTButtom}
                    />
                </Card>
                <div>
                    <Card
                        type="inner"
                        title="Validación de OPTs para Control"
                        extra={
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<ReloadOutlined />}
                                onClick={setReload}
                                loading={reloadButtonValidateTable}
                            />
                        }
                        style={{
                            marginTop: 20
                        }}
                    >
                        <PrincipalValidateTable
                            reloadButtonValidateTable={reloadButtonValidateTable}
                            setReload={setReload}
                        />
                    </Card>
                </div>
            </Card>
        </>
    );
}

export default ValidateCheckpoints;