import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Upload, Col, Image, Button, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import truck from '../../assets/truck.png';
import VehicleRepo from './VehicleRepo';
import _ from 'lodash';

export default function ImageVehicle({photo, setPhoto, plate, setListVehicle, listVehicle, selectDataVehicle}) {
    
    const [plateVehicle, setPlateVehicle] = useState("");
    const {savePhoto} = VehicleRepo();
    const [currentPhoto, setCurrentPhoto ] = useState();
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(()=>{
        setPlateVehicle(plate);
        if(!photo){
          setCurrentPhoto(truck);
        }else{
          setCurrentPhoto(photo);
        }
    },[photo]);

    const handleUpload = async (event) => {
        event.preventDefault()
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append("photo", file);
          });
        
        setUploading(true);

        try {
          await savePhoto(formData,plateVehicle).then(response => {
            setPhoto(response.data.photo);

            for (let i = 0; i < listVehicle.length; i++) {
              if(listVehicle[i].plate === plate){
                listVehicle[i].photo = response.data.photo;
              };
            }
            setListVehicle(listVehicle);
          });
          setFileList([]);
          setUploading(false);
          setIsModalVisible(false);
        } catch(error) {
          setFileList([]);
          setUploading(false);
        };
        
    }

      const props = {
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            setFileList([...fileList, file]);
            setIsModalVisible(true);
        },
        fileList,
        action: "#"
      };

      const handleCancel = () => {
        setFileList([]);
        setIsModalVisible(false);
      };

    return(
        <React.Fragment>
                <Col>
                  <Image 
                        src={currentPhoto}
                        //srcSet={currentPhoto}
                        placeholder={" "}
                    /> 
                </Col>
                <Col >
                    <Upload  {...props}>
                        <Button disabled={fileList.length === 1 } icon={<UploadOutlined />}>Nueva Imagen</Button>
                    </Upload>
                    <Button
                        type="primary"
                        disabled={fileList.length === 0 }
                        loading={uploading}
                        style={{ marginTop: 16, "display": "none" }}
                        >
                        {uploading ? 'Guardando' : 'Guardando'}
                    </Button>
                    <Modal title="Guardar" visible={isModalVisible} onOk={handleUpload} onCancel={handleCancel}>
                        <p>Desea guardar la foto?</p>
                    </Modal>
                </Col>
                <br />
        </React.Fragment>
    );
}