import { Descriptions, Card, Tag, Modal } from "antd";
import React from "react";

const ModalDetail = ({ isModalOpen, setIsModalOpen, optDetails }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Detalles de la OPT"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Descriptions size="small" title={`OPT: ${optDetails.opt}`} column={1}>
          <Descriptions.Item label="Conductor">
            {optDetails.conductor}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions column={2}>
          <Descriptions.Item label="Placa">
            {optDetails.placa}
          </Descriptions.Item>
          <Descriptions.Item label="Fecha">
            {optDetails.fecha}
          </Descriptions.Item>
        </Descriptions>
        <br />
        {optDetails.eventos
          ? optDetails.eventos.map((evento, key) => (
              <Card
                key={key}
                title={evento.tipo}
                extra={[
                  <Tag key={1} color="blue">
                    SIN_ENVIAR
                  </Tag>,
                ]}
              >
                <Descriptions column={2}>
                  <Descriptions.Item label="Almacén">
                    {evento.nombre || "sin datos"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Distrito">
                    {evento.distrito || "sin datos"}
                  </Descriptions.Item>
                  <Descriptions.Item label="P.Arrb">
                    {evento.pntArribo.map((tiempo) => tiempo + ", ") ||
                      "sin datos"}
                  </Descriptions.Item>
                  <Descriptions.Item label="I.Almc">
                    {evento.ingAlmacen.map((tiempo) => tiempo + ", ") ||
                      "sin datos"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Aprt.P">
                    {evento.aperPuertas.map((tiempo) => tiempo + ", ") ||
                      "sin datos"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Cier.P">
                    {evento.cierPuertas.map((tiempo) => tiempo + ", ") ||
                      "sin datos"}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions column={1} layout="vertical">
                  <Descriptions.Item label="Salida Almacen">
                    {evento.salAlmacen.map((tiempo, i) => <p key={i}>{`${tiempo},`}</p>) ||
                      "sin datos"}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            ))
          : null}
      </Modal>
    </>
  );
};
export default ModalDetail;
