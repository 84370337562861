import DashRepo from '../../shared/DashRepo';
import { useCookies } from 'react-cookie';

export default function CostingRepo() {

    const [cookies, setCookie] = useCookies(['token']);
    const { dFetch } = DashRepo();

    const getConstants = () => {
        return dFetch({
            verb: 'GET', path: 'costing/constants', headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const updateConstant = (newData) => {
        return dFetch({
            verb: 'POST', path: 'costing/constant', data: newData, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const getVehicles = () => {
        return dFetch({
            verb: 'GET', path: 'costing/vehicles', headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const updateVehicle = (newData) => {
        return dFetch({
            verb: 'POST', path: 'costing/vehicle', data: newData, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const getConceptVarious = () => {
        return dFetch({
            verb: 'GET', path: 'costing/concepts', headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const updateConceptVarious = (newData) => {
        return dFetch({
            verb: 'POST', path: 'costing/concept', data: newData, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const getOperators = () => {
        return dFetch({
            verb: 'GET', path: 'costing/operators', headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const updateOperators = (newData) => {
        return dFetch({
            verb: 'POST', path: 'costing/operator', data: newData, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const getClients = () => {
        return dFetch({
            verb: 'GET', path: 'clients', headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const getClientRoutes = (identificator) => {
        return dFetch({
            verb: 'POST', path: 'costing/clientRoutes', data: identificator, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const newQuote = (data) => {
        return dFetch({
            verb: 'POST', path: 'costing/newQuote', data: data, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const saveProf = (data) => {
        return dFetch({
            verb: 'POST', path: 'costing/saveProforma', data: data, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const allProf = () => {
        return dFetch({
            verb: 'GET', path: 'costing/allProformas', headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const allRejectProformas = () => {
        return dFetch({
            verb: 'GET', path: 'costing/allRejectProformas', headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const allHistoryQuotes = () => {
        return dFetch({
            verb: 'GET', path: 'costing/allHistoryQuotes', headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const acceptProf = (data) => {
        return dFetch({
            verb: 'POST', path: 'costing/acceptQuote', data: data, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const rejectProforma = (data) => {
        return dFetch({
            verb: 'POST', path: 'costing/rejectProforma', data: data, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const allQuotes = () => {
        return dFetch({
            verb: 'GET', path: 'costing/alltQuotes', headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const newVersionQuote = (data) => {
        return dFetch({
            verb: 'POST', path: 'costing/newVersionQuote', data: data, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const getGards = () => {
        return dFetch({ path: 'costing/gards' }).then(response => response.data)
    }
    const getTolls = () => {
        return dFetch({ path: 'costing/tolls' }).then(response => response.data)
    }

    return {
        getConceptVarious,
        updateConceptVarious,
        getVehicles,
        updateVehicle,
        getOperators,
        updateOperators,
        getConstants,
        updateConstant,
        getClients,
        getClientRoutes,
        newQuote,
        saveProf,
        allProf,
        acceptProf,
        allQuotes,
        rejectProforma,
        allRejectProformas,
        newVersionQuote,
        getGards,getTolls,allHistoryQuotes
    };
}