import  { useState, useEffect} from 'react';
import { useCookies } from 'react-cookie';
import { Layout, Button } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';
import logo from '../../assets/logo_appbar.png';
import './navbar.css';
import MobileNavigation from './MobileNavigation';
import Navigation from './Navigation';
import NavButtons from './NavButtons';
import { ToastOk } from '../../shared/Toast';


const { Header, Content } = Layout;

export default function Navbar(props){

    const [cookies, setCookie, removeCookie] = useCookies(['token', 'name']);
    const [permi, setPermi] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        const per = props.token.permissions;
        setPermi(per)
    },[props.token]);
    
    const LogOut = () => {
        props.setToken(undefined);
        removeCookie('token');
        removeCookie('name');
        removeCookie('permissions');
        ToastOk("Hasta pronto")
    };

    return(
        <Layout className="layout">
            <Header className="header1">
                <Button className='buttonLogOut' title="Cerrar sesión" onClick={LogOut} type="primary" shape="circle" icon={<PoweroffOutlined />} />
            </Header>
            <Header className="header2">
                <div className='headerContent'>
                    <div className="logo">
                        <a href='https://mundo.etarmadillo.com'>
                            <img id="logo" src={logo} alt="logo"></img>
                        </a>
                    </div>
                    <Navigation navlinks={<NavButtons modo={"horizontal"} permi={permi}/>} />
                    <MobileNavigation navlinks={<NavButtons modo={"inline"} permi={permi} />} open={open} setOpen={setOpen} />
               </div>
            </Header>
            <Content className='contentNav' >
                {props.routes}
            </Content>
        </Layout>
    );
    
    
}