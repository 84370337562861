import DashRepo from "../../../shared/DashRepo";

const BudgetRepo = () => {
    const { dFetch } = DashRepo();

    const getBudgetByYear = (yearAndType) => {
        return dFetch({
            verb: 'POST', path: 'budgeting/accounts', data: yearAndType, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const getPLByYearAndTypes = (yearAndTypes) => {
        return dFetch({
            verb: 'POST', path: 'budgeting/accounts/pl', data: yearAndTypes, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const getYears = () => {
        return dFetch({
            verb: 'GET', path: 'budgeting/years', headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const getSubAccounts = () => {
        return dFetch({
            verb: 'GET', path: 'budgeting/subaccounts', headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const createSubAccount = (data) => {
        return dFetch({
            verb: 'POST', path: 'budgeting/subaccount', data: data, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    const sendDataExcel = (data) => {
        return dFetch({
            verb: 'POST', path: 'budgeting/subaccounts/total', data: data, headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        });
    }

    return {
        getBudgetByYear,
        getPLByYearAndTypes,
        getYears,
        getSubAccounts,
        createSubAccount,
        sendDataExcel
    }
}
export default BudgetRepo;