import { Table, Tag, Button, Tooltip, Popconfirm } from "antd";
import { useEffect, useState } from 'react';
import { CloseOutlined, RightOutlined, SyncOutlined } from '@ant-design/icons';
import './index.css';

const StatesTable = ({ opt, optguid, ort, ortguid, content, edit, reloadButton, disableButtons, loadingCancel, cancelRequest }) => {

    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData()
    }, [reloadButton])

    const fetchData = () => {
        setData(content)
    }

    const columns = [
        {
            title: 'Evento',
            dataIndex: 'tipo',
            render: (text) => <b>{text}</b>,
        },
        {
            title: 'Almacén',
            dataIndex: 'nombre'
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            filters: [
                {
                    text: 'Aprobado',
                    value: 'APROBADO',
                },
                {
                    text: 'Espera',
                    value: 'PENDIENTE',
                },
            ],
            onFilter: (value, record) => record.estado.indexOf(value) === 0,
            sorter: (a, b) => a.estado.length - b.estado.length,
            ellipsis: true,
            render: (estado) => {
                let color = estado === 'APROBADO' ? 'green' : 'gold';
                if (estado === 'RECHAZADO') {
                    color = '#D90E0E';
                }
                return (
                    <Tag color={color} icon={estado === 'PENDIENTE' ? <SyncOutlined spin /> : null}>
                        {estado.toUpperCase()}
                    </Tag>
                )
            },
        },
        {
            title: "acciones",
            width: 130,
            fixed: 'right',
            render: (_, record) => {
                if (record.estado === "PENDIENTE") {
                    return (
                        <Popconfirm title="¿Desea cancelar?" onConfirm={() => cancelRequest(record, opt, optguid, ort, ortguid)}>
                            <Tooltip placement="rightTop" title={<span>Cancelar solicitud</span>}>
                                <Button
                                    type="primary"
                                    shape="round"
                                    danger
                                    loading={loadingCancel}
                                    disabled={disableButtons}
                                    icon={<CloseOutlined />}
                                >
                                    Cancelar
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    )
                } else {
                    return (
                        <Tooltip placement="rightTop" title={<span>Editar en formulario</span>}>
                            <Button
                                type="primary"
                                shape="round"
                                style={disableButtons ? null : { background: "#FAAD14", borderColor: "#FAAD14" }}
                                icon={<RightOutlined />}
                                disabled={disableButtons}
                                onClick={() => edit(record, opt, optguid, ort, ortguid)}
                            >
                                Editar
                            </Button>
                        </Tooltip>
                    )
                }
            }
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            rowClassName="editable-row"
            bordered
            scroll={{
                y: 500
            }}
        />
    )
}

export default StatesTable;