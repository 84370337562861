import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Modal, Table } from 'antd';

export default function QuoteDetails(props) {

  const columns = [
    {
      title: 'Inicio',
      dataIndex: 'routeHome',
      key: 'routeHome',
    },
    {
      title: 'Fin',
      dataIndex: 'routeEnd',
      key: 'routeEnd',
    },
    {
      title: 'Vehiculo',
      dataIndex: 'vehicle',
      key: 'vehicle',
    },
    {
      title: 'Km',
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: 'Horas',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Resguardo',
      dataIndex: 'guard',
      key: 'guard',
    },
    {
      title: 'Costo Operadores',
      dataIndex: 'costOperators',
      key: 'costOperators',
    },
    {
      title: 'Costo Var. Conceptos',
      dataIndex: 'costVariousConcepts',
      key: 'costVariousConcepts',
    },
    {
      title: 'Costo peajes',
      dataIndex: 'peajesCost',
      key: 'peajesCost',
    },
    {
      title: 'Costo vehicle',
      dataIndex: 'costVehicle',
      key: 'costVehicle',
    },
    {
      title: 'Viáticos',
      dataIndex: 'travelExpenses',
      key: 'travelExpenses',
    },
    {
      title: 'Costo total vehiculo',
      dataIndex: 'costTotalPerVehicle',
      key: 'costTotalPerVehicle',
    },
    {
      title: 'Precio Final',
      dataIndex: 'finalPrice',
      key: 'finalPrice',
    },
  ];

  return (
    <Modal
      title={props.recordDetailsQuote.id + " - " + props.recordDetailsQuote.clientName + " - Detalles"}
      centered
      visible={props.modalDetails}
      onOk={() => props.setModalDetails(false)}
      onCancel={() => props.setModalDetails(false)}
      width={1150}
      pagination={false}
    >
      <Table
        style={{ width: '100%', overflow: 'scroll' }}
        columns={columns}
        dataSource={props.recordDetailsQuote.costing_routes_data_final_quotes}
        pagination={false}
      />
    </Modal>
  )
}