import { DatePicker } from "antd";
import { useState } from "react";
const dateFormat = 'DD/MM/YYYY';

const SelectDateForm = () => {
    const [seletecDay, setSelectedDay] = useState('');

    return(
        <>
            <DatePicker onChange={setSelectedDay} format={dateFormat} value={seletecDay} size='large' />
        </>
    )
}

export default SelectDateForm;