import { Form, Input, Button, Modal } from 'antd';
import { ToastErr, ToastOk } from '../../shared/Toast';
import PermissionsRepo from './PermissionsRepo';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  /* eslint-enable no-template-curly-in-string */
  
  
export default function FormNewUser(props) {

    const { newUser } = PermissionsRepo();
    const [form] = Form.useForm();

    const onFinish = (values) => {
        newUser(values.user).then(res => {
            if(res.data === "User Upcreated"){
                props.setIsModalVisible(false);
                form.resetFields();
                ToastOk("Nuevo usuario registrado");
            }else{
                ToastErr("Error al registrar el usuario");
            }
        })
      };

    const handleCancel = () => {
        props.setIsModalVisible(false);
        form.resetFields();
    };

    return(
        <Modal 
            title="Registrar Nuevo Usuario" 
            visible={props.isModalVisible} 
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                  Cancelar
                </Button>
              ]}
        >
            <Form {...layout} form={form} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
                <Form.Item name={['user', 'name']} label="Nombre" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={['user', 'last_name']} label="Apellidos" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={['user', 'username']} label="Username" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={['user', 'email']} label="Email" rules={[{ type: 'email', required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={['user', 'password']} label="Password" rules={[{required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                    <Button type="primary" htmlType="submit">
                    Registrar
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}