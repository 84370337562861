import DashRepo from '../../shared/DashRepo';
import { useCookies } from 'react-cookie';

export default function SyncLidermanRepo() {
    
    const [cookies, setCookie] = useCookies(['token']);
    const { dFetch } = DashRepo();

    const getVehiclesLid = () => {
        return  dFetch({ verb: 'GET', path: 'liderman/getStatus', headers : {
            'Content-Type': 'application/json'}
        }).then(response => {
            return  response.data;
        });
    }

    const sendVehiclesLid = (plates) => {
        return  dFetch({ verb: 'POST', path: 'liderman/setStatus', data: plates, headers : {
            'Content-Type': 'application/json'}
        }).then(response => {
            return  response.data;
        });
    }
    


    return { getVehiclesLid, sendVehiclesLid};
}