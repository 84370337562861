import { Table } from "antd";
import { useState, useEffect } from "react";

const columns = [
  {
    title: "Evento",
    dataIndex: "evento",
    width: 140,
    fixed: "left",
    render: (text) => <b>{text}</b>,
  },
  {
    title: "Cliente",
    dataIndex: "cliente",
    width: 140,
  },
  {
    title: "Punto de arribo",
    dataIndex: "pntArribo",
    render: (times, record, index) => {
      return <p>{times[0]}</p>;
    },
  },
  {
    title: "Ingreso almacén",
    dataIndex: "ingAlmacen",
    render: (times, record, index) => {
        return <p>{times[0]}</p>;
      },
  },
  {
    title: "Apertura puertas",
    dataIndex: "aperPuertas",
    render: (times, record, index) => {
        return <p>{times[0]}</p>;
      },
  },
  {
    title: "Cierre puertas",
    dataIndex: "cierPuertas",
    render: (times, record, index) => {
        return <p>{times[0]}</p>;
      },
  },
  {
    title: "Salida almacén",
    dataIndex: "salAlmacen",
    render: (times, record, index) => {
        return <p>{times[0]}</p>;
      },
  },
  {
    title: "Fecha de Modificación",
    dataIndex: "fechModificacion",
    width: 150,
    fixed: "right",
  },
];

const VerificationTable = ({ opt, content, reloadButton }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [reloadButton]);

  const fetchData = () => {
    setLoading(true);
    setTimeout(() => {
      setData(content);
      setLoading(false);
    }, 1000);
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      loading={loading}
      pagination={false}
      size={'small'}
    />
  );
};

export default VerificationTable;
