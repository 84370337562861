import { Col, Row, Input } from 'antd';
import { useState } from 'react';

const { Search } = Input;

const SearchInput = ({setDisableEditOPTButtom}) => {

    const [loadingSearchButtom, setLoadingSearchButtom] = useState(false)

    const onSearch = (value) => {
        if(value === '') return
        setLoadingSearchButtom(true)
        setTimeout(() => {
          console.log(value)
          setLoadingSearchButtom(false)
          setDisableEditOPTButtom(false)
        }, 1000);
      }

    return (
        <Row>
            <Col span={12} offset={6}>
                <Search
                    placeholder="Buscar OPT"
                    allowClear
                    enterButton="Buscar"
                    size="large"
                    loading={loadingSearchButtom}
                    onSearch={onSearch}
                />
            </Col>
        </Row>
    );
}

export default SearchInput;