import { Table } from "antd";
import { useEffect, useState } from 'react';
import ValidateRepo from "../helpers/ValidateRepo";
import ValidateTable from "./ValidateTable";

const originData = [];
for (let i = 0; i < 3; ++i) {
    originData.push({
        key: i.toString(),
        opt: '123213123',
        conductor: 'Driver ' + i,
        vehiculo: '10.3.4.5654',
        fechayhora: '2014-12-24 23:12:00',
        tipo: 'Jack',
        hora: 500,
        content: [{
            key: i.toString(),
            evento: 'Recojo',
            cliente: 'Idemia SAC',
            pntArribo: ['12/12/2022 15:09:19', '12/12/2022 14:09:19'],
            ingAlmacen: ['12/12/2022 20:09:19'],
            aperPuertas: ['12/12/2022 20:09:19'],
            cierPuertas: ['12/12/2022 20:09:19'],
            salAlmacen: ['12/12/2022 20:09:19'],
            estado: 'espera',
        },
        {
            key: (i + 1).toString(),
            evento: 'Entrega',
            cliente: 'Idemia SAC',
            pntArribo: ['12/12/2022 20:09:19'],
            ingAlmacen: ['12/12/2022 20:09:19', '12/12/2022 18:09:19'],
            aperPuertas: ['12/12/2022 20:09:19'],
            cierPuertas: ['12/12/2022 20:09:19'],
            salAlmacen: ['12/12/2022 19:09:19', '12/12/2022 20:09:19', '12/12/2022 21:09:19'],
            estado: 'aprobado',
        }
        ]
    });
}

const PrincipalValidateTable = ({ reloadButtonValidateTable, setReload }) => {

    const { getCheckPointsPending } = ValidateRepo();

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData()
    }, [reloadButtonValidateTable])

    const fetchData = () => {
        setLoading(true)
        getCheckPointsPending()
          .then(({ data }) => {
            console.log(data)
            setData(data);
            setLoading(false);
          })
    }

    const columns = [
        {
            title: 'OPT',
            dataIndex: 'opt',
            width: 140,
            render: (text) => <b>{text}</b>,
        },
        {
            title: 'Conductor',
            dataIndex: 'conductor',
        },
        {
            title: 'Vehiculo',
            dataIndex: 'placa',
        },
        {
            title: 'Fecha y Hora',
            dataIndex: 'fecha',
        },
        {
            title: 'Inicio del Servicio',
            children: [
                {
                    title: 'Hora de Inicio',
                    dataIndex: 'fechaInicio',
                }
            ]
        },
    ];

    return (
        <Table
            expandable={{
                expandedRowRender: (record) =>
                    <ValidateTable
                        opt={record.opt}
                        optguid={record.optguid}
                        ort={record.numeroORT}
                        ortguid={record.ortguid}
                        fecha={record.fecha}
                        content={record.eventos}
                        reloadButtonValidateTable={reloadButtonValidateTable}
                        setReload={setReload}
                    />
            }}
            columns={columns}
            loading={loading}
            dataSource={data}
            size={'middle'}
            rowClassName="editable-row"
            bordered
            scroll={{
                x: 'calc(750px + 50%)',
                y: 500,
            }}
        />
    )
}

export default PrincipalValidateTable;