import { Typography } from 'antd';
import StatisticCell from './StatisticCell';
const { Text } = Typography;

const RenderEachCell = ({ record, id, q }) => {
    if (record.content) {
        return (
            <table className='subtitlesTable'>
                <tbody>
                    {
                        record.content.map((summary, i) => {
                            return (
                                <tr key={i}>
                                    <td>
                                        {
                                            id < 12
                                                ? i < 2
                                                    ? <StatisticCell total={summary.content[id].total} />
                                                    : <StatisticCell total={summary.content[id].total} isPorcent={true} />
                                                : id === 12
                                                    ? i < 2
                                                        ? <StatisticCell total={summary.totalYear} />
                                                        : <StatisticCell total={summary.totalYear} isPorcent={true} />
                                                    : id > 12 && id < 17
                                                        ? i < 2
                                                            ? <StatisticCell total={summary.quarters[q]} />
                                                            : <StatisticCell total={summary.quarters[q]} isPorcent={true} />
                                                        : <Text>{summary.summary}</Text>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        )
    }
}

export default RenderEachCell;