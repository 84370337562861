import { Card, Button, Space, Segmented, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import PrincipalVerificationTable from './Tables/PrincipalVerificationTable';
import SelectDateForm from './Forms/SelectDateForm';
const { Title } = Typography

const DashCheckpoints = () => {

    const [reloadButtonVerificationTable, setReloadButtonVerificationTable] = useState(false);
    // const [reloadVerificationTable, SetReloadVerificationTable] = useState(false);
    const [valueSegmented, setValueSegmented] = useState('Hoy');
    // const [seletecDay, setSelectedDay] = useState('');

    const setReload = () => {
        setReloadButtonVerificationTable(true)
        setTimeout(() => {
            setReloadButtonVerificationTable(false);
        }, 1000);
    }

    return (
        <Card
            title={<Title level={5}>Dashboard Checkpoints</Title>}
        >
            <Space size="large" style={{
                padding: 15
            }}>
                <Segmented options={['Hoy', 'Ayer', 'Otro']} value={valueSegmented} onChange={setValueSegmented} size='large' />
                {
                    valueSegmented === "Otro"
                        ? <SelectDateForm />
                        : null
                }
            </Space>
            <Card
                type='inner'
                title="Tabla de Viajes"
                extra={
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<ReloadOutlined />}
                        onClick={setReload}
                        loading={reloadButtonVerificationTable}
                    />
                }
            >
                <PrincipalVerificationTable reloadButton={reloadButtonVerificationTable} />
            </Card>
        </Card>
    );
}

export default DashCheckpoints;