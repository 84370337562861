import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Typography, Popconfirm, Modal } from 'antd';
import CostingRepo from './CostingRepo';
import { ToastErr, ToastOk } from '../../shared/Toast';
import PDF from './PDF';

export default function AllProformas(props) {

  const [dataProf, setDataProf] = useState();
  const { allProf, acceptProf, rejectProforma } = CostingRepo();
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    allProf().then(data => {
      let proformas = data.data;
      for (let i = 0; i < proformas.length; i++) {
        proformas[i].key = proformas[i].id;
      }
      setDataProf(proformas);
    })
  }, [])

  const details = (record) => {
    setVisible(true);
  }

  const reject = (record) => {
    rejectProforma(record).then(res => {
    })
    const data = [...dataProf];
    setDataProf(
      data.filter((item) => item.key !== record.key),
    )
  }

  const accept = (quote) => {
    acceptProf(quote).then(res => {
      if (res.message === "Cotización creada") {
        const data = [...dataProf];
        setDataProf(data.filter((item) => item.key !== quote.key));
        ToastOk("Proforma aceptada")
        props.setUpdate(u => !u)
      }
    }).catch(error => {
      console.error(error)
      ToastErr("Error aceptando proforma.")
    });

  }

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Cliente',
      dataIndex: 'clientName',
      key: 'clientName',
      width: '30%',
    },
    {
      title: 'Ruc',
      dataIndex: 'identificator',
      key: 'identificator',
      width: '10%',
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
      width: '20%',
    },
    {
      title: 'Fecha',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '15%',
      render: (text, record) => {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        const d = new Date(record.createdAt);
        const date = d.toLocaleDateString(undefined, options);
        return (
          <span>
            {date}
          </span>
        )
      },
    },
    {
      title: 'Costo incial',
      dataIndex: 'totalCost',
      key: 'totalCost',
      width: '5%',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <span>
            {/* <Typography.Link
                  onClick={() => details(record)}
                  style={{
                    marginRight: 8,
                  }}
                >
                  Detalles
                </Typography.Link> */}
            <Popconfirm
              placement="topRight"
              title={"¿Desea aceptar esta proforma？"}
              onConfirm={() => accept(record)}
              okText="Ok"
              cancelText="No"
            >
              <a href="#">Aceptar</a>
            </Popconfirm>
            <Typography.Link
              onClick={() => reject(record)}
              style={{
                marginLeft: 8,
                marginRight: 8,
              }}
            >
              Rechazar
            </Typography.Link>

          </span>
        )


      },
    }
  ]

  return (
    <div className='tableQuoteHistory'>
      <Table columns={columns} dataSource={dataProf} />
      <Modal
        title="Detalles de la proforma"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1000}
      >
        <PDF />
      </Modal>
    </div>
  );
}