import DashRepo from '../../shared/DashRepo';
import { useCookies } from 'react-cookie';

export default function VehicleRepo() {
    
    const [cookies, setCookie] = useCookies(['token']);
    const { dFetch } = DashRepo();
    const getVehicles = () => {
        return  dFetch({ verb: 'GET', path: 'vehicles', headers : {
            'Content-Type': 'application/json'}
        }).then(response => {
            console.log('Mis carros:', response.data);
            return  response.data;
        });
    }

    const getVehiclesSpec = (selectPlate) => {
        return  dFetch({ verb: 'GET', path: 'vehicles/specs' , headers : {
            'Content-Type': 'application/json',
            plate: selectPlate}  
        }).then(response => {
            console.log('Mis especs:', response.data);
            return  response.data;
        });
    }

    const updateVehiclesSpec = (newVehicleSpecs) => {
        return  dFetch({ verb: 'POST', path: 'vehicles/specs', data: newVehicleSpecs , headers : {
            'Content-Type': 'application/json'}  
        }).then(response => {
            console.log('Mis especs:', response);
            return  response;
        });
    }

    const savePhoto = (photoVehicle,plate) => {
        return  dFetch({ verb: 'POST', 
            path: 'vehicles/photo',
            data:photoVehicle , 
            headers : {
            'Content-Type': 'multipart/form-data' ,
            'plate': plate }  
        }).then(response => {
            console.log('la respuesta:', response);
            return  response;
        });
    }


    return { getVehicles, getVehiclesSpec, updateVehiclesSpec, savePhoto };
}