import { useState } from 'react';

export default function useToken() {

    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        if (userToken?.token !== undefined) {
            localStorage.setItem('token', JSON.stringify(userToken));
            //setToken(userToken?.token);
            setToken(userToken);
        } else {
            localStorage.removeItem('token');
            setToken(undefined);
        }
    };

    return {
        setToken: saveToken,
        token
    }
}