import DashRepo from '../../shared/DashRepo';

export default function BillingRepo() {
  const { dFetch } = DashRepo();

  const getOvs = async ({ startDate, endDate, update }) => {
    return dFetch({ verb: 'POST', path: 'billing/tms', data: { startDate, endDate, update } }).then(response => {
      return response.data;
    });
  }

  const updateOvsArm = async ({ numero_ov, observacion, producto }) => {
    return dFetch({ verb: 'POST', path: 'billing/tms/observations', data: { numero_ov, observacion, producto } }).then(response => {
      return response.data;
    });
  }

  const makeBill = async (numero) => {
    return dFetch({ verb: 'POST', path: 'billing/efact', data: { numero } }).then(response => {
      return response.data;
    });
  }
  const updateBill = async (ov_number) => {
    return dFetch({ verb: 'PATCH', path: 'billing/efact', data: { ov_number } }).then(response => {
      return response.data;
    });
  }
  const getBillingInfo = async () => {
    return dFetch({ vertb: 'GET', path: 'billing/info' }).then(response => { return response.data })
  }
  const setBillingInfo = async (newData) => {
    return dFetch({ verb: 'POST', path: 'billing/info', data: newData }).then(response => { return response.data })
  }

  const deleteFactInvoice = async (num) => {
    return dFetch({ verb: 'POST', path: 'billing/deleteBillInvoice', data: num }).then(response => { return response.data })
  }

  return { getOvs, updateOvsArm, makeBill, updateBill, getBillingInfo, setBillingInfo, deleteFactInvoice };

};
