import { FileExcelOutlined } from "@ant-design/icons";
import { Button, Modal, message, Upload, Table, Steps, Drawer, Select, DatePicker, Space } from "antd";
import BudgetRepo from "../helpers/budgetingRepo";
import moment from "moment";
import * as XLSX from "xlsx";
import { useState } from "react";
import ContentCodeDrawer from "./ContentCodeDrawer";
const { Column } = Table;
const { Dragger } = Upload;

const ImportData = () => {
  const { sendDataExcel } = BudgetRepo();

  const [fileList, setFileList] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [openCodeDrawer, setOpenCodeDrawer] = useState(false);
  const [openDataModal, setOpenDataModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [okButtonModal, setOkButtonModal] = useState(true);
  const [cancelButtonModal, setCancelButtonModal] = useState(false);
  const [loadingOkButtonModal, setLoadingOkButtonModal] = useState(false);
  const [disableSelectAndDP, setDisableSelectAndDP] = useState(true);
  const [typeSelect, setTypeSelect] = useState(null);
  const [valueDatePicker, setValueDatePicker] = useState(moment());

  const handlePreviewData = async () => {
    setUploading(true);
    setLoadingTable(true);
    // const headers = ['CODIGO', 'DESCRIPCION', `Jan-${year}`, `Feb-${year}`, `Mar-${year}`, `Apr-${year}`, `May-${year}`, `Jun-${year}`, `Jul-${year}`, `Aug-${year}`, `Sep-${year}`, `Oct-${year}`, `Nov-${year}`, `Dec-${year}`]
    const data = await fileList[0].arrayBuffer();
    const workbook = XLSX.readFile(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonDataBruta = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    const headers = convertHeaders(jsonDataBruta[2]);
    headers.splice(0, 1, "CODIGO");
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: headers }).slice(3);
    jsonData.map((item, i) => {
      item.key = i;
    });
    const finalArray = handleTransformData(jsonData);
    // You can use any AJAX library you like
    console.log(headers);
    if (fileList) {
      if (!finalArray.length) return message.error("No se cargaron los datos, revise el archivo.", 3);
      setDataTable(finalArray);
      message.success("carga de datos exitosa.");
      setCurrentStep(2);
      setDisableSelectAndDP(false);
    } else {
      message.error("falla en la carga del archivo.");
    }
    setUploading(false);
    setLoadingTable(false);
  };

  const handleSendData = () => {
    setCancelButtonModal(true);
    setLoadingOkButtonModal(true);
    const data = {
      type: typeSelect,
      year: valueDatePicker.format("YYYY"),
      dataExcel: dataTable
    }
    sendDataExcel(data)
      .then((result) => {
        message.success("Datos subidos correctamente! Por favor, actualice la página.", 5);
        setCancelButtonModal(false);
        setLoadingOkButtonModal(false);
        handleResetModal();
      })
      .catch(err => {
        setLoadingOkButtonModal(false);
        console.log(err);
        if (typeof err.error === 'string') message.error(err.error, 8);
        else message.error(err.message, 4);
      })
  }

  const handleResetModal = () => {
    setFileList([]);
    setDataTable([]);
    setTypeSelect(null);
    setCurrentStep(0);
    setDisableSelectAndDP(true);
    setOkButtonModal(true);
    setValueDatePicker(moment());
    setOpenDataModal(false);
  }

  const handleSelectType = (value) => {
    setTypeSelect(value);
    setCurrentStep(3);
    setOkButtonModal(false);

  };

  const handleSelectYear = (date) => {
    setValueDatePicker(date);
  }

  const convertHeaders = (headers) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return headers.map((title, i) => {
      if (i === 0) return 'CODIGO';
      if (typeof title === "number") {
        //fecha de inicio es (-) porque es antes de 1970
        // const fechaInicio = new Date('12/30/1899');
        const fechaInicio = new Date(Date.UTC(1899, 12, 1, 0, 0, 0));
        let miliseconds = fechaInicio.getTime() + (title * 1000 * 60 * 60 * 24);
        return months[new Date(miliseconds).getMonth()];
      } else {
        return title;
      }
    })
  }

  const handleTransformData = (array) => {
    const result = array.filter((item, i) => {
      if (i === 0) return true
      else if (item.CODIGO) return true
    });
    return result.map((item, i) => {
      return ({
        key: item.key,
        CODIGO: i === 0 ? "IN-001" : item.CODIGO,
        DESCRIPCION: i === 0 ? "GROSS REVENUE" : item.DESCRIPCION,
        january: isNaN(item[`Jan`]) ? "" : parseFloat(item[`Jan`].toFixed(2)) * (i === 0 ? 1 : -1),
        february: isNaN(item[`Feb`]) ? "" : parseFloat(item[`Feb`].toFixed(2)) * (i === 0 ? 1 : -1),
        march: isNaN(item[`Mar`]) ? "" : parseFloat(item[`Mar`].toFixed(2)) * (i === 0 ? 1 : -1),
        april: isNaN(item[`Apr`]) ? "" : parseFloat(item[`Apr`].toFixed(2)) * (i === 0 ? 1 : -1),
        may: isNaN(item[`May`]) ? "" : parseFloat(item[`May`].toFixed(2)) * (i === 0 ? 1 : -1),
        june: isNaN(item[`Jun`]) ? "" : parseFloat(item[`Jun`].toFixed(2)) * (i === 0 ? 1 : -1),
        july: isNaN(item[`Jul`]) ? "" : parseFloat(item[`Jul`].toFixed(2)) * (i === 0 ? 1 : -1),
        august: isNaN(item[`Aug`]) ? "" : parseFloat(item[`Aug`].toFixed(2)) * (i === 0 ? 1 : -1),
        september: isNaN(item[`Sep`]) ? "" : parseFloat(item[`Sep`].toFixed(2)) * (i === 0 ? 1 : -1),
        october: isNaN(item[`Oct`]) ? "" : parseFloat(item[`Oct`].toFixed(2)) * (i === 0 ? 1 : -1),
        november: isNaN(item[`Nov`]) ? "" : parseFloat(item[`Nov`].toFixed(2)) * (i === 0 ? 1 : -1),
        december: isNaN(item[`Dec`]) ? "" : parseFloat(item[`Dec`].toFixed(2)) * (i === 0 ? 1 : -1),
      })
    });
  };

  const props = {
    listType: "picture",
    onRemove: () => {
      setCurrentStep(0);
      setDataTable([]);
      setFileList([]);
      setDisableSelectAndDP(true);
      setTypeSelect(null);
      setOkButtonModal(true);
    },
    beforeUpload: (file) => {
      const isExcel =
        file.type === "text/csv" ||
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isExcel) {
        message.error(`${file.name} no es un documento excel.`);
        return false;
      }
      setCurrentStep(1);
      setFileList([file]);
      return false || Upload.LIST_IGNORE;
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    fileList,
  };

  return (
    <>
      <Button type="primary" onClick={() => setOpenCodeDrawer(true)} style={{ margin: "0 15px 0 0" }} >
        Agregar código
      </Button>
      <Button type="primary" onClick={() => setOpenDataModal(true)}>
        Cargar Información
      </Button>
      <Drawer
        title="Agregar Código"
        placement="right"
        size="large"
        onClose={() => {
          setOpenCodeDrawer(false)
        }}
        open={openCodeDrawer}
      // extra={
      //   <Space>
      //     <Button onClick={() => {setOpenCodeDrawer(false)}}>Cancelar</Button>
      //     <Button type="primary">
      //       Aceptar
      //     </Button>
      //   </Space>
      // }
      >
        <ContentCodeDrawer />
      </Drawer>
      <Modal
        title="Importar Datos de Excel"
        centered
        confirmLoading={loadingOkButtonModal}
        open={openDataModal}
        onOk={handleSendData}
        onCancel={handleResetModal}
        okButtonProps={{
          disabled: okButtonModal,
        }}
        cancelButtonProps={{
          disabled: cancelButtonModal,
        }}
        okText="Subir Datos"
        width={"100%"}
        style={{
          textAlign: "center",
        }}
      >
        <Steps
          size="small"
          current={currentStep}
          items={[
            {
              title: 'Subir Archivo',
            },
            {
              title: 'Cargar los datos a la tabla',
            },
            {
              title: 'Elegir tipo y año',
            },
            {
              title: 'Subir a la base de datos',
            },
          ]}
        />
        <br />
        <Dragger {...props} maxCount={1}>
          <p className="ant-upload-drag-icon">
            <FileExcelOutlined />
          </p>
          <p className="ant-upload-text">
            Pulsar para subir o Arrastrar el archivo aquí
          </p>
          <p className="ant-upload-hint">
            Solo se pueden subir archivos Excel (.csv, .xlsx) para poder
            ver su contenido. Máximo un archivo y este debe tener el formato establecido.
          </p>
        </Dragger>
        <Space>
          <Button
            type="primary"
            onClick={handlePreviewData}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{
              margin: "16px 0",
            }}
          >
            {uploading ? "Cargando" : "Visualizar datos"}
          </Button>
          <Select
            style={{
              width: 150,
            }}
            status="warning"
            placeholder="Seleccione Tipo"
            disabled={disableSelectAndDP}
            onChange={handleSelectType}
            value={typeSelect}
            options={[
              {
                value: 'real',
                label: 'Datos Reales',
              },
              {
                value: 'budget',
                label: 'Presupuesto',
              },
            ]}
          />
          <DatePicker
            onChange={handleSelectYear}
            picker="year"
            status="warning"
            placeholder="Escoger año"
            disabled={disableSelectAndDP}
            value={valueDatePicker}
          />
        </Space>
        <Table size="small" dataSource={dataTable} loading={loadingTable}>
          <Column title="Código" dataIndex="CODIGO" width="5%" />
          <Column title="Descripción" dataIndex="DESCRIPCION" width="15%" />
          <Column title="Enero" dataIndex="january" />
          <Column title="Febrero" dataIndex="february" />
          <Column title="Marzo" dataIndex="march" />
          <Column title="Abril" dataIndex="april" />
          <Column title="Mayo" dataIndex="may" />
          <Column title="Junio" dataIndex="june" />
          <Column title="Julio" dataIndex="july" />
          <Column title="Agosto" dataIndex="august" />
          <Column title="Septiembre" dataIndex="september" />
          <Column title="Octubre" dataIndex="october" />
          <Column title="Noviembre" dataIndex="november" />
          <Column title="Diciembre" dataIndex="december" />
        </Table>
      </Modal>
    </>
  );
};

export default ImportData;
