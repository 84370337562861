import React, { useState, useEffect } from 'react';
import { Card , Popconfirm, Transfer, Row, Col, List, Avatar, Skeleton, Divider, Button } from 'antd';
import { ToastOk, ToastErr} from '../../shared/Toast'
import InfiniteScroll from 'react-infinite-scroll-component';
import PermissionsRepo from './PermissionsRepo';
import _ from 'lodash';
import 'antd/dist/antd.css';
import './permissions.css';
import FormNewUser from './FormNewUser';

export default function Permissions(){

    const [users, setUsers] = useState([]);
    const [menus, setMenus] = useState();
    const [allKeys, setAllKeys] = useState([]);
    const [newKeysPermissions, setNewKeysPermissions] = useState([]);
    const [currentUsername, setCurrentUsername] = useState();
    const [fullName, setFullName] = useState("Nombre");
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [usernameDestroy, setUsernameDestroy] = useState(false);

    
    const { getUsers, deleteUser, getMenus, getUserPermissions, setNewUserPermissions } = PermissionsRepo();

    useEffect(() => {
        Promise.all([
            getUsers(),
            getMenus()
        ]).then(x => {
            setUsers(x[0]);
            let allMenus = x[1].data;
            allMenus?.forEach((menu) => {
                menu.key = (menu.id).toString();
            });

            setMenus(allMenus);
            let keys = [];
            allMenus?.map((menu) => {
                keys.push(menu.key);
            })
            setAllKeys(keys);
        });
    }, []);

    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setNewKeysPermissions(_.difference(allKeys,nextTargetKeys));
        setTargetKeys(nextTargetKeys);
    };

    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };


    const onSelectUser = (username, name, lastname) => {
        let user = name + " " +lastname;
        setFullName(user);
        setCurrentUsername(username)
        let dato = {"username":username}
        getUserPermissions(dato).then(x => {
            let dataTarget = _.differenceBy(menus,x.data, 'inner_id');
            dataTarget.forEach(element => element.key = (element.id).toString());
            let keys = [];
            dataTarget.map((data) => {
                keys.push(data.key);
            })
            setTargetKeys(keys);
        })
    }

    const saveNewPermissions = () => {
        let newPermission = {
            "username":currentUsername,
	        "permissions": newKeysPermissions
        }
        setNewUserPermissions(newPermission).then(res => {
            if (res.error == null){
                ToastOk("Cambios guardados exitosamente");
            }else{
                ToastErr("Error al guardar cambios");
            }
        });
    }

    const showModal = ()  => {
        setIsModalVisible(true);
      };
    
    //PopOver

    const setUserDestroy = (username) => {
        setUsernameDestroy(username)
        console.log("Clickkkkk"+username)
    }

    const confirm = () => {
        let username = {
            "username":usernameDestroy
        }
        deleteUser(username).then(res => {
            (res.data === "User deleted")? ToastOk("Usuario eliminado") : ToastErr("Error");
        }
        )
        console.log("borradoooo")
    }

    return(
        <div className='classTransfer'>
            <Row justify="space-around" align="top">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card 
                        title="Lista de Usuarios" 
                        bordered={false} 
                        style={{ maxWidth: 650 }}
                        extra={<Button type="primary" shape="round" size={'small'} onClick={showModal}>
                                    Nuevo Usuario
                                </Button>
                                }
                    >
                        <div
                            id="scrollableDiv"
                            style={{
                                maxWidth:600,
                                height: 430,
                                overflow: 'auto',
                                padding: '0 16px',
                                border: '1px solid rgba(140, 140, 140, 0.35)',
                            }}
                            >
                            <InfiniteScroll
                                dataLength={users.length}
                                hasMore={false}
                                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                                endMessage={<Divider plain>That's all, no more users 😊</Divider>}
                                scrollableTarget="scrollableDiv"
                            >
                                <List
                                    dataSource={users}
                                    renderItem={item => (
                                        <List.Item key={item.id}
                                        actions={[<Button type="primary" shape="round" onClick={() => onSelectUser(item.username, item.name, item.last_name)}>
                                                    Permisos
                                                </Button>, 
                                                <Popconfirm placement="right" title={"Desea eliminar este usuario?"} onConfirm={confirm} okText="Yes" cancelText="No">
                                                    <Button type="primary" shape="round" onClick={() => setUserDestroy(item.username)}>
                                                        Eliminar
                                                    </Button>
                                                 </Popconfirm>
                                                ]}
                                        >
                                        <List.Item.Meta
                                            avatar={<Avatar/>}
                                            title={item.name + " " + item.last_name}
                                            description={item.email}
                                        />
                                        </List.Item>
                                    )}
                                />
                            </InfiniteScroll>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    <Card 
                        title={"Permisos - " + fullName}
                        bordered={false}
                        extra={<Button type="primary" shape="round" size={'small'} onClick={saveNewPermissions}>
                                    Guardar Cambios
                                </Button>
                                }
                    >
                        <Transfer
                            dataSource={currentUsername? menus : []}
                            titles={['ACTIVOS', 'DESACTIVADOS']}
                            targetKeys={targetKeys}
                            selectedKeys={selectedKeys}
                            onChange={onChange}
                            onSelectChange={onSelectChange}
                            render={item => item.description_span}
                            pagination
                        />
                    </Card>
                </Col>
            </Row>
            <FormNewUser isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}/>     
        </div>
    )
}