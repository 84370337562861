import { useState, useEffect, useMemo, forwardRef, useRef } from 'react';
import BillingRepo from './BillingRepo';
import Styles from '../../styles/Styles';
import Select from "react-select";
import { useTable } from 'react-table';
import { ToastErr, ToastOk } from '../../shared/Toast';
import { Spin, Button, Radio, Input, Form, InputNumber, Row, Col, Tag, Divider, Modal, DatePicker, Empty, Popconfirm } from 'antd';
import 'moment/locale/es';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/es_ES';
import { SendOutlined } from '@ant-design/icons';
import './billing.css';
import { Typography, Space } from 'antd';

const { Text } = Typography;

const options = [
    { value: "1", id: "6163", label: "SERVICIO DE TRANSPORTE DE CARGA" },
    { value: "2", id: "6164", label: "SERVICIO DE CUADRILLA" },
    { value: "3", id: "6165", label: "SERVICIO DE RESGUARDO" },
    { value: "4", id: "6166", label: "SERVICIO DE TRANSPORTE DE UNIDAD SEMIBLINDADA" },
    { value: "5", id: "17723", label: "SEGURO DE CARGA" },
    { value: "6", id: "6166", label: "HORA EXTRA" },
    { value: "7", id: "6167", label: "FALSO FLETE" },
];


const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef()
        const resolvedRef = ref || defaultRef

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

function Table({ columns, data, updateMyData }) {

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
        updateMyData,
    })


    // Render the UI for your table
    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {(rows.length > 0) ? <div></div> :
                <Row justify="center" style={{ width: "100%" }}>
                    <Empty
                        style={{ margin: "60px" }}
                        description={
                            <span>
                                Sin información
                            </span>
                        }
                    >
                    </Empty>
                </Row>}

            <div style={{ height: "50px" }}></div>
        </>
    )
};

export default function Billing() {
    //const classes = useStyles();
    const { TextArea } = Input;

    let billingRepo = new BillingRepo();
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [startDate, setStartDate] = useState(moment());

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [popupfields, setPopupfields] = useState([]);

    const [isBilling, setIsBilling] = useState(false);

    const showModal = () => {
        setIsModalLoading(true);
        billingRepo.getBillingInfo().then((data) => {
            setPopupfields(setBillingInfoData(data));
            ToastOk(data.message)
            setIsModalLoading(false);
            setIsModalVisible(true);
        }).catch((err) => {
            console.error(err);
            ToastErr(err.message);
            setIsModalLoading(false);
            setPopupfields([])
        });
    };
    const setBillingInfoData = (data) => {
        let newData = []
        newData.push({ name: 'serie', value: data.data.find(reg => reg.name === 'serie')['text'] || '' });
        newData.push({ name: 'numero', value: data.data.find(reg => reg.name === 'numero')['number'] || 0 });
        newData.push({ name: 'correoReceptor', value: data.data.find(reg => reg.name === 'correoReceptor')['text'] || '' });
        return newData
    }
    const handleOk = () => {
        billingRepo.setBillingInfo(popupfields).then((data) => {
            setPopupfields(setBillingInfoData(data));
            ToastOk(data.message);
            setIsModalVisible(false);
        }).catch((err) => {
            console.error(err);
            ToastErr(err.message);
            setPopupfields([]);
            setIsModalVisible(false);
        });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };



    useEffect(() => {
        getData(false);
    }, [startDate]);

    const getData = (update) => {
        setLoadingData(true);
        // COMPROBACION FECHA
        const datBegin = startDate.utc().startOf("day").toJSON();
        datBegin.replace("T05", "T00");
        const datEnd = startDate.utc().endOf('day').toJSON();

        console.log(update, datBegin.slice(0, -1), datEnd.slice(0, -1))
        setData([]);
        billingRepo.getOvs({
            update,
            startDate: datBegin.slice(0, -1),
            endDate: datEnd.slice(0, -1)
        }).then((data) => {
            setData(data.data);
            ToastOk(data.message)
            setLoadingData(false);
        }).catch((err) => {
            console.error(err);
            ToastErr(err.message);
            setLoadingData(false);
            return []
        });
    }

    const updateMyData = (rowIndex, columnId, value, payload) => {
        // We also turn on the flag to not reset the page
        // setSkipPageReset(true)
        // console.log(rowIndex, columnId, value)
        return billingRepo.updateOvsArm(payload).then((data) => {
            ToastOk(`Detalles de Ov: ${payload.numero_ov} actualizada.`)
            setData(old =>
                old.map((row, index) => {
                    if (index === rowIndex) {
                        return {
                            ...old[rowIndex],
                            [columnId]: value,
                        }
                    }
                    return row
                })
            )
        })
    }
    const EditableCell = ({
        value: initialValue,
        row: { index },
        column: { id },
        cell: { row },
        updateMyData, // This is a custom function that we supplied to our table instance
    }) => {
        // We need to keep and update the state of the cell normally
        const [value, setValue] = useState(row.original.billing_ov_arm?.observacion)
        const [loading, setLoading] = useState(false);
        const [changed, setChanged] = useState(false);
        const onChange = v => {
            setChanged(true);
            setValue(v)
        }
        // We'll only update the external data when the input is blurred
        const onObvs = () => {
            if (changed) {
                setLoading(true);
                setValue(value?.trim());
                let payload = { numero_ov: row.original.numero, observacion: value?.trim(), producto: row.original.billing_ov_arm?.producto }
                updateMyData(index, id, value, payload).then(() => {
                    setLoading(false)
                }).catch((err) => {
                    console.log(err)
                    setValue(row.original.billing_ov_arm?.observacion ?? "")
                    ToastErr(err.message)
                    setLoading(false)
                })
            } else {
                ToastOk('Sin cambios')
            }
        }

        // If the initialValue is changed external, sync it up with our state
        useEffect(() => {
            setValue(row.original.billing_ov_arm?.observacion)
        }, [row.original.billing_ov_arm?.observacion])

        return <div>
            <Row>
                <Col flex="auto">
                    <TextArea bordered={true} style={{ color: '#2f5a83' }} autoSize={{ minRows: 1, maxRows: 3 }} value={value}
                        onChange={(e) => onChange(e.target.value)} onFocus={(e) => setChanged(false)}
                    />
                </Col>
                <Col>
                    <Button onClick={onObvs} icon={<SendOutlined />} style={{ "backgroundColor": "#3FC61D", "borderColor": "#3FC61D" }}
                    />
                    {loading ? <Spin size="small" /> : <div></div>}
                </Col>
            </Row>
        </div>
    }

    function MyButton(props) {
        const { status, ...other } = props;
        if (props.status == "SIN FACTURAR") {
            return <Button {...other} style={{ "backgroundColor": "#dc3545", "borderColor": "#E43748" }} />;
        } else {
            return <Button {...other} disabled={true} style={{ "backgroundColor": "#E5707C", "borderColor": "#E5707C", "display": "none" }} />;
        }
    }

    function MyChip(props) {
        const { color, ...other } = props;
        switch (props.color) {
            case "ACEPTADO":
                return <Tag color="#35ce8d" {...other} style={{ "cursor": isBilling ? "not-allowed" : "pointer" }}>{props.color}</Tag>;
            case "FIRMADO":
                return <Tag color="#4381c1" {...other} style={{ "cursor": isBilling ? "not-allowed" : "pointer" }}>{props.color}</Tag>;
            case "RECHAZADO":
                return <Tag color="#978F90" {...other} style={{ "cursor": isBilling ? "not-allowed" : "pointer" }}>{props.color}</Tag>;
            case "BAJA":
                return <Tag color="#8c271e" {...other} style={{ "cursor": isBilling ? "not-allowed" : "pointer" }}>{props.color}</Tag>;
            case "PENDIENTE":
                return <Tag color="#F09902" {...other} style={{ "cursor": isBilling ? "not-allowed" : "pointer" }}>{props.color}</Tag>;
            default:
                return '';
        }
    }


    const updateStatus = (index, id, value) => {
        setData(old =>
            old.map((row, mindex) => {
                if (mindex === index) {
                    return {
                        ...old[index],
                        [id]: value,
                    }
                }
                return row
            })
        )
    }
    const FacturarButtons = ({
        value: initialValue,
        row: { index },
        column: { id },
        cell: { row },
    }) => {
        const [value, setValue] = useState();
        const [loading, setLoading] = useState(false);
        const [loadingChip, setLoadingChip] = useState(false);

        const onClickChip = async () => {
            setIsBilling(true)
            setLoadingChip(true)
            billingRepo.updateBill(row.original.numero).then(response => {
                setValue(response.data.efact_status);
                console.log(response);
                updateStatus(index, 'efact_status', response.data.efact_status)
                ToastOk(`Estado de Factura actualizado.`)
                setLoadingChip(false);
                setIsBilling(false)

            }).catch(error => {
                ToastErr(`Error actualizando Factura: ${row.original.numero}.\n${error.error}`)
                console.log(error);
                setLoadingChip(false);
                setIsBilling(false)

            })
        }

        const onFacturar = async () => {
            setLoading(true);
            console.log(row.original.numero);
            billingRepo.makeBill(row.original.numero).then(response => {
                ToastOk(`Factura realizada con éxito: ${row.original.numero} actualizada.`);
                console.log(response);
                updateStatus(index, 'efact_status', response.data.efact_status)
                setLoading(false);

            }).catch(error => {
                ToastErr(`Error enviando Factura: ${row.original.numero}.\n${error.error}`)
                console.log(error);
                setLoading(false);

            })
        }
        return (
            <>
                <MyButton variant="contained" status={row.original.efact_status} onClick={onFacturar} disabled={loading}>
                    {loading ? <Spin size="small" /> : <div>Facturar</div>}
                </MyButton>
                {/* <Button variant="contained" className={classes.buttonAlt}>
                    Detalles
                </Button> */}
                <MyChip color={row.original.efact_status} label={loadingChip ? <Spin size="small" /> : row.original.efact_status} onClick={isBilling ? () => { ToastErr(`Facturando, espere.`) } : onClickChip}></MyChip>
            </>);
    }

    const EditableDrop = ({
        value: initialValue,
        row: { index },
        column: { id },
        cell: { row },
        updateMyData // This is a custom function that we supplied to our table instance
    }) => {
        // We need to keep and update the state of the cell normally
        const [value, setValue] = useState(
            row.original.billing_ov_arm?.producto
        );
        const [loading, setLoading] = useState(false);
        const [changed, setChanged] = useState(false);

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                borderBottom: '1px dotted pink',
                color: state.isSelected ? 'white' : 'black',
                padding: 10,
            }),
            control: () => ({
                // none of react-select's styles are passed to <Control />
                width: 200,
            }),
            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const fontSize = '10px';
                const transition = 'opacity 300ms';
                const color = '#0E0D0D';
                return { ...provided, opacity, transition, color, fontSize };
            }
        }

        // We'll only update the external data when the input is blurred
        const onBlur = () => {
            if (changed) {
                setLoading(true);
                let payload = { numero_ov: row.original.numero, observacion: row.original.billing_ov_arm?.observacion, producto: value }
                updateMyData(index, id, value, payload).then(() => {
                    setLoading(false)
                }).catch((err) => {
                    console.log(err)
                    setValue(row.original.billing_ov_arm?.producto ?? null);
                    ToastErr(err.message);
                    setLoading(false)
                })
            } else {
                ToastOk('Sin cambios');
            }
        };
        const focus = () => {
            // console.log('Focus')
            setChanged(false);
        }
        const change = (e) => {
            if (e !== value) {
                setValue(e);
                setChanged(true);
            }
            // console.log('Change ',e)
        }
        // If the initialValue is changed external, sync it up with our state
        useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);

        return (
            <div>
                <Select
                    styles={customStyles}
                    // initialValue={value}
                    options={options}
                    onBlur={onBlur}
                    value={value}
                    onChange={change}
                    onFocus={focus}
                />
                {loading ? <Spin size="small" /> : <div></div>}
            </div>
        );
    };

    const handleDeleteFact = (bill) => {
        const num = { numero: bill.original.numero };
        console.log(bill);
        console.log(startDate);
        console.log(data);
        billingRepo.deleteFactInvoice(num).then(res => {
            ToastOk(res.message);
            setData((data) => {
                return data.map(x => {
                    if (x.numero === bill.original.numero) {
                        x.efact_status = "SIN FACTURAR";
                    }
                    return x;
                })
            }
            )

            //getData(false);
        })

    }

    const columns = useMemo(
        () => [
            {
                Header: "Fecha",
                accessor: "fecha",
                // provide custom function to format props 
                Cell: props => <div style={{ "color": "#0E0D0D" }}> {new Date(props.value).toLocaleDateString()} </div>
            },
            {
                Header: "OV",
                accessor: "numero"
            },
            {
                Header: "Cliente",
                accessor: "clienteNombre",
            },
            // {
            //     Header: "Servs.",

            //     /*Cell: props =>
            //         <Popup modal trigger={<Button variant='text' ><CarOutlined /></Button>}>
            //             {close => <Content title='Servicios' close={close}>
            //                 {props.row.original.ordenVentaServicios}
            //             </Content>}
            //         </Popup>*/

            //     //   <div><Button variant='text' onClick={() => console.log(props.row.original.ordenVentaServicios)}>🚐</Button></div>
            // },
            // {
            //     Header: "Liqs.",
            //     /*Cell: props => <Popup modal trigger={<Button variant='text'><FileTextOutlined /></Button>}>
            //         {close => <Content title='Liquidaciones' close={close}>
            //             {props.row.original.liquidacionVentas}
            //         </Content>}
            //     </Popup>*/
            // },
            {
                Header: "Moneda",
                accessor: "moneda"
            },
            {
                Header: "Total",
                accessor: "total",
                Cell: props => <div style={{ "color": "#0E0D0D" }}>  {new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(props.value)}</div>
            },
            {
                Header: "Producto",
                accessor: "billing_ov_arm.producto",
                Cell: props => EditableDrop(props)
            },
            {
                Header: "Observaciones",
                accesor: "billing_ov_arm.observacion",
                Cell: props => EditableCell(props)
            },
            {
                Header: "Facturar",
                accesor: "efact_status",
                Cell: props => FacturarButtons(props)
            },
            {
                Header: "Acciones",
                accesor: "action",
                Cell: props => {
                    return (
                        (props.row.original.efact_status !== "SIN FACTURAR" )?
                        <span>
                            <Popconfirm title="Desea eliminar factura?" onConfirm={() => handleDeleteFact(props.row)}>
                                <a style={{ fontSize: 14 }}>Eliminar</a>
                            </Popconfirm>
                        </span>
                        :
                        ""
                    )
                }
            },

            // {
            //     Header: "Estatus Factura",
            //     Cell: props =>
            //         <Popup modal trigger={<Button variant='text' ><Description /></Button>}>
            //             {close => <Content title='Servicios' close={close}>
            //                 {props.row.original.ordenVentaServicios}
            //             </Content>}
            //         </Popup>
            // },

        ],
        []
    )


    const onFinishPopup = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailedPopup = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    function onDateChange(date, dateString) {
        setStartDate(date);
    }
    return (
        <Styles>
            <Row justify="space-around">
                <Col>
                    <Row>
                        <DatePicker onChange={onDateChange} locale={locale} value={startDate} allowClear={false} />
                    </Row>
                </Col>
                <Col>
                    <Text strong>Órdenes de Venta del {startDate.format("DD/MM/yyyy")}</Text></Col>
                <Col>
                    <Radio.Group value="large">
                        <Radio.Button onClick={() => getData(false)}
                            disabled={loadingData} value="Actualizar Armadillo">
                            Actualizar Armadillo</Radio.Button>
                        <Radio.Button onClick={() => getData(true)}
                            disabled={loadingData} value="Actualizar TMS">
                            Actualizar TMS</Radio.Button>
                        <Radio.Button onClick={() => showModal()}
                            disabled={loadingData} value="Configuración">
                            {isModalLoading ? <Spin></Spin> : 'Configuración'}</Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
            <Divider />
            <Modal title="Configuración" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form layout='horizontal' onFinish={onFinishPopup} onFinishFailed={onFinishFailedPopup} fields={popupfields}
                    onFieldsChange={(_, allFields) => {
                        setPopupfields(allFields);
                    }}>
                    <Form.Item label="Correlativo" name="serie">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Siguiente Numeración" name="numero">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="Correos de envío (Separados por coma)" name="correoReceptor">
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Table columns={columns} data={data} updateMyData={updateMyData} />

            {loadingData ? <div style={{ height: "500px", position: "relative" }}>
                <div style={{ position: "absolute", top: "50%", left: "50%", color: "black" }}><Spin /> Obteniendo información </div>
            </div> : <div></div>}
        </Styles>
    )
}