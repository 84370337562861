import DashRepo from '../../shared/DashRepo';
import { useCookies } from 'react-cookie';

export default function LoginRepo() {
    
    const [cookies, setCookie] = useCookies(['token', 'name']);
    const { dFetch } = DashRepo();
    const getLogin = (credentials) => {
        return dFetch({ verb: 'POST', path: 'login', data: credentials }).then(response => {
            setCookie('name', response.data.data.name);
            setCookie('token', response.data.data.token);
            return response.data;
        });
    }
    const getVersion= () => {
        return dFetch({ verb: 'GET', path: '/', data: null }).then(response => {
            return response.data;
        });
    }
    return { getLogin, getVersion };
}