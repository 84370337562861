import DashRepo from "../../shared/DashRepo";

export default function OperationRepo(){

    const { dFetch } = DashRepo();

    const getOperations = (startDate, endDate, update) => {
        return dFetch({ verb: 'GET', path: `/operations/performance/byDateRangeValidation?update=${update}&startDate=${startDate}&endDate=${endDate}`, headers: {
            'Content-Type': 'application/json'}
        }).then(response => {
            return response.data;
        });
    }

    return{
        getOperations
    };

    
}

