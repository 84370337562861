import { useState } from "react";
import DistanceDrivers from "./DistanceDrivers";
import { Tabs, Card} from 'antd';
import './costing.css';
import Constants from "./Constants";
import TimeDrivers from "./TimeDrivers";

const { TabPane } = Tabs;

export default function Costing() {

    const [diesel, setDiesel] = useState(null);

    return (
        <Card title="Lista de drivers de gastos registrados">
            <div className="HomeCosting">
                <Tabs tabPosition={'left'}>
                    <TabPane tab="Constantes" key="1">
                        <Constants setDiesel={setDiesel} />
                    </TabPane>
                    <TabPane tab="Drivers de distancia" key="2">
                        <DistanceDrivers diesel={diesel} setDiesel={setDiesel} />
                    </TabPane>
                    <TabPane tab="Drivers de Tiempo" key="3">
                        <TimeDrivers />
                    </TabPane>
                </Tabs>
            </div>
        </Card>
    );

}